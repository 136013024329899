<template>
  <div class="help-icon">
    <FontAwesomeIcon
      :icon="helpIcon"
      v-b-popover.hover.click.focus.blur.top="helpText"
    />
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

export default {
  name: 'HelpIcon',
  data() {
    return {
      helpIcon: faQuestionCircle,
    };
  },
  components: {
    FontAwesomeIcon,
  },
  props: {
    helpText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.help-icon {
  display: inline-block;
}
</style>
