<template>
  <div class="image-container">
    <div class="img-circle">
      <img v-if="imgUrl" :src="imgUrl">
      <div v-if="!imgUrl" class="image-icon-wrapper">
        <FontAwesomeIcon
          color="white"
          class="image-icon"
          :icon="portraitIcon"
        />
        <div
          v-if="imgText"
          class="image-text"
        >
          {{ imgText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CircularImage',
  props: {
    imgUrl: String,
    imgText: String,
  },
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      portraitIcon: faImage,
    };
  },
};
</script>

<style scoped lang="scss">
.image-container {
  display: block;
  min-width: 100%;
  padding-bottom: 100%;
  position: relative;
  background-color: $panel-img-bg;
  border-radius: 50%;
  .img-circle {
    position: absolute;
    overflow: hidden;
    height: inherit;
    width: inherit;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
    .image-icon-wrapper {
      width: inherit;
      height: inherit;
      @include flexColumnCenter();
      .image-icon {
        clear: both;
        font-size: 4rem;
      }
      .image-text {
        text-align: center;
        line-height: 1;
      }
    }
  }
}

</style>
