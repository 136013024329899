<template>
  <div class="languages fade-in">
    <div class="languages-content">
      <VueSelectField
        v-for="(lang, index) in knownLanguages"
        :key="index"
        :id="'language-selector-'+index"
        :label="lang.type+' ('+lang.name+')'"
        :options="lang.options"
        v-model="lang.language"
        taggable
        size="sm"
        placeholder="- Select / Enter Language -"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapMultiRowFields } from 'vuex-map-fields';
import VueSelectField from '@/components/form/VueSelectField.vue';

export default {
  name: 'LanguagesPanel',
  components: {
    VueSelectField,
  },
  computed: {
    ...mapMultiRowFields('characterSheet', [
      'knownLanguages',
    ]),
    ...mapGetters('characterSheet', [
      'getAbilityScore',
      'getAllSkills',
    ]),
    linguisticsRanks() {
      const findLinguistics = this.getAllSkills.find((skill) => skill.name === 'Linguistics');
      return findLinguistics.ranks;
    },
    intelligenceScore() {
      return this.getAbilityScore('Intelligence');
    },
  },
  watch: {
    linguisticsRanks() {
      this.$store.dispatch('characterSheet/gatherLanguages');
    },
    intelligenceScore() {
      this.$store.dispatch('characterSheet/gatherLanguages');
    },
  },
};
</script>
