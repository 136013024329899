<template>
  <div class="home">
    <CharacterBuilder />
    <Modal
      modalId="manageCharactersModal"
      modalSize="lg"
      hideHeader
      hideFooter
      preventCloseOnSubmit
      preventCloseOnHide
      preventSaveWhileOpen
      @handleModalSubmit="manageCharactersModalSubmit"
      @handleModalShow="manageCharactersModalShow"
      @handleModalHide="manageCharactersModalHide"
    >
      <div class="manage-characters-text">
        <div class="splash-image-wrapper">
          <img class="anvil-logo" src="../assets/images/anvil_white_logo.png">
          <img class="anvil-splash" :src="getRandomSplashImageURL">
        </div>
        <p class="welcome-text">
          <span class="sr-only">Welcome to ANViL, the premiere 1st Edition Pathfinder Character Generator</span>
        </p>
        <b-form-row>
          <b-col class="col-12">
            <NameFields
              :desc="getLastSavedDate()"
              label="Name Your Character"
              :introName="true"
              :showLabel="false"
            />
          </b-col>
        </b-form-row>
        <div class="mx-5 mb-3" v-if="!isSupporter">
          <div class="my-3">{{ 'To save unlimited characters per device, import/export character sheets, gain access to over a dozen visual themes, and much more, ' }}
          <a href="https://www.patreon.com/treasuretrove" target="_blank">join us on Patreon</a>.</div>
        </div>
        <div class="" v-if="hasSavedCharacters && isAuthenticated && isSupporter">
          <div class="mb-3 select-saved-char-text">
            Saved Character Sheets
          </div>
          <StackedButtonGroup
            v-model="characterName"
            :itemsList="savedCharacterSheets"
            itemGroupName="Saved Character Sheet"
            @handleDeleteItemButtonClick="deleteCharacterSheet"
          />
        </div>
        <b-form-row class="mt-5 mb-5">
          <b-col class="col-12 col-sm-6">
            <Button
              class="w-100"
              btnClass="w-75 active"
              showLabel
              pill
              helpText="Our Step-by-Step Builder walks you through the character creation process from start to finish."
              @handleButtonClick="manageCharactersModalSubmit"
            >
              Step-by-Step Builder
            </Button>
          </b-col>
          <b-col class="col-12 col-sm-6">
            <Button
              class="w-100"
              btnClass="w-75 active"
              showLabel
              pill
              helpText="The Interactive UI lets you build your character in any order."
              @handleButtonClick="manageCharactersModalSubmit"
            >
              Interactive UI
            </Button>
          </b-col>
        </b-form-row>
      </div>
    </Modal>
    <StepByStepBuilder />
</div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers, mapFields } from 'vuex-map-fields';
import CharacterBuilder from '@/components/CharacterBuilder.vue';
import StepByStepBuilder from '@/components/StepByStepBuilder.vue';
import Modal from '@/components/Modal.vue';
import Button from '@/components/form/Button.vue';
import StackedButtonGroup from '@/components/form/StackedButtonGroup.vue';
import NameFields from '@/components/NameFields.vue';
import { APP_TITLE } from '../constants/metaConstants';
import formatDateTimeString from '../utils/stringUtils';

const splashImages = [
  require('../assets/images/anvil_splash1.png'),
  require('../assets/images/anvil_splash2.png'),
  require('../assets/images/anvil_splash3.png'),
];

const { mapFields: mapUserSettings } = createHelpers({
  getterType: 'getUserSettings',
  mutationType: 'updateUserSettings',
});

export default {
  title: APP_TITLE,
  name: 'Home',
  components: {
    CharacterBuilder,
    StepByStepBuilder,
    Modal,
    Button,
    StackedButtonGroup,
    NameFields,
  },
  created() {
    this.$store.commit('getSavedCharacterSheets');
    if (localStorage.getItem('latestSheet')) {
      this.$store.dispatch('loadCharacterSheet', localStorage.getItem('latestSheet'));
    }
    if (!this.characterName) {
      this.showModal('manageCharactersModal');
    } else {
      this.startSavingLocalData();
    }
    this.$store.dispatch('fetchPatreonProfile');
  },
  computed: {
    ...mapState([
      'savedCharacterSheets',
      'saveUpdatesToLocalStorage',
      'errors',
      'isAuthenticated',
      'isSupporter',
      'isPrestige',
    ]),
    ...mapFields('characterSheet', [
      'characterName',
    ]),
    ...mapUserSettings([
      'buildOption',
    ]),
    hasSavedCharacters() {
      return !(this.savedCharacterSheets.length === 0 || this.savedCharacterSheets[0] === 'loglevel:webpack-dev-server');
    },
    getRandomSplashImageURL() {
      return splashImages[Math.floor(Math.random() * splashImages.length)];
    },
  },
  methods: {
    getLastSavedDate() {
      if (localStorage.getItem(this.characterName) && this.isAuthenticated && this.isSupporter) {
        const saveDate = JSON.parse(localStorage.getItem(this.characterName)).lastSave;
        return `Last Saved: ${formatDateTimeString(saveDate)}`;
      }
      return 'You are creating a new character.';
    },
    manageCharactersModalSubmit(event) {
      this.validateCharacterName();
      if (!this.errors.length) {
        if (this.isAuthenticated && this.isSupporter) {
          this.$store.dispatch('loadCharacterSheet', this.characterName);
        } else if (localStorage.getItem('latestSheet') !== this.characterName) {
          this.$store.commit('createNewCharacterSheet', this.characterName);
        }
        this.startSavingLocalData();
        this.hideModal('manageCharactersModal');
        if (event.target.textContent.includes('Step-by-Step Builder')) {
          this.showModal('stepByStepBuilderModal');
        }
      }
    },
    manageCharactersModalHide() {
      this.validateCharacterName();
      if (!this.errors.length) {
        this.startSavingLocalData();
        this.hideModal('manageCharactersModal');
      }
    },
    manageCharactersModalShow() {
      if (this.saveUpdatesToLocalStorage) {
        this.$store.commit('toggleSaveToLocalStorage', false);
      }
      this.$store.commit('getSavedCharacterSheets');
    },
    startSavingLocalData() {
      if (!this.saveUpdatesToLocalStorage) {
        this.$store.commit('toggleSaveToLocalStorage', true);
      }
    },
    getManageCharactersModalTitle() {
      return (this.hasSavedCharacters && this.isAuthenticated && this.isSupporter) ? 'ANViL - Manage Characters' : 'ANViL - Create a New Character';
    },
    deleteCharacterSheet(characterName) {
      if (characterName) {
        localStorage.removeItem(characterName);
      }
      this.$store.commit('getSavedCharacterSheets');
    },
    validateCharacterName(theName = this.characterName) {
      this.$store.commit('clearErrors');
      if (theName.length < 3 || theName.length > 40) {
        this.$store.commit('addError', 'Character Name must be between 3 and 40 characters in length.');
      }
    },
    hideModal(modalName) {
      this.$nextTick(() => {
        this.$bvModal.hide(modalName);
        this.startSavingLocalData();
      });
    },
    showModal(modalName) {
      this.$nextTick(() => {
        this.$bvModal.show(modalName);
      });
    },
  },
};
</script>

<style lang="scss">
.manage-characters-text {
  text-align: center;
  .splash-image-wrapper {
    position: relative;
    margin: 0;
    margin-top: 2rem;
    .anvil-logo {
      position: absolute;
      bottom: 60px;
      left: 0;
      right: 0;
      display: block;
      margin: 0 auto;
      filter: drop-shadow(0 0 0.75rem black);
      height: 50px;
    }
    .anvil-splash {
      margin: 0;
      height: 350px;
      @media screen and (max-width: 400px) {
        width: 100%;
        height: 100%;
      }
    }
  }
  .welcome-text {
    font-size: $text-med-small;
  }
  .select-saved-char-text {
    font-family: 'cardo';
    @media screen and (max-width: 576px) {
      font-size: $text-smallest;
    }
  }
  .radio-button-group {
    text-align: center !important;
  }
}
</style>
