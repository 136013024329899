<template>
  <div
    class="form-group radio-button-group"
  >
    <label
      :for="'btn-group-'+id"
      :class="`d-block ${showLabel ? '' : 'sr-only'}`"
    >
    {{ label }}
    <HelpIcon
      v-if="helpText"
      :helpText="helpText"
    />
    </label>
    <b-button-group
      class="btn-group-toggle"
      :id="'btn-group-'+id"
    >
      <label
        v-b-popover.hover.focus.blur.top="option[popoverField]"
        v-for="(option, index) in options"
        :key="index"
        :label-for="'group-btn-'+id+index"
        :class="[{ active: (selectedValue === option[valueField] || selectedValue === option) }, 'btn btn-sm']"
      >
        <input
          type="radio"
          v-model="selectedValue"
          :value="option[valueField] || option"
          :disabled="disabled"
          :id="'group-btn-'+id+index"
          @click="handleButtonClick"
        >
          {{ option[textField] || option }}
      </label>
    </b-button-group>
  </div>
</template>

<script>
import HelpIcon from '@/components/form/HelpIcon.vue';

export default {
  name: 'ButtonGroup',
  components: {
    HelpIcon,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    label: String,
    showLabel: {
      type: Boolean,
      default: true,
    },
    options: Array,
    textField: {
      type: [String, Number],
      default: 'text',
    },
    valueField: {
      type: [String, Number],
      default: 'value',
    },
    popoverField: {
      type: String,
      default: '',
    },
    value: [String, Number],
    helpText: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    handleButtonClick(event) {
      this.$emit('handleButtonClick', event);
    },
  },
};
</script>
