<template>
  <div class="ability-score-fields fade-in">
    <b-form-row>
      <b-col class="col-12 d-flex justify-content-between">
        <Button
          btnClass="ability-score-btn"
          @handleButtonClick="toggleDiceTray()"
        >
          {{ showDiceTray ? 'Hide Dice Roller' : 'Show Dice Roller' }}
        </Button>
        <Button
          btnClass="ability-score-btn"
          size="sm"
        >
          {{ getPointBuyTotal+' Points Spent' }}
        </Button>
      </b-col>
    </b-form-row>
    <b-form-row class="my-3">
      <b-col class="col-12">
        <DiceTray
          v-if="showDiceTray"
        />
      </b-col>
    </b-form-row>
    <b-form-row>
      <AbilityScoreRow
        scoreName="Strength"
      />
      <AbilityScoreRow
        scoreName="Dexterity"
      />
      <AbilityScoreRow
        scoreName="Constitution"
      />
      <AbilityScoreRow
        scoreName="Intelligence"
      />
      <AbilityScoreRow
        scoreName="Wisdom"
      />
      <AbilityScoreRow
        scoreName="Charisma"
      />
    </b-form-row>
  </div>
</template>

<script>
import AbilityScoreRow from '@/components/AbilityScoreRow.vue';
import Button from '@/components/form/Button.vue';
import DiceTray from '@/components/DiceTray.vue';
import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';

const { mapFields: mapUserSettings } = createHelpers({
  getterType: 'getUserSettings',
  mutationType: 'updateUserSettings',
});

export default {
  name: 'AbilityScoreFields',
  components: {
    AbilityScoreRow,
    Button,
    DiceTray,
  },
  computed: {
    ...mapUserSettings([
      'showDiceTray',
    ]),
    ...mapGetters('characterSheet', [
      'getPointBuyTotal',
    ]),
  },
  methods: {
    toggleDiceTray() {
      this.$store.commit('toggleDeepProperty', { property: 'userSettings', element: 'showDiceTray' });
    },
  },
};
</script>

<style lang="scss">

div.form-button {
  .ability-score-btn {
    background: $userTheme !important;
  }
}
