<template>
  <div class="race-builder fade-in">
    <b-form-row>
      <b-col class="col-12">
        <SectionDivider
          title="Race"
        />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col class="col-12 col-sm-4 col-lg-6 col-xl-2">
        <VueSelectField
          id="characterRace"
          label="Race"
          :options="races"
          v-model="raceName"
          taggable
          textField="name"
          valueField="name"
          size="sm"
          placeholder="- Race -"
          @handleVueSelectFieldChange="setRaceData"
        />
      </b-col>
      <b-col class="col-6 col-sm-4 col-lg-4 col-xl-2">
        <VueSelectField
          id="raceSize"
          label="Size"
          v-model="raceSize"
          :options="sizeCategories"
          textField="name"
          valueField="name"
          placeholder="- Size -"
        />
      </b-col>
      <b-col class="col-6 col-sm-4 col-lg-2 col-xl-2">
        <SpinButton
          id="raceSpeed"
          label="Speed"
          v-model="raceSpeed"
          min=0
        />
      </b-col>
      <b-col class="col-4 col-sm-2 col-lg-2 col-xl-1">
        <SpinButton
          id="raceAbilModsStr"
          label="STR"
          v-model="Strength"
        />
      </b-col>
      <b-col class="col-4 col-sm-2 col-lg-2 col-xl-1">
        <SpinButton
          id="raceAbilModsDex"
          label="DEX"
          v-model="Dexterity"
        />
      </b-col>
      <b-col class="col-4 col-sm-2 col-lg-2 col-xl-1">
        <SpinButton
          id="raceAbilModsCon"
          label="CON"
          v-model="Constitution"
        />
      </b-col>
      <b-col class="col-4 col-sm-2 col-lg-2 col-xl-1">
        <SpinButton
          id="raceAbilModsInt"
          label="INT"
          v-model="Intelligence"
        />
      </b-col>
      <b-col class="col-4 col-sm-2 col-lg-2 col-xl-1">
        <SpinButton
          id="raceAbilModsWis"
          label="WIS"
          v-model="Wisdom"
        />
      </b-col>
      <b-col class="col-4 col-sm-2 col-lg-2 col-xl-1">
        <SpinButton
          id="raceAbilModsCha"
          label="CHA"
          v-model="Charisma"
        />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import SpinButton from '@/components/form/SpinButton.vue';
import VueSelectField from '@/components/form/VueSelectField.vue';
import SectionDivider from '@/components/SectionDivider.vue';

export default {
  name: 'RaceBuilder',
  created() {
    this.$store.dispatch('fetchList', { urlPath: this.raceListToFetch, targetList: 'races' });
  },
  components: {
    SpinButton,
    VueSelectField,
    SectionDivider,
  },
  computed: {
    ...mapState([
      'isAuthenticated',
      'isSupporter',
      'sizeCategories',
      'races',
    ]),
    ...mapFields('characterSheet', [
      'raceName',
      'raceSize',
      'raceSpeed',
      'raceAbilityModifiers.Strength',
      'raceAbilityModifiers.Dexterity',
      'raceAbilityModifiers.Constitution',
      'raceAbilityModifiers.Intelligence',
      'raceAbilityModifiers.Wisdom',
      'raceAbilityModifiers.Charisma',
    ]),
    raceListToFetch() {
      return (this.isAuthenticated && this.isSupporter) ? 'allRaces' : 'races';
    },
  },
  methods: {
    setRaceData(value) {
      this.$store.dispatch('fetchListItem', { value, listToSearch: 'races', urlPath: this.raceListToFetch })
        .then((response) => {
          if (response.length > 0) {
            this.$store.dispatch('characterSheet/setRace', response[0]);
          }
        });
    },
  },
};
</script>
