import { SAVE_TYPES } from '../constants/metaConstants';
import getId from './idUtils';

export const plusMinus = (aNumber) => ((aNumber >= 0) ? `+${aNumber}` : aNumber);

export const commaStringToArray = (commaString) => ((commaString && commaString?.length) ? commaString.split(',') : []);

export const lowerString = (str) => str.toString().toLowerCase();

export const stripDashMinus = (str) => str && str.toString().replace(/–/gi, '-');

export const addOrdinalSuffix = (num) => {
  const int = parseInt(num, 10);
  const digits = [int % 10, int % 100];
  const ordinals = ['st', 'nd', 'rd', 'th'];
  const oPattern = [1, 2, 3, 4];
  const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];
  return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
    ? int + ordinals[digits[0] - 1]
    : int + ordinals[3];
};

export const abilAbbrev = (att) => att.substring(0, 3).toUpperCase();

export const setRaceAbilMods = (modArray) => ({
  Strength: modArray[0],
  Dexterity: modArray[1],
  Constitution: modArray[2],
  Intelligence: modArray[3],
  Wisdom: modArray[4],
  Charisma: modArray[5],
});

export const carryingCapacityBySize = (weightLoad) => ({
  Fine: Math.round(weightLoad * 0.125),
  Diminutive: Math.round(weightLoad * 0.25),
  Tiny: Math.round(weightLoad * 0.5),
  Small: Math.round(weightLoad * 0.75),
  Medium: weightLoad,
  Large: Math.round(weightLoad * 2),
  Huge: Math.round(weightLoad * 4),
  Gargantuan: Math.round(weightLoad * 8),
  Colossal: Math.round(weightLoad * 16),
});

export const formatFeatName = (feat) => (feat ? (`${feat.name} (${feat.source ? `${feat.source} ` : ''}Level ${feat.levelGained})${(feat.types && feat.types.length > 0) ? ` (${feat.types})` : ''}`) : '');

export const slowSave = (classLevel) => Math.floor(classLevel / 3);

export const fastSave = (classLevel) => Math.floor(classLevel / 2) + 2;

export const getClassSaveBonusAtLevel = (classObj, saveType) => (classObj?.savingThrows?.charAt(SAVE_TYPES[saveType]) === 'F' ? fastSave(classObj.classLevels) : slowSave(classObj.classLevels));

export const slowBaB = (classLevel) => Math.floor(classLevel / 2);

export const mediumBaB = (classLevel) => Math.floor(classLevel * 0.75);

export const fastBaB = (classLevel) => classLevel;

export const baseAttackBonus = (babspeed, lvl) => {
  const babSpeeds = {
    Fast: fastBaB(lvl),
    Medium: mediumBaB(lvl),
    Slow: slowBaB(lvl),
  };
  return babSpeeds[babspeed] || 0;
};

export const minVal = (val, min) => (val < min ? min : val);

export const getLowestArrayValue = (array) => Math.min(...array || []);

export const getHighestArrayValue = (array) => Math.max(...array || []);

export const replaceSpaces = (string) => string.replaceAll(' ', '-');

export const filterBonusStack = (modifierArray, allowTheseDuplicates) => modifierArray.reduce((acc, current) => {
  if (allowTheseDuplicates.indexOf(current.type.toLowerCase()) > -1) {
    return acc.concat([current]);
  }
  const x = acc.find((item) => item.type.toLowerCase() === current.type.toLowerCase());
  if (!x) {
    return acc.concat([current]);
  }
  if (+current.amount > +x.amount) {
    return acc.filter((mod) => mod !== x).concat([current]);
  }
  return acc;
}, []);

export const filterBonusTypes = (arrayToFilter, typesToRemove) => arrayToFilter.filter((bonus) => typesToRemove.indexOf(bonus.type.toLowerCase()) <= -1);

export const trimBonusTypes = (arrayToTrim, typesToKeep) => arrayToTrim.filter((bonus) => typesToKeep.indexOf(bonus.type.toLowerCase()) > -1);

export const sumModifiers = (modifierArray) => modifierArray.reduce((a, b) => a + +b.amount, 0);

export const setMaxDex = (dexMod, maxDex) => {
  if (dexMod > maxDex) {
    return { name: 'Max Dex Bonus', value: maxDex };
  }
  return { name: 'Dexterity', value: dexMod };
};

export const bonusSpellsPerDay = (abilmod, spelllevel) => this.minZero(Math.ceil((abilmod - (spelllevel - 1)) / 4));

export const minOne = (n) => (n < 1 ? 1 : n);

export const minZero = (n) => (n < 0 ? 0 : n);

export const skillNameWithIndicators = (skill) => skill.name + (skill.armorCheckPenalty ? '*' : '') + (skill.trainedOnly ? '†' : '');

export const times = (x) => (f) => {
  if (x > 0) {
    f();
    times(x - 1)(f);
  }
};

export const objHasProp = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);

export const filterObj = (obj, prop) => Object.entries(obj).reduce(
  (accum, [key, value]) => ({
    ...accum,
    ...((obj[key].name === prop || key === prop || objHasProp(obj[key], prop)) ? { [key]: value } : {}),
  }),
  {},
);

export const randomIntegerBetween = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

export const denomAbbrev = (denomType) => `${denomType.charAt(0).toLowerCase()}p`;

export const capIt = (string) => (string ? string.charAt(0).toUpperCase() + string.substr(1).toLowerCase() : '');

export const stringToCamel = (string) => string
  .split(' ')
  .map((word, index) => (index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()))
  .join('');

export const alphabetize = (list) => list.sort((a, b) => a.name.localeCompare(b.name));

export const filterList = (array, filters) => {
  const filterKeys = Object.keys(filters);
  return array.filter((item) => filterKeys.every((key) => {
    if (typeof filters[key] !== 'function') {
      return true;
    }
    return filters[key](item);
  }));
};

export const extractSpellcastingFeature = (classFeatures) => classFeatures[Object.keys(classFeatures).find((feature) => classFeatures[feature].name === 'Spellcasting')] || {};

export const isSpellcastingClass = (charClass) => charClass?.spellcasting?.isSpellcaster;

export const lookupPreparedSpell = (charClass, spellLevel, spellSlot) => charClass.preparedSpells[spellLevel][spellSlot] || {};

export const chosenSpellName = (charClass, spellLevel, spellSlot) => (lookupPreparedSpell(charClass, spellLevel, spellSlot) && lookupPreparedSpell(charClass, spellLevel, spellSlot).name) || '';

export const chosenSpellUsed = (charClass, spellLevel, spellSlot) => lookupPreparedSpell(charClass, spellLevel, spellSlot) && lookupPreparedSpell(charClass, spellLevel, spellSlot).hasBeenCast;

export const casterType = (charClass) => ((charClass.classFeatures && charClass.spellcasting) ? charClass.spellcasting.castingType : '');

export const hasClassSpells = (charClass) => charClass?.spellcasting?.['Level Increases']?.[0] <= charClass.classLevels;

export const isActiveClass = (charClass) => charClass.name && charClass.classLevels > 0;

export const critRangeLow = (critRange) => critRange.replaceAll(' ', '').split('-')[0] || 20;

export const isKnownCaster = (charClass) => casterType(charClass) === 'Known';

export const hasSpellbook = (charClass) => charClass?.spellcasting?.['Spell Source'] === 'Spellbook';

export const hasFamiliar = (charClass) => charClass?.spellcasting?.['Spell Source'] === 'Familiar';

export const setThemeBodyClass = (themeClass) => { document.body.className = themeClass; };

export const getArmorOrWeaponSlot = (invItem) => {
  if (invItem && invItem.type) {
    if (invItem.type.toLowerCase() === 'armor') {
      return invItem.type;
    }
    if (invItem.type.toLowerCase() === 'weapon') {
      return (invItem.encumbrance.toLowerCase() === 'light' ? 'One-Handed' : invItem.encumbrance);
    }
  }
  return invItem.slot || '';
};

export const itemIsTwoHanded = (item) => item.slot.toLowerCase() === 'two-handed';

export const itemIsEquipped = (item, equipment) => {
  const foundSlot = Object.values(equipment).find((slot) => slot.equippedItemId === item.id);
  const isTwoHanded = itemIsTwoHanded(item);
  if (foundSlot && isTwoHanded) {
    return 'Both Hands';
  }
  if (foundSlot && !isTwoHanded) {
    return foundSlot.name;
  }
  return false;
};

export const bothHandsAreFree = (equipment) => !equipment['Left Hand'].equippedItemId && !equipment['Right Hand'].equippedItemId;

export const removeEmptyObjectKeys = (arrOfObj) => arrOfObj.filter((obj) => Object.keys(obj).length > 0);

// Accepts raw json data object and converts to a UUID key with id and name properties
export function assignIdsToData(dataObj) {
  const processedData = {};
  Object.keys(dataObj).forEach((key) => {
    const id = getId();
    processedData[id] = { ...dataObj[key], id, name: key };
  });
  return processedData;
}

export const checkHasProperty = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);

// Checks whether data object needs to have ids assigned to it
export const checkInitialize = (dataObj) => {
  const checkForNameAndId = Object.values(dataObj).filter((item) => checkHasProperty(item, 'name') && checkHasProperty(item, 'id'));
  if (checkForNameAndId.length === Object.keys(dataObj).length) {
    return dataObj;
  }
  return assignIdsToData(dataObj);
};

export const initializeFeats = (dataObj) => {
  const checkFeat = Object.values(dataObj).filter((item) => checkHasProperty(item, 'types') && checkHasProperty(item, 'selection'));
  if (checkFeat.length === Object.keys(dataObj).length) {
    return dataObj;
  }
  return assignIdsToData(dataObj);
};

export const arrayToObject = (array) => array.reduce((obj, item) => Object.assign(obj, { [`${item.id}`]: item }), {});
