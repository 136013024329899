<template>
    <div class="stat-icon fade-in" v-b-popover.hover.focus.blur.click.top="statName+' - '+statText">
      <FontAwesomeIcon class="icon-svg mb-2" :icon="icon" />
      <div class="stat-text">{{ statText || 'none' }}</div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'StatIcon',
  components: {
    FontAwesomeIcon,
  },
  props: {
    statName: {
      type: String,
      default: '',
    },
    statText: {
      type: String,
      default: '',
    },
    icon: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {

    };
  },
};
</script>

<style scoped lang="scss">
.stat-icon {
  @include flexColumnCenter();
  max-width: 4rem;
  .icon-svg {
    display: block;
    font-size: $text-subtitle;
  }
  .stat-text {
    display: block;
    text-align: center;
    line-height: 1;
    font-size: $text-smallest;
    font-weight: $bold-text;
  }
}
</style>
