<template>
  <div class="skill-custom-fields fade-in">
    <Button
      alt="Add Custom Skill"
      size="sm"
      centered
      btnClass="add-custom-skill-btn"
      @handleButtonClick="addUserSkill"
    >
      + Add Custom Skill
    </Button>
    <SkillUserAddedFields
      v-for="(userAddedSkill, skillId) in userAddedSkills"
      :key="`custom-skill-fields-${skillId}`"
      :userSkill="userAddedSkill"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { mapMultiRowFields } from 'vuex-map-fields';
import Button from '@/components/form/Button.vue';
import SkillUserAddedFields from '@/components/SkillUserAddedFields.vue';

export default {
  name: 'SkillCustomFields',
  components: {
    Button,
    SkillUserAddedFields,
  },
  computed: {
    ...mapMultiRowFields('characterSheet', [
      'userAddedSkills',
    ]),
  },
  methods: {
    ...mapMutations('characterSheet', [
      'addUserSkill',
    ]),
  },
};
</script>
