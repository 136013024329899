<template>
  <div class="bio-panel fade-in"
    v-b-modal.bioModal
  >
    <b-form-row class="bio-panel-content">
      <b-col class="col-12 col-sm-6">
        <PanelElement itemName="Age" :itemValue="age" />
        <PanelElement itemName="Age Category" :itemValue="ageCategory" />
        <PanelElement itemName="Hair" :itemValue="hairColor" />
        <PanelElement itemName="Eyes" :itemValue="eyeColor" />
        <PanelElement itemName="Skin" :itemValue="skinColor" />
        <PanelElement itemName="Gender" :itemValue="gender" />
      </b-col>
      <b-col class="col-12 col-sm-6">
        <PanelElement itemName="Height" :itemValue="height" />
        <PanelElement itemName="Weight" :itemValue="weight" />
        <PanelElement itemName="Homeland" :itemValue="homeland" />
        <PanelElement itemName="Deity" :itemValue="deity" />
        <PanelElement itemName="Campaign" :itemValue="campaign" />
        <PanelElement itemName="Representing" :itemValue="playerName" />
      </b-col>
    </b-form-row>
    <Modal
      modalTitle="Edit Appearance & Description"
      modalSize="xl"
      modalId="bioModal"
      okOnly
    >
      <BioFields/>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue';
import BioFields from '@/components/BioFields.vue';
import PanelElement from '@/components/PanelElement.vue';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'BioPanel',
  components: {
    BioFields,
    Modal,
    PanelElement,
  },
  computed: {
    ...mapFields('characterSheet', [
      'age',
      'ageCategory',
      'hairColor',
      'eyeColor',
      'skinColor',
      'height',
      'weight',
      'gender',
      'homeland',
      'deity',
      'campaign',
      'playerName',
    ]),
  },
  methods: {
  },
};
</script>
