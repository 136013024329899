<template>
  <b-col class="col-4 col-md-2 pb-2">
    <div class="ability-score-row">
      <div class="score-name-text">
        {{ abilAbbrev(scoreName) }}
      </div>
      <SpinButton
        :id="'baseAbilityScore'+scoreName"
        label="Base"
        size="sm"
        spinButtonClass="ability-spin-buttons"
        min=1
        max=25
        :value="baseAbilityScores[scoreName]"
        @handleSpinButtonChange="setBaseAbilScore"
      />
      <SpinButton
        :id="'racialAbilityScore'+scoreName"
        :label="raceName ? raceName : 'Race'"
        size="sm"
        min=-8
        max=8
        :value="raceAbilityModifiers[scoreName]"
        @handleSpinButtonChange="setRaceAbilMod"
        spinButtonClass="ability-spin-buttons"
      />
      <div class="lvl-text">Level</div>
      <Button
        size="sm"
        :btnClass="`lvl-inc-btn ${setButtonClass(1)}`"
        :disabled="disableAbilityIncrease(1)"
        label=""
        @handleButtonClick="assignAbilityIncrease(1)"
      >1st</Button>
      <Button
        size="sm"
        :btnClass="`lvl-inc-btn ${setButtonClass(4)}`"
        :disabled="disableAbilityIncrease(4)"
        label=""
        @handleButtonClick="assignAbilityIncrease(4)"
      >4th</Button>
      <Button
        size="sm"
        :btnClass="`lvl-inc-btn ${setButtonClass(8)}`"
        :disabled="disableAbilityIncrease(8)"
        label=""
        @handleButtonClick="assignAbilityIncrease(8)"
      >8th</Button>
      <Button
        size="sm"
        :btnClass="`lvl-inc-btn ${setButtonClass(12)}`"
        :disabled="disableAbilityIncrease(12)"
        label=""
        @handleButtonClick="assignAbilityIncrease(12)"
      >12th</Button>
      <Button
        size="sm"
        :btnClass="`lvl-inc-btn ${setButtonClass(16)}`"
        :disabled="disableAbilityIncrease(16)"
        label=""
        @handleButtonClick="assignAbilityIncrease(16)"
      >16th</Button>
      <Button
        size="sm"
        :btnClass="`lvl-inc-btn ${setButtonClass(20)}`"
        :disabled="disableAbilityIncrease(20)"
        label=""
        @handleButtonClick="assignAbilityIncrease(20)"
      >20th</Button>
      <SpinButton
        :id="'miscAbilityMod'+scoreName"
        label="Misc"
        size="sm"
        spinButtonClass="ability-spin-buttons"
        min=-20
        max=20
        :value="miscAbilityModifiers[scoreName]"
        @handleSpinButtonChange="setMiscAbilMod"
      />
      <div v-if="ageCategory !== 'Normal'" class="age-category-ability-modifier">
        <div class="modifier-label">{{ ageCategory }}</div>
        <div class="modifier-number">{{ plusMinus(ageCategories[ageCategory][scoreName]) }}</div>
      </div>
      <div v-for="(mod, index) in buildModArray(scoreName)" :key="index" class="ability-modifier">
        <div class="modifier-label">{{ mod.name }}</div>
        <div class="modifier-number">{{ plusMinus(mod.value) }}</div>
      </div>
      <div class="total-text">Total</div>
      <div class="total-score">
        {{ getAbilityScore(scoreName) }}
      </div>
      <div class="total-score-modifier">
        {{ getAbilityModifier(scoreName) }}
      </div>
    </div>
  </b-col>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import SpinButton from '@/components/form/SpinButton.vue';
import Button from '@/components/form/Button.vue';
import { abilAbbrev, plusMinus } from '../utils/utils';

export default {
  name: 'AbilityScoreRow',
  components: {
    SpinButton,
    Button,
  },
  props: {
    scoreName: String,
  },
  computed: {
    ...mapFields('characterSheet', [
      'baseAbilityScores',
      'abilityIncreases',
      'miscAbilityModifiers',
      'raceAbilityModifiers',
      'raceName',
      'raceStartingAbilMod',
      'ageCategory',
    ]),
    ...mapState([
      'ageCategories',
    ]),
    ...mapGetters('characterSheet', [
      'getAbilityScore',
      'getAbilityModifier',
      'getTotalClassLevels',
      'buildModArray',
    ]),
  },
  methods: {
    abilAbbrev,
    plusMinus,
    disableAbilityIncrease(incLvl) {
      return (incLvl === 1 && !this.raceStartingAbilMod) || (this.getTotalClassLevels < incLvl || (this.abilityIncreases[incLvl].length > 0 && this.scoreName !== this.abilityIncreases[incLvl]));
    },
    assignAbilityIncrease(incLvl) {
      this.$store.commit('characterSheet/increaseAbilityScore', { lvl: incLvl, scoreName: this.scoreName });
    },
    setButtonClass(incLvl) {
      return this.abilityIncreases[incLvl] === this.scoreName ? 'active' : '';
    },
    setBaseAbilScore(score) {
      this.$store.commit('characterSheet/setField', { list: 'baseAbilityScores', id: this.scoreName, value: score });
    },
    setRaceAbilMod(modifier) {
      this.$store.commit('characterSheet/setField', { list: 'raceAbilityModifiers', id: this.scoreName, value: modifier });
    },
    setMiscAbilMod(modifier) {
      this.$store.commit('characterSheet/setField', { list: 'miscAbilityModifiers', id: this.scoreName, value: modifier });
    },
  },
};
</script>

<style lang="scss">
.ability-score-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $panel-tab;
  margin: 0 auto;
  border-radius: 20px;
  border: 2px dotted $panel-dividers;
  overflow: hidden;
  .score-name-text {
    font-size: $text-standard;
    font-weight: $bold-text;
    text-align: center;
    background: $userTheme;
    text-transform: uppercase;
    width: 100%;
    padding: 0.5rem 0 0.25rem;
  }
  .lvl-text {
    font-size: $text-smaller;
    margin: 0.25rem 1rem;
  }
  div.form-button {
    margin: 0.25rem 1rem;
    .lvl-inc-btn {
      width: 100%;
    }
  }
  .input-field {
    margin: 0.25rem 1rem;
    .ability-inputs {
      border-radius: 0.25rem;
      padding: 0;
      padding-left: 15px;
      margin: 0;
      text-align: center;
      font-size: $text-large;
      font-weight: $thin-text;
      height: 31px;
    }
  }
  .spin-button-field {
    margin: 0.25rem 1rem !important;
    .b-form-spinbutton {
      height: 31px;
    }
  }
  .total-text {
    margin: 0 1rem;
  }
  .age-category-ability-modifier,
  .ability-modifier {
    margin: 0.25rem 1rem;
    .modifier-number {
      font-size: $text-large;
      font-weight: $thin-text;
      text-align: center;
    }
  }
  .total-score {
    display: inline-block;
    font-size: $text-header;
    font-weight: $superthin-text;
    line-height: 1;
    text-align: center;
  }
  .total-score-modifier {
    display: inline-block;
    font-size: $text-xlarge;
    line-height: 1.5;
    text-align: center;
  }
  @media screen and (max-width: 576px) {
    .spin-button-field,
    .anvil-button {
      margin: 0.25rem !important;
    }
  }
}
</style>
