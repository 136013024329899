const alignments = {
  state: () => ([
    {
      name: 'Lawful Good',
      abbreviation: 'LG',
      value: 'Lawful Good',
    },
    {
      name: 'Lawful Neutral',
      abbreviation: 'LN',
      value: 'Lawful Neutral',
    },
    {
      name: 'Lawful Evil',
      abbreviation: 'LE',
      value: 'Lawful Evil',
    },
    {
      name: 'Neutral Good',
      abbreviation: 'NG',
      value: 'Neutral Good',
    },
    {
      name: 'True Neutral',
      abbreviation: 'N',
      value: 'True Neutral',
    },
    {
      name: 'Neutral Evil',
      abbreviation: 'NE',
      value: 'Neutral Evil',
    },
    {
      name: 'Chaotic Good',
      abbreviation: 'CG',
      value: 'Chaotic Good',
    },
    {
      name: 'Chaotic Neutral',
      abbreviation: 'CN',
      value: 'Chaotic Neutral',
    },
    {
      name: 'Chaotic Evil',
      abbreviation: 'CE',
      value: 'Chaotic Evil',
    },
  ]),
};

export default alignments;
