<template>
  <div>
    <b-form-row>
      <div class="hp-level-text">
        {{ charClass.name }} Level {{ +level+1 }}
      </div>
    </b-form-row>
    <b-form-row class="hit-point-level-row">
      <b-col class="class-skill mb-3">
        <Button
          :id="'roll-hp-level-'+(+level+1)"
          size="sm"
          btnClass="roll-hp-btn"
          :disabled="!isSubsequentLevel || !charClass.hitDie"
          square
          :label="setHitDieButtonLabel"
          @handleButtonClick="rollHitPointsForLevel()"
        >
          <FontAwesomeIcon :icon="getHitDieIcon()" />
        </Button>
      </b-col>
      <b-col class="hp-roll-stepper">
        <SpinButton
          :id="'hpLevel'+level"
          label="Result"
          size="sm"
          spinButtonClass="hit-die-roll"
          min=0
          :disabled="!isSubsequentLevel"
          :max="getDieSidesFromHitDieString(charClass.hitDie)"
          :value="charClass.hpRolls[level]"
          @handleSpinButtonChange="setHitPointsForLevel"
        />
      </b-col>
      <b-col class="hit-point-con-mod mb-3">
        <label class="hit-point-label">{{ abilAbbrev('Constitution') }}</label>
        <div class="hit-point-amount">{{ getAbilityModifier('Constitution')}}</div>
      </b-col>
      <b-col v-if="charClass.isFavored && (+level < +charClass.favHp)" class="hit-point-favored mb-3">
        <label class="hit-point-label">Favored Class</label>
        <div class="hit-point-amount">+1</div>
      </b-col>
      <b-col class="hit-point-total">
        <div class="hit-point-total-amount">{{ plusMinus(getHitPointLevelTotal(charClass.id, level)) }}</div>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinButton from '@/components/form/SpinButton.vue';
import Button from '@/components/form/Button.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faDiceD4, faDiceD6, faDiceD8, faDiceD10, faDiceD12,
} from '@fortawesome/pro-solid-svg-icons';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { getDieSidesFromHitDieString, rollADie } from '../utils/diceUtils';
import { abilAbbrev, plusMinus } from '../utils/utils';

export default {
  name: 'HitPointLevelRow',
  components: {
    SpinButton,
    Button,
    FontAwesomeIcon,
  },
  data() {
    return {
      d4Icon: faDiceD4,
      d6Icon: faDiceD6,
      d8Icon: faDiceD8,
      d10Icon: faDiceD10,
      d12Icon: faDiceD12,
      noHDIcon: faQuestion,
      rollResult: null,
    };
  },
  props: {
    level: Number,
    charClass: Object,
  },
  computed: {
    ...mapGetters('characterSheet', [
      'getAbilityModifier',
      'getHitPointLevelTotal',
    ]),
    isSubsequentLevel() {
      return (this.charClass.classType === 'Multiclass' || (this.charClass.classType === 'Starting Class' && this.level > 0));
    },
    setHitDieButtonLabel() {
      return !this.isSubsequentLevel ? `1${this.charClass.hitDie || 'd?'} [Full]` : `Roll 1${this.charClass.hitDie || 'd?'}`;
    },
  },
  methods: {
    abilAbbrev,
    plusMinus,
    getDieSidesFromHitDieString,
    getHitDieIcon() {
      const hitDieIcons = {
        d4: this.d4Icon,
        d6: this.d6Icon,
        d8: this.d8Icon,
        d10: this.d10Icon,
        d12: this.d12Icon,
        default: this.noHDIcon,
      };
      return hitDieIcons[this.charClass.hitDie] || hitDieIcons.default;
    },
    rollHitPointsForLevel() {
      this.rollResult = rollADie(getDieSidesFromHitDieString(this.charClass.hitDie));
      this.setHitPointsForLevel(this.rollResult);
    },
    setHitPointsForLevel(value) {
      this.$store.commit('characterSheet/saveHitPointRoll', { charClassId: this.charClass.id, level: this.level, result: value });
    },
  },
};
</script>

<style lang="scss">
.hp-level-text {
  background-color: rgba(255, 255, 255, 0.10);
  border-radius: 10px 10px 0px 0px;
  margin: 0 1.5rem;
  padding: 0.25rem 1rem !important;
  width: auto;
}
.hit-point-level-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.05);
  overflow: hidden;
  border: 2px dotted $panel-dividers;
  margin: 0rem 0rem 0.25rem;
  padding: 0.25rem 1rem;
  border-radius: 20px;
  .spin-button-field {
    margin: 0 1rem 1rem !important;
  }
  .hit-point-total {
    font-size: $text-header;
    font-weight: $superthin-text;
    line-height: 1;
    text-align: right;
  }
  label.hit-point-label {
    font-size: $text-smaller;
    font-weight: $normal-text;
  }
  div.hit-point-amount {
    font-size: $text-large;
  }
}
</style>
