export const rollADie = (sides) => (1 + Math.floor(Math.random() * sides));

export const rollDice = (dice, sides, format) => {
  // formats: text, total, array
  const diceRolls = [];
  for (let i = 0; i < dice; i += 1) {
    diceRolls.push(rollADie(sides));
  }
  const rollsTotal = diceRolls.reduce((acc, roll) => acc + roll);
  const formats = {
    text: `${diceRolls.join(' + ')} = ${rollsTotal}`,
    total: rollsTotal,
    array: diceRolls,
  };
  return formats[format] || 'You did not enter a valid return format (text, total, or array)';
};

export const sumValues = (numberArray) => numberArray.reduce((a, b) => +a + +b);

export const getDieSidesFromHitDieString = (hitDieString) => (hitDieString ? +hitDieString.replace('d', '') : 0);

export const parseDamageString = (damageString) => {
  const damageArray = damageString
    .replaceAll(' ', '')
    .replaceAll('-', '+-')
    .split('+');
  const dieRolls = [];
  const modifiers = [];
  damageArray.forEach((dmg) => (dmg.includes('d') ? dieRolls.push(dmg) : modifiers.push(dmg)));
  const splitDieRolls = [];
  dieRolls.forEach((dieRoll) => {
    const thisRoll = dieRoll.split('d');
    for (let i = 0; i < +thisRoll[0]; i += 1) {
      splitDieRolls.push(`d${thisRoll[1]}`);
    }
  });
  return [[...splitDieRolls], [...modifiers]];
};
