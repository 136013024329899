<template>
  <div class="race-class-alignment-panel fade-in">
    <div class="character-race-classes-levels pr-4"
      v-b-modal.raceClassAlignmentModal
    >
      <div class="cutcorner"/>
      {{ raceName }} {{ setClassLevelString() }} {{ alignment && `[${alignment}]` }}
    </div>
    <Modal
      modalTitle="Edit Race, Class, and Alignment"
      modalSize="xl"
      modalId="raceClassAlignmentModal"
      okOnly
    >
      <RaceClassAlignmentFields/>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue';
import RaceClassAlignmentFields from '@/components/RaceClassAlignmentFields.vue';
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'RaceClassAlignmentPanel',
  components: {
    Modal,
    RaceClassAlignmentFields,
  },
  computed: {
    ...mapFields('characterSheet', [
      'alignment',
      'characterClasses',
      'raceName',
    ]),
    ...mapGetters('characterSheet', [
      'getActiveClasses',
    ]),
  },
  methods: {
    setClassLevelString() {
      return this.characterClasses[0].name
        ? this.getActiveClasses
          .map((clasz) => `${clasz.name} ${clasz.classLevels}`)
          .join(' | ')
        : this.setClassLevelSubstring();
    },
    setClassLevelSubstring() {
      return this.raceName ? 'Select Your Class(es)' : 'Select Your Race and Class(es)';
    },
  },
};
</script>

<style scoped lang="scss">
.race-class-alignment-panel {
  text-align: right;
  position: relative;
  .character-race-classes-levels {
    position: relative;
    display: inline-block;
    padding-left: 3rem;
    overflow: hidden;
    transition: background-color 1s ease;
    @include hoverFocusStyle();
    .cutcorner:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: -1px;
      background-color: $body-bg;
      width: 14px;
      height: 102%;
      clip-path: polygon( 0 0, 0 0, 100% 100%, 0% 100%);
      z-index: 10;
    }
  }
  @media screen and (max-width: 768px) {
    .character-race-classes-levels {
      font-size: $text-smaller;
      line-height: 1.5;
    }
  }
}
@media screen and (max-width: 576px) {
  .race-class-alignment-panel {
    .character-race-classes-levels {
      width: 100%;
      text-align: center;
      .cutcorner {
        display: none;
      }
    }
  }
}
</style>
