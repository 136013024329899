<template>
  <div class="skill-wrapper fade-in">
    <div class="skill pl-2" v-b-modal="'viewEdit'+skill.name+'Modal'+skill.id">
      <span class="skill-name">
        <FontAwesomeIcon :icon="isClassSkill() ? classSkillIcon : nonClassSkillIcon" /> {{ skillNameWithIndicators(skill) }}
      </span>
      <span class="skill-abil-abbrev pl-1">({{ skillAbilityAbbrev }})</span>
      <span class="modifier px-3">{{ plusMinus(getSkillTotal(skill.name)) }}</span>
    </div>
    <RollACheck
      :checkName="skill.name"
      :modifier="getSkillTotal(skill.name)"
    />
    <Modal
      :modalTitle="'Detail - '+skill.name+' Skill'"
      modalSize="xl"
      :modalId="'viewEdit'+skill.name+'Modal'+skill.id"
      :modalIcon="skillStar"
      okOnly
    >
      <SkillRow
        :skill="skill"
      />
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RollACheck from '@/components/RollACheck.vue';
import Modal from '@/components/Modal.vue';
import SkillRow from '@/components/SkillRow.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faSquare, faCheckSquare, faStar } from '@fortawesome/free-regular-svg-icons';
import { plusMinus, abilAbbrev, skillNameWithIndicators } from '../utils/utils';

export default {
  name: 'Skill',
  components: {
    RollACheck,
    Modal,
    SkillRow,
    FontAwesomeIcon,
  },
  props: {
    skill: Object,
  },
  data() {
    return {
      classSkillIcon: faCheckSquare,
      nonClassSkillIcon: faSquare,
      skillStar: faStar,
    };
  },
  computed: {
    ...mapGetters('characterSheet', [
      'getSkillTotal',
      'getAllClassSkills',
      'getAllSkills',
    ]),
    skillAbilityAbbrev() {
      const skillAbil = this.getAllSkills.find((skill) => skill.name === this.skill.name);
      return abilAbbrev(skillAbil.abilityScore);
    },
  },
  methods: {
    plusMinus,
    skillNameWithIndicators,
    isClassSkill() {
      return !!this.getAllClassSkills.includes(this.skill.name);
    },
  },
};
</script>

<style lang="scss">
.skill-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  margin: 1% 0;
  border-radius: 3px;
  background: rgba($userTheme, .7);
  .skill {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-size: $text-med-small;
    font-weight: $thin-text;
    line-height: 1.5;
    width: 100%;
    height: 100%;
    @include hoverFocusStyle();
    .skill-name {
      width: 100%;
      text-align: left;
    }
    .modifier {
      position: relative;
      font-size: $text-standard;
      font-weight: $normal-text;
      line-height: 1;
    }
  }
}
</style>
