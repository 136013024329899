<template>
  <div class="accordion" role="tablist">
    <b-card
      no-body
      class="mb-1"
      :key="`collapse-item-${item.id}`"
    >
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button-group class="w-100">
          <b-button
            size="sm"
            v-if="!canDelete"
            class="w-100"
            v-b-toggle="`collapse-item-collapse-${item.id}`"
          >
            {{ item.name }}
          </b-button>
          <b-dropdown
            v-if="canDelete"
            class="w-100 collapse-dropdown"
            size="sm"
            right
            block
            split
            :text="item.name"
            v-b-toggle="`collapse-item-collapse-${item.id}`"
          >
            <b-dropdown-item
              @click="handleDeleteCollapseItem(item.id)"
            >
              <FontAwesomeIcon :icon="trashIcon" /> Delete {{ item.name }}
            </b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </b-card-header>
      <b-collapse class="px-2 pt-2" :id="`collapse-item-collapse-${item.id}`" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <slot/>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'Collapse',
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      trashIcon: faTrashAlt,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        name: '',
        id: '',
      }),
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleDeleteCollapseItem(itemIdToDelete) {
      this.$emit('handleDeleteCollapseItem', itemIdToDelete);
    },
  },
};
</script>

<style lang="scss">
.collapse-dropdown {
  button.dropdown-toggle {
    @include squareButton();
  }
  .dropdown-menu {
    font-size: $text-smaller;
    box-shadow: 0 0 8px $navbar-shadow;
  }
}
</style>
