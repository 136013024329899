<template>
  <div class="dice-tray fade-in">
    <div class="dice-tray-builder">
      <Button
        @handleButtonClick="rollSomeDice()"
      >Roll</Button>
      <SpinButton
        label="# of Dice"
        type="number"
        v-model="numOfDice"
      />
      <VueSelectField
        label="Die Type"
        :options="hitDice"
        v-model="diceType"
      />
      x
      <SpinButton
        label="# of Times"
        v-model="numOfTimes"
      />
      then
      <VueSelectField
        label="Modify Rolls"
        :options="modTypes"
        @handleVueSelectFieldChange="setModType"
        v-model="modifyType"
      />
      <SpinButton
        v-if="modifyType === 'Add / Subtract'"
        label="Modify Total"
        min=-10
        max=10
        v-model="selectedMod"
      />
    </div>
    <div v-if="displayResults">Results</div>
    <div class="dice-tray-results fade-in">
      <div v-for="(result, index) in displayResults" :key="index" class="single-result">
        <div v-html="result"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Button from '@/components/form/Button.vue';
import SpinButton from '@/components/form/SpinButton.vue';
import VueSelectField from '@/components/form/VueSelectField.vue';
import { rollDice, sumValues } from '../utils/diceUtils';

export default {
  name: 'DiceTray',
  components: {
    Button,
    SpinButton,
    VueSelectField,
  },
  data() {
    return {
      modTypes: [
        'No Modifier',
        'Drop Lowest',
        'Drop Highest',
        'Add / Subtract',
      ],
      numOfDice: 4,
      diceType: 'd6',
      numOfTimes: 6,
      modifyType: 'Drop Lowest',
      selectedMod: 0,
      results: [],
      resultTotals: [],
      displayResults: null,
    };
  },
  computed: {
    ...mapState([
      'hitDice',
    ]),
  },
  methods: {
    sumValues,
    setModType(value) {
      this.selectedMod = value;
    },
    rollSomeDice() {
      this.results = [];
      for (let i = 0; i < this.numOfTimes; i += 1) {
        this.results.push(rollDice(this.numOfDice, this.diceType.substring(1), 'array'));
      }
      this.displayResults = this.results.map((result) => this.getResult(result, this.modifyType));
    },
    dropLow(array, format = 'text') {
      const workArr = [...array];
      const lowest = Math.min(...workArr);
      const lowIndex = workArr.findIndex((el) => el === lowest);
      workArr.splice(lowIndex, 1);
      return format === 'text' ? `${workArr},(${lowest})` : workArr;
    },
    dropHigh(array, format = 'text') {
      const workArr = [...array];
      const highest = Math.max(...workArr);
      const highIndex = workArr.findIndex((el) => el === highest);
      workArr.splice(highIndex, 1);
      return format === 'text' ? `${workArr},(${highest})` : workArr;
    },
    getResult(result, option) {
      if (option === 'No Modifier') {
        return `<div class="result-total">${this.sumValues(result)}</div><div class="individual-rolls">${result}</div>`;
      }
      if (option === 'Drop Lowest') {
        return `<div class="result-total">${this.sumValues(this.dropLow(result, 'array'))}</div><div class="individual-rolls">${this.dropLow(result)}</div>`;
      }
      if (option === 'Drop Highest') {
        return `<div class="result-total">${this.sumValues(this.dropHigh(result, 'array'))}</div><div class="individual-rolls">${this.dropHigh(result)}</div>`;
      }
      if (option === 'Add / Subtract') {
        return `${result} + ${this.selectedMod} = <span class="font-weight-bold">${this.sumValues([this.sumValues(result), this.selectedMod])}</span>`;
      }
      return 'Invalid Option';
    },
  },
};
</script>

<style lang="scss">
.dice-tray {
  background: $panel-tab;
  padding: 10px 20px 5px;
  margin: 0 auto;
  border-radius: 20px;
  border: 2px dotted $panel-dividers;
  .dice-tray-builder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    .spin-button-field {
      margin-bottom: 1rem !important;
    }
    @media screen and (max-width: 768px) {
      div.anvil-button {
        width: 100%;
        div button {
          width: 100%;
        }
      }
    }
  }
  .dice-tray-results {
    height: 10%;
    min-height: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .single-result {
      div {
        text-align: center;
        .result-total {
          font-size: $text-title;
        }
        .individual-rolls {
          font-size: $text-small;
        }
      }
    }
  }
}
</style>
