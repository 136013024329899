import { getField, updateField } from 'vuex-map-fields';
import Vue from 'vue';
import {
  blankCharacter, emptyClass, emptyClassFeature, emptyRacialTrait, emptySpellcasting, emptyFeat, clearedFeat, emptyTrait, emptyEffect, emptyAttack, emptyInventoryItem, emptyDamage, emptyModifier, emptyDiceCheck, emptyUserAddedSkill, emptySkill,
} from '../database/blankCharacter';
import {
  setRaceAbilMods,
  plusMinus,
  getClassSaveBonusAtLevel,
  filterBonusStack,
  filterBonusTypes,
  trimBonusTypes,
  sumModifiers,
  baseAttackBonus,
  minZero,
  minOne,
  isKnownCaster,
  isActiveClass,
  filterObj,
  capIt,
  getArmorOrWeaponSlot,
  itemIsTwoHanded,
  removeEmptyObjectKeys,
  carryingCapacityBySize,
  setMaxDex,
  alphabetize,
  checkInitialize,
  arrayToObject,
  commaStringToArray,
  hasSpellbook,
  hasFamiliar,
  stripDashMinus,
} from '../../utils/utils';
import getId from '../../utils/idUtils';
import {
  formatLanguage,
  formatFeatObjectForData,
  compileBonusLanguages,
  formatFeat,
  getArrayIndex,
  formatSpellQuery,
} from '../../utils/vuexUtils';
import {
  getDieSidesFromHitDieString,
} from '../../utils/diceUtils';
import { BASE_STAT_VALUE } from '../../constants/metaConstants';

export default {
  namespaced: true,
  state: {
    ...blankCharacter(),
  },
  getters: {
    getField,
    getModifiersFromSource: (state) => (source) => source.reduce((a, b) => [...a, ...b.modifierIds], []).map((modId) => state.modifiers[modId]),
    getAllModifiers: (state, getters) => [...getters.getModifiersFromSource(getters.getEquippedGear), ...getters.getModifiersFromSource(Object.values(state.characterFeats)), ...getters.getModifiersFromSource(Object.values(state.userAddedFeats)), ...getters.getModifiersFromSource(Object.values(state.characterTraits)), ...getters.getModifiersFromSource(getters.getActiveEffects)],
    getActiveEffects: (state) => Object.values(state.effects).filter((effect) => effect.isActive),
    filterAndSumModifiers: (state, getters) => (modCategory) => sumModifiers(getters.filterModifiersByCategory(modCategory)),
    filterModifiersByCategory: (state, getters, rootState) => (modCategory) => filterBonusStack((getters.getAllModifiers).filter((mod) => mod.categories.find((cat) => cat.toLowerCase() === modCategory.toLowerCase())), rootState.stackableBonusTypes),
    buildModArray: (state, getters) => (modCategory) => getters.filterModifiersByCategory(modCategory).map((mod) => ({ name: mod.name, value: mod.amount })),
    getEquippedGear: (state) => Object.values(state.equipment).filter((ei) => ei.equippedItemId !== null).map((item) => state.inventory[item.equippedItemId]),
    getACBonusSum: (state, getters) => sumModifiers(getters.filterModifiersByCategory('Armor Class')),
    getACSizeMod: (state, getters, rootState) => (rootState.sizeCategories.find((cat) => cat.name === state.raceSize) || {}).acMod || 0,
    getCMSizeMod: (state, getters, rootState) => (rootState.sizeCategories.find((cat) => cat.name === state.raceSize) || {}).cmMod || 0,
    getArmorClass: (state, getters) => {
      const {
        getACBonusSum, getAbilityModifier, getMaxDexBonus, getACSizeMod,
      } = getters;
      return 10 + +getACBonusSum + +setMaxDex(+getAbilityModifier('Dexterity'), getMaxDexBonus).value + +getACSizeMod;
    },
    getACStringArray: (state, getters) => (useDex = true, typeFilters = []) => {
      const {
        filterModifiersByCategory, getAbilityModifier, getMaxDexBonus, getACSizeMod,
      } = getters;
      const dexMod = setMaxDex(+getAbilityModifier('Dexterity'), +getMaxDexBonus);
      const filteredModList = filterBonusTypes(filterModifiersByCategory('Armor Class'), [...typeFilters]);
      const nameValueMods = filteredModList.map((mod) => ({
        name: mod.name,
        value: mod.amount,
      }));
      const raceSizeMod = { name: state.raceSize, value: +getACSizeMod };
      return removeEmptyObjectKeys([...BASE_STAT_VALUE, (useDex && { ...dexMod }), ...nameValueMods, (!!(raceSizeMod.name && raceSizeMod.value !== 0) && { ...raceSizeMod })]);
    },
    getTouchAC: (state, getters) => {
      const {
        filterModifiersByCategory, getACSizeMod, getAbilityModifier, getMaxDexBonus,
      } = getters;
      const filterForTouch = sumModifiers(filterBonusTypes(filterModifiersByCategory('Armor Class'), ['armor', 'shield', 'natural']));
      return 10 + +filterForTouch + +setMaxDex(+getAbilityModifier('Dexterity'), +getMaxDexBonus).value + +getACSizeMod;
    },
    getFlatAC: (state, getters) => {
      const { filterModifiersByCategory, getACSizeMod } = getters;
      const filterForFlat = sumModifiers(filterBonusTypes(filterModifiersByCategory('Armor Class'), ['dodge']));
      return 10 + +filterForFlat + +getACSizeMod;
    },
    getCombatManeuverBonus: (state, getters) => {
      const {
        getBaseAttackBonus, getAbilityModifier, filterAndSumModifiers, getCMSizeMod,
      } = getters;
      return +getBaseAttackBonus + +getAbilityModifier('Strength') + +getCMSizeMod + +filterAndSumModifiers('cmb');
      // MONK - at 3rd lvl, the Monk gains 'Maneuver Training' and uses his Monk lvl instead of BaB for calculating CMB
      // //Agile Maneuvers feat - lets you use Dex instead of Str for CMB
    },
    getCombatManeuverDefense: (state, getters) => {
      const {
        filterModifiersByCategory, getBaseAttackBonus, getAbilityModifier, filterAndSumModifiers, getCMSizeMod,
      } = getters;
      const miscModTotal = sumModifiers(trimBonusTypes(filterModifiersByCategory('Armor Class'), ['circumstance', 'deflection', 'dodge', 'insight', 'luck', 'morale', 'profane', 'sacred']));
      return 10 + +getBaseAttackBonus + +getAbilityModifier('Strength') + +getAbilityModifier('Dexterity') + +getCMSizeMod + +miscModTotal + +filterAndSumModifiers('cmd');

      // Defensive Combat Training - Add your total character level instead of your Base Attack Bonus to your CMD
      // Monk - Adds Wisdom mod and Monk AC Bonus to CMD
    },
    getCombatManeuverBonusStringArray: (state, getters) => removeEmptyObjectKeys([
      { name: 'BaB', value: +getters.getBaseAttackBonus },
      { name: 'Strength', value: +getters.getAbilityModifier('Strength') },
      { name: state.raceSize || 'Size', value: +getters.getCMSizeMod },
      ...getters.buildModArray('CMB'),
    ]),
    getCombatManeuverDefenseStringArray: (state, getters) => removeEmptyObjectKeys([
      ...BASE_STAT_VALUE,
      { name: 'BaB', value: +getters.getBaseAttackBonus },
      { name: 'Strength', value: +getters.getAbilityModifier('Strength') },
      { name: 'Dexterity', value: +getters.getAbilityModifier('Dexterity') },
      { name: state.raceSize || 'Size', value: +getters.getCMSizeMod },
      ...trimBonusTypes(getters.filterModifiersByCategory('Armor Class'), ['circumstance', 'deflection', 'dodge', 'insight', 'luck', 'morale', 'profane', 'sacred']).map((mod) => ({ name: mod.name, value: mod.amount })),
      ...getters.buildModArray('CMD'),
    ]),
    getAbilityScore: (state, getters, rootState) => (abilityName) => {
      const levelBasedAbilityBonuses = Object.entries(state.abilityIncreases)
        .reduce((total, pair) => {
          const [incLvl, abilName] = pair;
          const incAmount = (incLvl === '1') ? 2 : 1;
          return abilName === abilityName ? total + incAmount : total + 0;
        }, 0);
      return +state.baseAbilityScores[abilityName] + +state.raceAbilityModifiers[abilityName] + +levelBasedAbilityBonuses + +rootState.ageCategories[state.ageCategory][abilityName] + +state.miscAbilityModifiers[abilityName] + +getters.filterAndSumModifiers(abilityName);
    },
    getAbilityModifier: (state, getters) => (abilityName) => plusMinus(Math.floor(getters.getAbilityScore(abilityName) / 2) - 5),
    getActiveClasses: (state) => state.characterClasses.filter((charClass) => isActiveClass(charClass)),
    getAvailableClasses: (state, getters, rootState) => rootState.classes.filter((clasz) => {
      const activeClassNames = getters.getActiveClasses.map((activeClass) => activeClass.name);
      return !(activeClassNames.indexOf(clasz.name) > -1);
    }),
    getFavoredClasses: (state, getters) => getters.getActiveClasses.filter((actClass) => actClass.isFavored).map((clz) => clz.name),
    getTotalClassLevels: (state, getters) => getters.getActiveClasses.reduce((a, b) => a + +b.classLevels, 0),
    getBaseAttackBonus: (state, getters) => (state.overrideBaB ? state.babOverride : getters.getActiveClasses.reduce((a, b) => a + +baseAttackBonus(b.BaB, b.classLevels), 0)),
    getBaseAttackBonusStringArray: (state, getters) => (state.overrideBaB ? [{ name: 'BaB Override', value: state.babOverride }] : removeEmptyObjectKeys([
      ...getters.getActiveClasses.map((clz) => ({ name: clz.name, value: +baseAttackBonus(clz.BaB, clz.classLevels) })),
    ])),
    getThisAttackBonus: (state, getters) => (attack) => +attack.attackBonus + +(attack.useBaB ? getters.getBaseAttackBonus : 0),
    getPointBuyTotal: (state) => {
      const pointChart = {
        1: -25, 2: -20, 3: -16, 4: -12, 5: -9, 6: -6, 7: -4, 8: -2, 9: -1, 10: 0, 11: 1, 12: 2, 13: 3, 14: 5, 15: 7, 16: 10, 17: 13, 18: 17, 19: 21, 20: 26, 21: 31, 22: 37, 23: 43, 24: 50, 25: 57,
      };
      return Object.values(state.baseAbilityScores).reduce((accum, score) => accum + pointChart[score], 0);
    },
    getRace: (state) => state.raceName,
    getSkillTotal: (state, getters) => (skillName) => {
      const skillData = getters.getAllSkills.find((skill) => skill.name === skillName);
      const abilMod = getters.getAbilityModifier(skillData.abilityScore);
      const skillRanks = ((getters.getAllClassSkills.includes(skillData.name) && skillData.ranks > 0) ? +skillData.ranks + +3 : +skillData.ranks);
      const stealthBonus = (skillName === 'Stealth' && state.raceSize === 'Small') ? 4 : 0;
      const armorCheckPenalty = skillData.armorCheckPenalty ? +getters.getArmorCheckPenalty : 0;
      return +abilMod + +skillRanks + +stealthBonus + +armorCheckPenalty + +getters.filterAndSumModifiers(skillName);
    },
    getAllSkills: (state) => alphabetize([...state.skillsList, ...Object.values(state.userAddedSkills)]),
    getClassSkills: (state, getters) => getters.getActiveClasses.reduce((accum, charClass) => [...accum, ...charClass.classSkills], []),
    getAllClassSkills: (state, getters) => [...getters.getClassSkills, ...state.userSelectedClassSkills],
    getTotalSkillPoints: (state, getters) => getters.getActiveClasses.reduce((acc, clasz) => acc + (clasz.name ? (minOne(clasz.skillPointsPerLevel + +getters.getAbilityModifier('Intelligence')) + (getters.getRace === 'Human' ? 1 : 0)) * clasz.classLevels : 0), 0) + +(getters.getActiveClasses.filter((clz) => clz.isFavored).reduce((a, b) => a + +b.favSp, 0)),
    getSkillPointsSpent: (state, getters) => getters.getAllSkills.reduce((acc, skill) => acc + +skill.ranks, 0),
    halflingLuck: (state, getters) => (getters.getRace === 'Halfling' ? 1 : 0),
    getCarryingCapacitySizeModifier: (state) => (weightLoad) => carryingCapacityBySize(weightLoad)[state.raceSize || 'Medium'],
    getCarryingCapacity: (state, getters, rootState) => (loadType) => (getters.getAbilityScore('Strength') > 4 ? getters.getCarryingCapacitySizeModifier(rootState.carryingCapacity[getters.getAbilityScore('Strength')][loadType]) : 0),
    getWeightLoad: (state) => Object.values(state.inventory).reduce((acc, invItem) => acc + +(Number(invItem.weight) || 0 * +invItem.quantity), 0),
    getSaveBonusFromClasses: (state, getters) => (saveType) => getters.getActiveClasses.reduce((a, b) => a + getClassSaveBonusAtLevel(b, saveType), 0),
    getSavingThrow: (state, getters) => (saveType, abilityScore) => {
      const {
        getSaveBonusFromClasses, getAbilityModifier, halflingLuck, filterAndSumModifiers,
      } = getters;
      return plusMinus(+getSaveBonusFromClasses(saveType) + +getAbilityModifier(abilityScore) + +halflingLuck + +filterAndSumModifiers(saveType));
    },
    getSavingThrowStringArray: (state, getters) => (saveType, abilityScore) => removeEmptyObjectKeys([
      { name: abilityScore, value: +getters.getAbilityModifier(abilityScore) },
      ...getters.getActiveClasses.map((clz) => ({ name: clz.name, value: getClassSaveBonusAtLevel(clz, saveType) })),
      ...getters.buildModArray(saveType),
      (getters.halflingLuck > 0 && { name: 'Halfling Luck', value: +getters.halflingLuck }),
    ]),
    hasMonkLevels: (state, getters) => (getters.getActiveClasses.find((cls) => cls.name === 'Monk') || {}).classLevels || 0,
    getInitiative: (state, getters) => plusMinus(+getters.getAbilityModifier('Dexterity') + +getters.filterAndSumModifiers('Initiative')),
    getInitiativeStringArray: (state, getters) => removeEmptyObjectKeys([
      { name: 'Dexterity', value: +getters.getAbilityModifier('Dexterity') },
      ...getters.buildModArray('Initiative'),
    ]),
    getSpeed: (state, getters) => +(state.raceSpeed || 0) + +getters.filterAndSumModifiers('Speed'),
    getSpeedStringArray: (state, getters) => removeEmptyObjectKeys([
      { name: state.raceName || '', value: state.raceSpeed || 0 },
      ...getters.buildModArray('Speed'),
    ]),
    getArmorCheckPenalty: (state, getters) => +getters.filterAndSumModifiers('Armor Check Penalty') + +getters.getEquippedGear.reduce((a, b) => a + (+stripDashMinus(b.stats['Armor Check Penalty'] || 0)), 0),
    getMaxDexBonus: (state, getters) => {
      const filteredMods = getters.filterModifiersByCategory('Max Dex Bonus');
      return filteredMods.length > 0 ? +sumModifiers(filteredMods) : 1000;
    },
    getArcaneSpellFailureChances: (state, getters) => getters.buildModArray('Arcane Spell Failure'),
    getHitPointLevelTotal: (state, getters) => (classId, level) => +state.characterClasses[classId].hpRolls[level] + +getters.getAbilityModifier('Constitution') + +((state.characterClasses[classId].isFavored && level < state.characterClasses[classId].favHp) ? 1 : 0),
    getTotalHitPoints: (state, getters) => {
      const trimmedHpRolls = getters.getActiveClasses.map((charClass) => charClass.hpRolls.slice(0, charClass.classLevels));
      return [].concat(...trimmedHpRolls).reduce((a, b) => a + (+b + +getters.getAbilityModifier('Constitution')), 0) + +(getters.getActiveClasses.filter((clz) => clz.isFavored).reduce((a, b) => a + +b.favHp, 0) + +(getters.filterAndSumModifiers('Hit Points')));
    },
    isSpellcaster: (state, getters) => getters.getActiveClasses.find((aClass) => aClass?.spellcasting?.isSpellcaster && aClass?.spellcasting['Level Increases'][0] <= aClass.classLevels),
    getDailySpellSlots: (state, getters) => (charClass) => (charClass?.spellcasting?.spellsPerDay || [])
      .map((spellLevel) => spellLevel[+charClass.classLevels - 1])
      .map((spellLevel, index) => (spellLevel !== null ? spellLevel + (spellLevel !== null ? +minZero(Math.ceil((getters.getAbilityModifier(charClass.spellcasting.castingAbility) - +minZero(+index - 1)) / 4)) : 0) : null)),
    getSpellsKnownPerLevel: () => (charClass) => charClass?.spellcasting?.spellsKnown.map((spellLevel) => spellLevel[+charClass.classLevels - 1]) || [],
    getHitPointsRecoveredFromRest: (state, getters) => {
      const { hoursOfRest, useCon, longTermCare } = state.rest;
      const healingPerHour = ((hoursOfRest >= 24 ? (2 * Math.floor(hoursOfRest / 24)) : 1) + +(useCon ? minZero(getters.getAbilityModifier('Constitution')) : 0)) * (longTermCare ? 2 : 1);
      const totalRestHealing = +(hoursOfRest >= 8 ? +getters.getTotalClassLevels : 0) * +healingPerHour;
      return totalRestHealing;
    },
    getExportFilename: (state, getters) => {
      const {
        characterName,
        raceName,
      } = state;
      const {
        getActiveClasses,
      } = getters;
      return characterName.replaceAll(' ', '') + capIt(raceName) + getActiveClasses.reduce((acc, item) => acc + (capIt(item.name) + item.classLevels), '');
    },
  },
  mutations: {
    updateField,
    deleteField(state, { list, id }) {
      Vue.delete(state[list], id);
    },
    setField(state, { list, id, value }) {
      Vue.set(state[list], id, value);
    },
    setCharacterTheme(state, field) {
      state.characterTheme = field.toLowerCase();
    },
    updateRaceField(state, field) {
      updateField(state.race, field);
    },
    addClassFeature(state, charClass) {
      const theClass = state.characterClasses[charClass.id];
      const id = getId();
      Vue.set(theClass.classFeatures, id, { ...emptyClassFeature(), id, name: `New ${charClass.name} Class Feature` });
    },
    updateClassFeature(state, { charClass, featureToUpdate }) {
      const theClass = state.characterClasses[charClass.id];
      const updatedFeature = { ...emptyClassFeature(), ...featureToUpdate };
      Vue.set(theClass.classFeatures, featureToUpdate.id, updatedFeature);
    },
    deleteClassFeature(state, { charClass, featureIdToDelete }) {
      Vue.delete(state.characterClasses[charClass.id].classFeatures, featureIdToDelete);
    },
    resetClassFeatures(state, { refetchedClass, classToReset }) {
      const { classFeatures } = refetchedClass;
      const updatedClass = {
        ...state.characterClasses[classToReset.id],
        classFeatures: checkInitialize(classFeatures),
      };
      Vue.set(state.characterClasses, classToReset.id, updatedClass);
    },
    addRacialTrait(state) {
      const id = getId();
      Vue.set(state.racialTraits, id, { ...emptyRacialTrait(), id, name: `New ${state.raceName} Racial Trait` });
    },
    updateRacialTrait(state, { racialTraitToUpdate }) {
      const updatedRacialTrait = { ...emptyRacialTrait(), ...racialTraitToUpdate };
      Vue.set(state.racialTraits, racialTraitToUpdate.id, updatedRacialTrait);
    },
    resetRacialTraits(state, refetchedRace) {
      const { racialTraits } = refetchedRace;
      state.racialTraits = checkInitialize(racialTraits);
    },
    updateClassSpellcasting(state, { charClass, spellcastingUpdate }) {
      const theClass = state.characterClasses[charClass.id];
      const updatedSpellcasting = { ...emptySpellcasting(), ...spellcastingUpdate };
      Vue.set(theClass, 'spellcasting', updatedSpellcasting);
    },
    saveHitPointRoll(state, { charClassId, level, result }) {
      state.characterClasses[charClassId].hpRolls.splice(level, 1, +result);
    },
    increaseAbilityScore(state, { lvl, scoreName }) {
      if (state.abilityIncreases[lvl] === scoreName) {
        state.abilityIncreases[lvl] = '';
      } else {
        state.abilityIncreases[lvl] = scoreName;
      }
    },
    updateSkill(state, { skill, ranks }) {
      const updatedSkill = { ...emptySkill(), ...skill, ranks };
      const skillToUpdate = getArrayIndex(state.skillsList, skill.name, 'name');
      if (skillToUpdate >= 0) {
        Vue.set(state.skillsList, skillToUpdate, updatedSkill);
      } else {
        Vue.set(state.userAddedSkills, skill.id, updatedSkill);
      }
    },
    addUserSelectedClassSkill(state, payload) {
      state.userSelectedClassSkills.push(payload);
    },
    removeUserSelectedClassSkill(state, payload) {
      state.userSelectedClassSkills = state.userSelectedClassSkills.filter((i) => i !== payload);
    },
    populateRace(state, selectedRace) {
      const {
        name,
        size,
        speed,
        raceAbilMods,
        startingAbilImprove,
        racialLanguages,
        racialTraits,
      } = selectedRace;
      state.raceName = name;
      state.raceSize = size;
      state.raceSpeed = speed;
      state.raceAbilityModifiers = setRaceAbilMods(raceAbilMods);
      state.raceStartingAbilMod = startingAbilImprove;
      state.racialLanguages = racialLanguages;
      state.racialTraits = checkInitialize(racialTraits);
    },
    populateClass(state, { selectedClass, classToPopulate }) {
      const {
        name,
        hitDie,
        BaB,
        savingThrows,
        skillPointsPerLevel,
        classSkills,
        classFeatures,
        spellcasting,
      } = selectedClass;
      const updatedClass = {
        ...state.characterClasses[classToPopulate.id],
        name,
        classArchetype: '',
        classLevels: 1,
        hitDie,
        BaB,
        savingThrows,
        skillPointsPerLevel,
        classSkills,
        spellcasting,
        classFeatures: checkInitialize(classFeatures),
        hpRolls: [classToPopulate.id === 0 ? getDieSidesFromHitDieString(hitDie) : 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        isFavored: false,
        favHp: 0,
        favSp: 0,
      };
      Vue.set(state.characterClasses, classToPopulate.id, updatedClass);
    },
    resetClass(state, classToReset) {
      for (let i = 3; i >= classToReset.id; i -= 1) {
        const resetClass = {
          ...state.characterClasses[i],
          ...emptyClass(),
        };
        Vue.set(state.characterClasses, i, resetClass);
      }
    },
    updateClassLevels(state, { classToUpdate, levels }) {
      state.characterClasses[classToUpdate.id].classLevels = levels;
    },
    updateFavoredClass(state, { classToUpdate }) {
      const thisClass = state.characterClasses[classToUpdate.id];
      thisClass.isFavored = !thisClass.isFavored;
      if (thisClass.isFavored) {
        thisClass.favSp = 0;
        thisClass.favHp = thisClass.classLevels;
      }
    },
    adjustFavoredHpSp(state, { classToAdjust }) {
      const thisClass = state.characterClasses[classToAdjust.id];
      thisClass.favHp = thisClass.classLevels - thisClass.favSp;
      thisClass.favSp = thisClass.classLevels - thisClass.favHp;
    },
    setFeats(state, { featsObj }) { // Sync gathered feats to characterFeats in state
      const newFeats = {};
      for (const [key, value] of Object.entries(featsObj)) {
        const {
          selection, selectedTypes, prerequisites, benefit, modifierIds,
        } = state.characterFeats[key] || {};
        newFeats[key] = {
          ...value,
          selection: selection || value.selection,
          selectedTypes: selectedTypes || value.selectedTypes,
          prerequisites: prerequisites || value.prerequisites,
          benefit: benefit || value.benefit,
          modifierIds: modifierIds || value.modifierIds,
        };
      }
      state.characterFeats = { ...newFeats };
    },
    setLanguages(state, { languagesObj }) {
      const newLanguages = {};
      for (const [key, value] of Object.entries(languagesObj)) {
        newLanguages[key] = { ...value, language: state?.knownLanguages[key]?.language || value.language };
      }
      state.knownLanguages = { ...newLanguages };
    },
    adjustHitPoints(state, hpAmount) {
      state.hpCurrent = +state.hpCurrent + +hpAmount;
    },
    updatePreparedSpellSlot(state, {
      charClass,
      spellLevel,
      spellSlot,
      spellData,
    }) {
      Vue.set(state.characterClasses[charClass.id].preparedSpells[spellLevel], spellSlot, spellData);
    },
    refreshAllSpellSlotsForClass(state, { charClass }) {
      state.characterClasses[charClass.id].preparedSpells = charClass.preparedSpells.map((spellLevelArray) => spellLevelArray.map((spellSlotObj) => (isKnownCaster(charClass) ? {} : { ...spellSlotObj, hasBeenCast: false })));
    },
    resetAllSpellSlotsForClass(state, { charClass }) {
      state.characterClasses[charClass.id].preparedSpells = charClass.preparedSpells.map((spellLevelArray) => spellLevelArray.map(() => ({})));
    },
    setRefreshClassSpells(state, { index, value }) {
      Vue.set(state.rest.refreshClassSpells, index, value);
    },
    updateSpellsKnownOfLevel(state, { charClass, spellLevelIndex, spellArray }) {
      Vue.set(state.characterClasses[charClass.id].knownSpells, spellLevelIndex, spellArray);
    },
    setClassSpellListForLevel(state, { charClass, spellList, spellLevel }) {
      const sortedSpellsList = alphabetize(spellList);
      Vue.set(state.characterClasses[charClass.id].classSpellsList, spellLevel, sortedSpellsList);
    },
    addUserSkill(state) {
      const id = getId();
      Vue.set(state.userAddedSkills, id, { ...emptyUserAddedSkill(), id, abilityScore: 'Strength' });
    },
    addUserFeat(state) {
      const id = getId();
      Vue.set(state.userAddedFeats, id, { ...emptyFeat(), id, name: 'Custom Feat' });
    },
    updateUserFeat(state, { userEnteredFeat, featToUpdate }) {
      const {
        name, source, type, prerequisites, benefit,
      } = userEnteredFeat || {};
      const updatedFeat = {
        ...state.userAddedFeats[featToUpdate.id],
        selection: name || '',
        source: source || '',
        types: commaStringToArray(type) || [],
        prerequisites: prerequisites || '',
        benefit: benefit || '',
      };
      Vue.set(state.userAddedFeats, featToUpdate.id, updatedFeat);
    },
    updateFeat(state, { selectedFeat, featToUpdate }) {
      const {
        name, type, prerequisites, benefit,
      } = selectedFeat || {};
      const updatedFeat = {
        ...state.characterFeats[featToUpdate.id],
        selection: name || '',
        selectedTypes: commaStringToArray(type) || [],
        prerequisites: prerequisites || '',
        benefit: benefit || '',
      };
      Vue.set(state.characterFeats, featToUpdate.id, updatedFeat);
    },
    clearFeat(state, { featToClear }) {
      Vue.set(state.characterFeats, featToClear.id, { ...state.characterFeats[featToClear.id], ...clearedFeat() });
    },
    addAttack(state) {
      state.attacks.push({ id: getId(), ...emptyAttack(), damage: [{ id: getId(), ...emptyDamage() }] });
    },
    deleteAttack(state, { attackData }) {
      state.attacks = state.attacks.filter((attack) => attack.id !== attackData.id);
    },
    addDamageType(state, { attack }) {
      const attackIndex = getArrayIndex(state.attacks, attack.id, 'id');
      state.attacks[attackIndex].damage.push({ id: getId(), ...emptyDamage() });
    },
    updateDamageType(state, { attack, damage }) {
      const attackIndex = getArrayIndex(state.attacks, attack.id, 'id');
      const damageIndex = getArrayIndex(state.attacks[attackIndex].damage, damage.id, 'id');
      Vue.set(state.attacks[attackIndex].damage, damageIndex, damage);
    },
    deleteDamageType(state, { attack, damage }) {
      const attackIndex = getArrayIndex(state.attacks, attack.id, 'id');
      state.attacks[attackIndex].damage = state.attacks[attackIndex].damage.filter((dmg) => dmg.id !== damage.id);
    },
    addInventoryItem(state) {
      const id = getId();
      Vue.set(state.inventory, id, { ...emptyInventoryItem(), id });
    },
    updateInventoryItem(state, { selectedInventoryItem, inventoryItemToUpdate }) {
      const {
        quantity = 1,
        name = '',
        type = '',
        weight = 0,
        cost = 0,
        denomination = '',
        description,
        slot,
        casterLevel = null,
        aura = '',
        source = '',
      } = selectedInventoryItem;
      const updatedInventoryItem = {
        ...state.inventory[inventoryItemToUpdate.id],
        quantity,
        name,
        type,
        slot: slot || getArmorOrWeaponSlot(selectedInventoryItem),
        weight,
        cost: +cost.replaceAll(',', '').replaceAll('.', ''),
        denomination,
        description,
        casterLevel,
        aura,
        source,
        stats: { ...selectedInventoryItem },
      };
      Vue.set(state.inventory, inventoryItemToUpdate.id, updatedInventoryItem);
    },
    toggleItemEquip(state, { item, slot }) {
      if ((slot === 'Left Hand' || slot === 'Right Hand') && itemIsTwoHanded(item)) {
        if (state.equipment['Left Hand'].equippedItemId !== item.id || state.equipment['Right Hand'].equippedItemId !== item.id) {
          state.equipment['Left Hand'].equippedItemId = item.id;
          state.equipment['Right Hand'].equippedItemId = item.id;
        } else {
          state.equipment['Left Hand'].equippedItemId = null;
          state.equipment['Right Hand'].equippedItemId = null;
        }
      } else {
        state.equipment[slot].equippedItemId = (state.equipment[slot].equippedItemId !== item.id ? item.id : null);
      }
    },
    addTrait(state) {
      const id = getId();
      Vue.set(state.characterTraits, id, { ...emptyTrait(), id });
    },
    updateTrait(state, { selectedTrait, traitToUpdate }) {
      const {
        name, type, subtype, description,
      } = selectedTrait;
      const updatedTrait = {
        ...state.characterTraits[traitToUpdate.id],
        name,
        type,
        subtype,
        description,
      };
      Vue.set(state.characterTraits, traitToUpdate.id, updatedTrait);
    },
    addEffect(state) {
      const id = getId();
      Vue.set(state.effects, id, { ...emptyEffect(), id });
    },
    addModifier(state, { payload = {}, modId }) {
      Vue.set(state.modifiers, modId, { id: modId, ...emptyModifier(), ...payload });
    },
    addModifierReference(state, { list, id, modId }) {
      state[list][id].modifierIds.push(modId);
    },
    updateModifier(state, { id, payload }) {
      Vue.set(state.modifiers, id, { id, ...payload });
    },
    deleteModifierReference(state, { list, id, deleteId }) {
      state[list][id].modifierIds = state[list][id].modifierIds.filter((modId) => modId !== deleteId);
    },
    deleteAllReferencedModifiers(state, modRefArray) {
      for (let m = 0; m < modRefArray.length; m += 1) {
        Vue.delete(state.modifiers, modRefArray[m]);
      }
    },
    logDiceCheck(state, checkObj) {
      state.diceCheckLog.push({ ...emptyDiceCheck(), ...checkObj });
    },
    clearDiceCheckLog(state) {
      state.diceCheckLog = [];
    },
  },
  actions: {
    toggleUserSelectedClassSkill({ commit, getters, state }, skillName) {
      if (!getters.getClassSkills.includes(skillName) && !state.userSelectedClassSkills.includes(skillName)) {
        commit('addUserSelectedClassSkill', skillName);
      } else if (state.userSelectedClassSkills.includes(skillName)) {
        commit('removeUserSelectedClassSkill', skillName);
      }
    },
    makeHitPointAdjustment({ commit, getters, state }, hitPointAmount) {
      const { getTotalHitPoints } = getters;
      const currHp = +state.hpCurrent;
      const amount = (+currHp + +hitPointAmount > +getTotalHitPoints) ? (+getTotalHitPoints - +currHp) : +hitPointAmount;
      commit('adjustHitPoints', amount);
    },
    setRace({ commit, dispatch }, raceData) {
      commit('populateRace', raceData);
      dispatch('gatherFeats');
      dispatch('gatherLanguages');
    },
    setClass({ commit, dispatch }, { selectedClass, classToPopulate }) {
      commit('populateClass', { selectedClass, classToPopulate });
      dispatch('gatherFeats');
      dispatch('gatherLanguages');
      dispatch('gatherClassSpells', classToPopulate);
    },
    updateClass({ commit, dispatch }, { classToUpdate, levels }) {
      commit('updateClassLevels', { classToUpdate, levels });
      dispatch('gatherFeats');
      dispatch('gatherLanguages');
      dispatch('gatherClassSpells', classToUpdate);
      commit('adjustFavoredHpSp', { classToAdjust: classToUpdate });
    },
    removeClass({ commit, dispatch }, classToReset) {
      commit('resetClass', classToReset);
      dispatch('gatherFeats');
      dispatch('gatherLanguages');
    },
    revertToDefaultClassFeatures({ commit, dispatch }, classToReset) {
      dispatch('fetchList', { urlPath: 'classes', targetList: 'classes' }, { root: true })
        .then(() => {
          dispatch('fetchListItem', { value: classToReset.name, listToSearch: 'classes', urlPath: 'classes' }, { root: true })
            .then((response) => {
              if (response && response.length > 0) {
                commit('resetClassFeatures', { refetchedClass: response[0], classToReset });
              }
            });
        });
    },
    revertToDefaultRacialTraits({ commit, dispatch, state }) {
      dispatch('fetchList', { urlPath: 'races', targetList: 'races' }, { root: true })
        .then(() => {
          dispatch('fetchListItem', { value: state.raceName, listToSearch: 'races', urlPath: 'races' }, { root: true })
            .then((response) => {
              if (response && response.length > 0) {
                commit('resetRacialTraits', response[0]);
              }
            });
        });
    },
    setFeat({ commit, dispatch }, selectedFeat) {
      dispatch('fetchListItem', { value: selectedFeat?.selection || '', listToSearch: 'feats', urlPath: 'feats' }, { root: true })
        .then((response) => {
          if (response && !!response.length) {
            commit('updateFeat', { selectedFeat: response[0], featToUpdate: selectedFeat });
          } else {
            commit('clearFeat', { featToClear: selectedFeat });
          }
        });
    },
    removeFieldAndModifiers({ commit }, { list, value }) {
      commit('deleteAllReferencedModifiers', value.modifierIds);
      commit('deleteField', { list, id: value.id });
    },
    removeModifier({ commit }, { list, id, deleteId }) {
      commit('deleteModifierReference', { list, id, deleteId });
      commit('deleteField', { list: 'modifiers', id: deleteId });
    },
    setModifier({ commit }, { list, id, payload }) {
      const modId = getId();
      commit('addModifierReference', { list, id, modId });
      commit('addModifier', { payload, modId });
    },
    gatherFeats({ commit, getters, state }) {
      const levelFeatsArray = [];
      for (let i = getters.getTotalClassLevels; i >= 0; i -= 1) {
        if (i % 2 && i > 0) {
          levelFeatsArray.push(formatFeat(state.characterFeats, 'Feat', {}, '', emptyFeat(), i));
        }
      }
      const levelFeats = arrayToObject(levelFeatsArray);
      const racialFeats = formatFeatObjectForData(state.characterFeats, filterObj(state.racialTraits, 'Bonus Feats'), emptyFeat(), state.raceName, 1);
      const classFeats = Object.assign({}, ...getters.getActiveClasses.map((activeClass) => formatFeatObjectForData(state.characterFeats, filterObj(activeClass.classFeatures, 'Bonus Feats'), emptyFeat(), activeClass.name, activeClass.classLevels)) || {});
      const sortedFeatsArray = Object.entries({
        ...levelFeats, ...racialFeats, ...classFeats,
      }).sort((a, b) => a[1].levelGained - b[1].levelGained);
      commit('setFeats', { featsObj: Object.fromEntries(sortedFeatsArray) });
    },
    gatherLanguages: ({ commit, state, getters }) => {
      const languagesArray = (state.raceName && state.racialLanguages) ? state.racialLanguages.map((lang, i) => formatLanguage(state.knownLanguages, `${state.raceName} ${+i + +1}`, 'Racial', lang, [])) : [];
      const classBonusLanguages = getters.getActiveClasses.map((charClass) => compileBonusLanguages(charClass.classFeatures));
      const racialBonusLanguages = compileBonusLanguages(state?.racialTraits);
      const allBonusLanguages = [...new Set([].concat(...racialBonusLanguages, ...classBonusLanguages))];
      for (let i = 0; i < getters.getAbilityModifier('Intelligence'); i += 1) {
        languagesArray.push(formatLanguage(state.knownLanguages, `Intelligence +${+i + 1}`, 'Bonus Language', '', allBonusLanguages));
      }
      const linguisticsSkillRanks = getters.getAllSkills.find((skil) => skil.name === 'Linguistics');
      for (let l = 0; l < linguisticsSkillRanks.ranks; l += 1) {
        languagesArray.push(formatLanguage(state.knownLanguages, `Linguistics ${+l + 1}`, 'Bonus Language', '', []));
      }
      commit('setLanguages', {
        languagesObj: arrayToObject(languagesArray),
      });
    },
    gatherClassSpells({ commit, dispatch, getters }, charClass) {
      let dailySpellSlotsPerLevelArray = getters.getDailySpellSlots(charClass);
      if (hasSpellbook(charClass) || hasFamiliar(charClass)) { dailySpellSlotsPerLevelArray = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]; }
      for (let spellLevel = 0; spellLevel < dailySpellSlotsPerLevelArray.length; spellLevel += 1) {
        if (charClass.classSpellsList[spellLevel].length === 0 && dailySpellSlotsPerLevelArray[spellLevel] > 0) {
          dispatch('fetchUrlPath', formatSpellQuery(charClass, spellLevel), { root: true })
            .then((response) => {
              commit('setClassSpellListForLevel', { charClass, spellList: response, spellLevel });
            });
        }
      }
    },
    gatherKnownSpellsOfLevel({ commit, dispatch }, { charClass, spellLevelIndex, spellArray }) {
      const knownSpellsOfLevel = charClass.knownSpells[spellLevelIndex];
      const overlayExistingSpellData = spellArray.map((sp) => knownSpellsOfLevel.find((ksp) => ksp.name === sp.name) || sp);
      const existingSpells = overlayExistingSpellData.filter((spell) => spell && spell.description);
      const spellsThatNeedFetching = overlayExistingSpellData.filter((spell) => spell && !spell.description);
      const fetchNewSpellData = spellsThatNeedFetching.map((async (val) => dispatch('fetchUrlPath', `spells/${encodeURIComponent(val.name)}`, { root: true })));
      Promise.all(fetchNewSpellData).then((completed) => completed.map((singleSpellArray) => (({
        name,
        school,
        subschool,
        spell_level: spellLevel,
        casting_time: castingTime,
        components,
        range,
        area,
        targets,
        duration,
        saving_throw: savingThrow,
        spell_resistance: spellResistance,
        description,
        descriptor,
      }) => ({
        name,
        school,
        subschool,
        spellLevel,
        castingTime,
        components,
        range,
        area,
        targets,
        duration,
        savingThrow,
        spellResistance,
        description,
        descriptor,
      }))(singleSpellArray[0])))
        .then((fetchedSpells) => {
          commit('updateSpellsKnownOfLevel', { charClass, spellLevelIndex, spellArray: [...existingSpells, ...fetchedSpells] });
        });
    },
  },
};
