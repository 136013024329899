<template>
  <Fragment>
    <div class="modifier-display">
      {{ modNumber }}
      <div class="individual-mod">{{ '['+modText+']' }}</div>
    </div>
    <span v-if="showPlus"><FontAwesomeIcon :icon="plusIcon" /></span>
  </Fragment>
</template>

<script>
import { Fragment } from 'vue-fragment';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'ModifierDisplay',
  components: {
    Fragment,
    FontAwesomeIcon,
  },
  data() {
    return {
      plusIcon: faPlus,
    };
  },
  props: {
    modNumber: {
      type: [Number, String],
      default: 0,
    },
    modText: {
      type: String,
      default: '',
    },
    showPlus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.modifier-display {
  font-size: $text-title;
  text-align: center;
  line-height: 1;
  min-width: 100px;
  .individual-mod {
    font-size: $text-small;
  }
}
@media screen and (max-width: 576px) {
  .modifier-display {
    font-size: $text-small;
    min-width: 50px;
    .individual-mod {
      font-size: $text-min;
    }
  }
}
</style>
