export const APP_TITLE = 'ANViL - 1st Edition Pathfinder Character Generator';

export const BASE_STAT_VALUE = [
  {
    name: 'Base',
    value: 10,
  },
];

export const SAVE_TYPES = {
  Fort: 0,
  Ref: 1,
  Will: 2,
};
