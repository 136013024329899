<template>
  <div class="class-features-panel my-1" v-if="isActiveClass(charClass)">
    <b-form-row>
      <b-col class="class-features-title-flex">
        <div class="class-features-title-wrapper" v-b-modal="'editClassFeaturesModal'+charClass.id">
          <div class="class-features-title">{{ charClass.name }}</div>
        </div>
      </b-col>
      <b-col class="">
        <div class="class-features-wrapper">
          <div v-if="isSpellcastingClass(charClass)">
            <span
              v-if="(isSpellcastingClass(charClass) && getLowestArrayValue(charClass.spellcasting['Level Increases']) <= charClass.classLevels)"
              title="Spellcasting"
              v-b-popover.hover.click.focus.blur.top="'This is a spellcasting class.'"
            >
              Spellcasting
            </span>
          </div>
          <div v-for="(feature, index) in charClass.classFeatures" :key="index">
            <span
              v-if="(feature && getLowestArrayValue(feature['Level Increases']) <= charClass.classLevels)"
              :title="feature.name" v-b-popover.hover.click.focus.blur.top="feature.Description">{{ feature.name }}
            </span>
          </div>
        </div>
      </b-col>
    </b-form-row>
    <Modal
      :modalTitle="`Edit Class Features - ${charClass.name}`"
      :modalId="'editClassFeaturesModal'+charClass.id"
      modalClass="edit-class-features-modal"
      modalSize="xl"
      okOnly
      isScrollable
    >
      <div class="add-feature-btn-group">
        <Button
          alt="Add Class Feature"
          size="sm"
          btnClass="add-class-feature-btn"
          centered
          @handleButtonClick="addClassFeature(charClass)"
        >
          + Add Class Feature
        </Button>
        <Button
          alt="Toggle Spellcasting"
          size="sm"
          :btnClass="`toggle-spellcasting-btn${isSpellcastingClass(charClass) ? ' active' : ''}`"
          centered
          @handleButtonClick="updateClassSpellcasting({ charClass, spellcastingUpdate: { ...charClass.spellcasting, isSpellcaster: !isSpellcastingClass(charClass) } })"
        >
          {{ checkSpellcasting ? 'Remove Spellcasting' : '+ Add Spellcasting' }}
        </Button>
      </div>
      <Collapse
        v-if="isSpellcastingClass(charClass)"
        :item="charClass.spellcasting"
        :canDelete="false"
      >
        <SpellcastingFields
          :charClass="charClass"
        />
      </Collapse>
      <Collapse
        v-for="feature in charClass.classFeatures"
        :key="`class-feature-${feature.id}`"
        :item="feature"
        @handleDeleteCollapseItem="deleteClassFeature({ charClass, featureIdToDelete: feature.id })"
      >
        <ClassFeatureFields
          :charClass="charClass"
          :feature="feature"
        />
      </Collapse>
      <Button
        v-b-popover.hover.focus.blur.top="setRevertButtonPopoverText()"
        size="sm"
        id="revert-default-class-features-btn"
        btnClass="revert-default-class-features-btn"
        centered
        @handleButtonClick="confirmRevertClassFeatures"
      >
        {{ confirmRevert ? 'Confirm?' : 'Reset Defaults' }}
      </Button>
    </Modal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from '@/components/Modal.vue';
import Button from '@/components/form/Button.vue';
import ClassFeatureFields from '@/components/ClassFeatureFields.vue';
import SpellcastingFields from '@/components/SpellcastingFields.vue';
import Collapse from '@/components/Collapse.vue';
import { isActiveClass, getLowestArrayValue, isSpellcastingClass } from '../utils/utils';

export default {
  name: 'ClassFeaturesPanel',
  components: {
    Modal,
    Button,
    ClassFeatureFields,
    SpellcastingFields,
    Collapse,
  },
  data() {
    return {
      confirmRevert: false,
    };
  },
  props: {
    charClass: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    checkSpellcasting() {
      return isSpellcastingClass(this.charClass);
    },
  },
  methods: {
    isActiveClass,
    getLowestArrayValue,
    isSpellcastingClass,
    ...mapMutations('characterSheet', [
      'addClassFeature',
      'deleteClassFeature',
      'updateClassSpellcasting',
    ]),
    confirmRevertClassFeatures() {
      this.confirmRevert = !this.confirmRevert;
      if (!this.confirmRevert) {
        this.$store.dispatch('characterSheet/revertToDefaultClassFeatures', this.charClass);
      }
    },
    setRevertButtonPopoverText() {
      return this.confirmRevert ? 'Are you sure? This will delete any custom features you have added.' : `Revert to Default ${this.charClass.name} Class Features`;
    },
  },
};
</script>

<style lang="scss">
.class-features-panel {
  .class-features-title-flex {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    .class-features-title-wrapper {
      height: 100%;
      min-height: 100%;
      min-width: 25px;
      max-width: 25px;
      @include flexColumnCenter();
      @include hoverFocusStyle();
      margin-right: 10px;
      .class-features-title {
        transform: rotate(270deg);
        text-align: center;
      }
    }
  }
}
.add-feature-btn-group {
  @include flexRowCenter();
}
.add-class-feature-btn,
.toggle-spellcasting-btn,
.revert-default-class-features-btn {
  margin-right: 32px;
}
#revert-default-class-features-btngroup {
  margin-top: 30px;
}
.feature-dropdown {
  button.dropdown-toggle {
    @include squareButton();
  }
  .dropdown-menu {
    font-size: $text-smaller;
    box-shadow: 0 0 8px $navbar-shadow;
  }
}

</style>
