<template>
  <div class="effects fade-in">
    <Button
      alt="Add Effect"
      size="sm"
      centered
      btnClass="add-effect-btn"
      @handleButtonClick="addEffect"
    >
      + Add Effect
    </Button>
    <EffectFields
      v-for="(effect, index) in effects"
      :key="`effect-${index}`"
      :effect="effect"
    />
  </div>
</template>

<script>
import { mapMultiRowFields } from 'vuex-map-fields';
import { mapMutations } from 'vuex';
import Button from '@/components/form/Button.vue';
import EffectFields from '@/components/EffectFields.vue';

export default {
  name: 'EffectsPanel',
  components: {
    Button,
    EffectFields,
  },
  computed: {
    ...mapMultiRowFields('characterSheet', [
      'effects',
    ]),
  },
  methods: {
    ...mapMutations('characterSheet', [
      'addEffect',
    ]),
  },
};
</script>
