<template>
  <div :class="`section-divider ${dividerClass}`">
    {{ title }}
  </div>
</template>

<script>

export default {
  name: 'SectionDivider',
  props: {
    title: String,
    dividerClass: {
      type: String,
      default: 'my-4',
    },
  },
};
</script>

<style scoped lang="scss">
.section-divider {
  display: flex;
  align-items: center;
  text-align: center;
}
.section-divider::before, .section-divider::after {
    content: '';
    flex: 1;
}
.section-divider::before {
    margin-right: 0.75rem;
}
.section-divider::after {
    margin-left: 0.75rem;
}
</style>
