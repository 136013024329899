<template>
  <div class="skills-panel m-3">
    <div class="skills-info">
      <Modal
        modalTitle="Spend Skill Points"
        modalSize="xl"
        modalId="spendSkillPointsModal"
        :modalIcon="skillStar"
        okOnly
      >
        <SkillFields/>
      </Modal>
      <Modal
        modalTitle="Manage Custom Skills"
        modalSize="xl"
        modalId="manageCustomSkillsModal"
        :modalIcon="skillIcon"
        okOnly
        preventCloseOnSubmit
        preventCloseOnHide
        @handleModalSubmit="customSkillsModalSubmit"
        @handleModalHide="customSkillsModalHide"
      >
        <SkillCustomFields/>
      </Modal>
    </div>
    <div class="skills-actions">
      <DropdownButton
        id="filter-skills-dropdown"
        buttonText=""
        :buttonIcon="filterIcon"
        dropdownWrapperClass="mb-3 mr-2"
        dropdownPopoverContent="Filter Skill List"
        :dropdownOptions="skillFilters"
        @handleDropdownButtonChange="setSkillFilters($event)"
      >
      </DropdownButton>
      <Button
        id="manage-custom-skills"
        label="Manage Custom Skills"
        :showLabel="false"
        square
        v-b-modal.manageCustomSkillsModal
        v-b-popover.hover.focus.blur.top="'Manage Custom Skills'"
      >
        <FontAwesomeIcon :icon="skillIcon" />
      </Button>
      <Button
        id="spend-skill-points"
        label="Spend Skill Points"
        :showLabel="false"
        v-b-popover.hover.focus.blur.top="'Spend Skill Points'"
        v-b-modal.spendSkillPointsModal
      >
        {{ getSkillPointsSpent }}/{{ getTotalSkillPoints }} Skill Points Spent
      </Button>
    </div>
    <div class="skills-container">
      <Skill
        v-for="(skill, skillId) in filteredSkillsList"
        :key="`skill-${skillId}`"
        :skill="skill"
      />
    </div>
    <div class="skills-keys">
      <div class="skills-key">* Armor Check Penalty {{ getArmorCheckPenalty }}</div>
      <div class="skills-key">† Trained Only</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mapMultiRowFields } from 'vuex-map-fields';
import Modal from '@/components/Modal.vue';
import SkillFields from '@/components/SkillFields.vue';
import SkillCustomFields from '@/components/SkillCustomFields.vue';
import Skill from '@/components/Skill.vue';
import Button from '@/components/form/Button.vue';
import DropdownButton from '@/components/form/DropdownButton.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faIconsAlt } from '@fortawesome/pro-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { filterList } from '../utils/utils';

export default {
  name: 'SkillsPanel',
  components: {
    Modal,
    SkillFields,
    SkillCustomFields,
    Skill,
    Button,
    DropdownButton,
    FontAwesomeIcon,
  },
  data() {
    return {
      skillIcon: faIconsAlt,
      skillStar: faStar,
      filterIcon: faFilter,
      skillFilterMethods: {
        class: (skill) => this.getAllClassSkills.includes(skill.name),
        nonclass: (skill) => !this.getAllClassSkills.includes(skill.name),
        custom: (skill) => [...Object.values(this.userAddedSkills)].map((userSkl) => userSkl.name).includes(skill.name),
        ranks: (skill) => skill.ranks > 0,
        noranks: (skill) => skill.ranks === 0,
        hideuntrained: (skill) => !(skill.ranks === 0 && skill.trainedOnly === true),
      },
    };
  },
  computed: {
    ...mapState([
      'errors',
    ]),
    ...mapMultiRowFields([
      'skillFilters',
    ]),
    ...mapMultiRowFields('characterSheet', [
      'skillsList',
      'userAddedSkills',
    ]),
    ...mapGetters('characterSheet', [
      'getTotalSkillPoints',
      'getSkillPointsSpent',
      'getAllClassSkills',
      'getArmorCheckPenalty',
      'getAllSkills',
    ]),
    customSkillNames() {
      return [...Object.values(this.userAddedSkills)].map((skill) => skill.name);
    },
    activeSkillFilterMethods() {
      return this.skillFilters.filter((skillFilter) => skillFilter.isActive).reduce((activeFilterMethods, skillFilter) => {
        const { value } = skillFilter;
        return { ...activeFilterMethods, [value]: this.skillFilterMethods[value] };
      }, {});
    },
    filteredSkillsList() {
      return filterList(this.getAllSkills, this.activeSkillFilterMethods);
    },
  },
  methods: {
    customSkillsModalSubmit() {
      this.validateSkillNames();
      if (!this.errors.length) {
        this.hideModal('manageCustomSkillsModal');
      }
    },
    customSkillsModalHide() {
      this.validateSkillNames();
      if (!this.errors.length) {
        this.hideModal('manageCustomSkillsModal');
      }
    },
    validateSkillNames() {
      this.$store.commit('clearErrors');
      const foundSkillName = this.getAllSkills.map((skill) => skill.name).filter((skillName, index) => this.getAllSkills.map((skill) => skill.name).indexOf(skillName) !== index);
      if (foundSkillName.length > 0) {
        this.$store.commit('addError', 'Each skill name must be unique.');
      }
    },
    hideModal(modalName) {
      this.$nextTick(() => {
        this.$bvModal.hide(modalName);
      });
    },
    setSkillFilters(activeFilters) {
      this.$store.commit('setSkillFilters', activeFilters);
    },
  },
};
</script>

<style scoped lang="scss">
.skills-panel {
  .skills-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    #manage-custom-skillsgroup,
    #filter-skillsgroup {
      margin-right: 0.5rem;
    }
  }
  .skills-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .skills-keys {
    .skills-key {
      font-size: $text-med-small;
    }
  }
}
</style>
