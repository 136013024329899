<template>
  <div class="effect-fields fade-in">
    <b-form-row>
      <b-col class="col-6">
        <InputField
          :id="`effect-name-${effect.id}`"
          label="Effect Name"
          v-model="effect.name"
        />
      </b-col>
      <b-col class="col-5">
        <InputField
          :id="`effect-source-${effect.id}`"
          label="Effect Source"
          v-model="effect.type"
        />
      </b-col>
      <b-col class="col-1 d-flex justify-content-center align-items-center remove-effect-btn">
        <Button
          size="sm"
          :btnClass="`remove-effect-btn-${effect.id}`"
          label="Remove This Effect"
          :showLabel="false"
          @handleButtonClick="removeFieldAndModifiers({ list: 'effects', value: effect })"
          v-b-popover.hover.focus.blur.top="'Remove This Effect'"
        >
          <FontAwesomeIcon :icon="removeIcon" />
        </Button>
      </b-col>
    </b-form-row>
    <ModifierPanel
      :parentElement="effect"
      parentList="effects"
      :addModifierButtonLabel="`+ Add Modifier to ${effect.name ? effect.name : 'Effect'}`"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import InputField from '@/components/form/InputField.vue';
import ModifierPanel from '@/components/ModifierPanel.vue';
import Button from '@/components/form/Button.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'EffectFields',
  components: {
    InputField,
    ModifierPanel,
    Button,
    FontAwesomeIcon,
  },
  data() {
    return {
      removeIcon: faMinus,
    };
  },
  props: {
    effect: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    ...mapActions('characterSheet', [
      'removeFieldAndModifiers',
    ]),
  },
};
</script>

<style lang="scss">
.remove-effect-btn {
  div.anvil-button.form-group.form-button {
    margin-top: 0.6rem;
    margin-bottom: 0 !important;
  }
}
</style>
