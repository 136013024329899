<template>
  <div class="trait-fields fade-in">
    <b-form-row>
      <b-col class="col-12 col-sm-6">
        <VueSelectField
          id="trait-name"
          label="Trait Name"
          v-model="trait.name"
          :options="traits"
          taggable
          textField="name"
          valueField="name"
          placeholder="- Select / Enter Trait Name -"
          @handleVueSelectFieldChange="setTraitData"
        />
      </b-col>
      <b-col class="col-6 col-sm-3">
        <VueSelectField
          id="trait-type"
          label="Type"
          v-model="trait.type"
          :options="traitTypes"
          taggable
          placeholder="- Trait Type -"
        />
      </b-col>
      <b-col class="col-6 col-sm-3">
        <InputField
          id="trait-subtype"
          label="Subtype"
          v-model="trait.subtype"
        />
      </b-col>
    </b-form-row>
    <ModifierPanel
      :parentElement="trait"
      parentList="characterTraits"
      :addModifierButtonLabel="`+ Add Modifier to ${trait.name ? trait.name : 'Trait'}`"
    />
    <b-form-row>
      <b-col class="col-12">
        <TextAreaField
          id="trait-description"
          label="Description"
          rows="5"
          v-model="trait.description"
        />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import InputField from '@/components/form/InputField.vue';
import VueSelectField from '@/components/form/VueSelectField.vue';
import TextAreaField from '@/components/form/TextAreaField.vue';
import ModifierPanel from '@/components/ModifierPanel.vue';

export default {
  name: 'TraitFields',
  created() {
    this.$store.dispatch('fetchList', { urlPath: 'traits', targetList: 'traits' });
  },
  components: {
    InputField,
    VueSelectField,
    TextAreaField,
    ModifierPanel,
  },
  props: {
    trait: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState([
      'traits',
      'traitTypes',
    ]),
  },
  methods: {
    setTraitData(value) {
      this.$store.dispatch('fetchListItem', { value, listToSearch: 'traits', urlPath: 'traits' })
        .then((response) => {
          if (response.length > 0) {
            this.$store.commit('characterSheet/updateTrait', { selectedTrait: response[0], traitToUpdate: this.trait });
          }
        });
    },
  },
};
</script>
