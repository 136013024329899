<template>
  <div class="character-builder" v-if="!stepEdit">
    <b-form-row class="m-0 p-0">
      <b-col class="col-12 char-info-wrapper m-0 p-0">
        <div class="top-panel-wrapper">
          <NamePanel />
          <RaceClassAlignmentPanel />
        </div>
      </b-col>
      <b-col class="col-12">
        <b-row class="sheet-wrapper m-0">
          <b-col class="col-12">
            <b-form-row>
              <b-col class="col-6 offset-3 offset-sm-0 col-sm-4 col-xl-3">
                <PortraitPanel />
              </b-col>
              <b-col class="col-12 col-sm-8 col-xl-9">
                <b-form-row>
                  <b-col class="col-12">
                    <AbilityScoresPanel />
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col class="col-12">
                    <ViewPanel
                      title="Defenses"
                    >
                      <DefensesPanel />
                    </ViewPanel>
                  </b-col>
                </b-form-row>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col class="col-12 col-sm-6 col-lg-8">
                <b-form-row>
                  <b-col class="col-12">
                    <ViewPanel
                      title="Combat"
                    >
                      <CombatPanel />
                    </ViewPanel>
                  </b-col>
                </b-form-row>
                <b-form-row v-if="isSpellcaster">
                  <b-col class="col-12">
                    <ViewPanel
                      title="Spellcasting"
                    >
                      <SpellcastingPanel />
                    </ViewPanel>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col class="col-12">
                    <ViewPanel
                      title="Class Features & Racial Traits"
                      :defaultOpen="false"
                    >
                      <ClassAndRacePanel />
                    </ViewPanel>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col class="col-12">
                    <ViewPanel
                      title="Inventory"
                      :defaultOpen="false"
                    >
                      <InventoryPanel />
                    </ViewPanel>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col class="col-12">
                    <ViewPanel
                      title="Equipment"
                      :defaultOpen="false"
                    >
                      <EquipmentPanel />
                    </ViewPanel>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col class="col-12 col-lg-6">
                    <ViewPanel
                      title="Feats"
                    >
                      <FeatsPanel />
                    </ViewPanel>
                  </b-col>
                  <b-col class="col-12 col-lg-6">
                    <ViewPanel
                      title="Traits"
                    >
                      <TraitsPanel />
                    </ViewPanel>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col class="col-12 col-lg-4">
                    <ViewPanel
                      title="Carrying Capacity"
                      :defaultOpen="false"
                    >
                      <CarryingCapacityPanel />
                    </ViewPanel>
                  </b-col>
                  <b-col class="col-12 col-lg-4">
                    <ViewPanel
                      title="Languages"
                    >
                      <LanguagesPanel />
                    </ViewPanel>
                  </b-col>
                  <b-col class="col-12 col-lg-4">
                    <ViewPanel
                      title="Notes"
                      :defaultOpen="false"
                    >
                      <NotesPanel />
                    </ViewPanel>
                  </b-col>
                </b-form-row>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4">
                <b-form-row>
                  <b-col class="col-12">
                    <ViewPanel
                      title="Status"
                      :defaultOpen="false"
                    >
                      <StatusPanel />
                    </ViewPanel>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col class="col-12">
                    <ViewPanel
                      title="Description"
                      :defaultOpen="false"
                    >
                      <BioPanel />
                    </ViewPanel>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col class="col-12">
                    <ViewPanel
                      title="Skills"
                    >
                      <SkillsPanel />
                    </ViewPanel>
                  </b-col>
                </b-form-row>
              </b-col>
            </b-form-row>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import PortraitPanel from '@/components/PortraitPanel.vue';
import NamePanel from '@/components/NamePanel.vue';
import RaceClassAlignmentPanel from '@/components/RaceClassAlignmentPanel.vue';
import ViewPanel from '@/components/ViewPanel.vue';
import BioPanel from '@/components/BioPanel.vue';
import StatusPanel from '@/components/StatusPanel.vue';
import AbilityScoresPanel from '@/components/AbilityScoresPanel.vue';
import CombatPanel from '@/components/CombatPanel.vue';
import DefensesPanel from '@/components/DefensesPanel.vue';
import SpellcastingPanel from '@/components/SpellcastingPanel.vue';
import ClassAndRacePanel from '@/components/ClassAndRacePanel.vue';
import FeatsPanel from '@/components/FeatsPanel.vue';
import TraitsPanel from '@/components/TraitsPanel.vue';
import SkillsPanel from '@/components/SkillsPanel.vue';
import InventoryPanel from '@/components/InventoryPanel.vue';
import EquipmentPanel from '@/components/EquipmentPanel.vue';
import CarryingCapacityPanel from '@/components/CarryingCapacityPanel.vue';
import LanguagesPanel from '@/components/LanguagesPanel.vue';
import NotesPanel from '@/components/NotesPanel.vue';

export default {
  name: 'CharacterBuilder',
  components: {
    NamePanel,
    RaceClassAlignmentPanel,
    PortraitPanel,
    ViewPanel,
    BioPanel,
    StatusPanel,
    AbilityScoresPanel,
    CombatPanel,
    DefensesPanel,
    SpellcastingPanel,
    ClassAndRacePanel,
    FeatsPanel,
    TraitsPanel,
    SkillsPanel,
    CarryingCapacityPanel,
    InventoryPanel,
    EquipmentPanel,
    LanguagesPanel,
    NotesPanel,
  },
  computed: {
    ...mapState([
      'stepEdit',
    ]),
    ...mapGetters('characterSheet', [
      'isSpellcaster',
    ]),
  },
};
</script>

<style lang="scss">
.character-builder {
  max-width: 1920px;
  margin: 0 auto;
  .char-info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media screen and (max-width: 576px) {
      .top-panel-wrapper {
        width: 100%;
      }
    }
  }
  .sheet-wrapper {
    .racial-traits, .class-features {
      text-align: left;
    }
  }
}
</style>
