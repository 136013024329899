<template>
  <Modal
    modalId="importExportCharacterSheetModal"
    modalTitle="Import/Export Character Sheet"
    modalSize="lg"
    :hideFooter="true"
  >
    <ErrorBar
      v-if="successMessage"
      :error="successMessage"
      @closeMe="handleSuccessClose"
    />
    <div class="import-export-modal-content">
      <label class="file-select btn btn-secondary btn-sm mt-5 mb-2">
        <div class="select-button">Import Character Sheet</div>
        <input type="file" ref="importedSheet" @change="importCharacterSheet"/>
      </label>
      <Button class="mt-2 mb-5" @handleButtonClick="exportCharacterSheet">
        Export {{ getExportFilename }}.json
      </Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Modal from '@/components/Modal.vue';
import Button from '@/components/form/Button.vue';
import ErrorBar from '@/components/ErrorBar.vue';

export default {
  name: 'ImportExportCharacterSheet',
  components: {
    Modal,
    Button,
    ErrorBar,
  },
  data() {
    return {
      successMessage: '',
      importErrorMessage: 'The character sheet you tried to import is invalid.',
    };
  },
  computed: {
    ...mapState([
      'characterSheet',
    ]),
    ...mapGetters('characterSheet', [
      'getExportFilename',
    ]),
  },
  methods: {
    exportCharacterSheet() {
      const data = JSON.stringify(this.characterSheet);
      const blob = new Blob([data], { type: 'application/json' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${this.getExportFilename}.json`;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    importCharacterSheet() {
      const file = this.$refs.importedSheet.files[0];
      if (!file || file.type !== 'application/json') {
        this.$store.commit('addError', this.importErrorMessage);
        return;
      }

      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');

      reader.onload = (evt) => {
        const loadedSheet = JSON.parse(evt.target.result);
        this.$store.commit('overwriteCharacterSheet', loadedSheet);
        this.successMessage = `The character sheet for ${loadedSheet.characterName} was imported successfully.`;
      };

      reader.onerror = () => {
        this.$store.commit('addError', this.importErrorMessage);
      };
    },
    handleSuccessClose() {
      this.successMessage = '';
    },
  },
};
</script>

<style lang="scss">
#importExportCharacterSheetModal {
  padding: 0 !important;
  margin: 0;
  .import-export-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .file-select input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }
  }
}

</style>
