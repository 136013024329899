<template>
  <b-form-group
    :class="`anvil-button form-button${centered ? ' text-center' : ''}`"
    :id="id+'group'"
    :label="label"
    :label-for="id"
    :label-sr-only="!showLabel"
    :description="desc"
  >
    <b-button
      :id="id"
      :size="size"
      :variant="color"
      :pill="pill"
      :class="`${btnClass}${square ? ' square' : ''}`"
      :disabled="disabled"
      @click="handleButtonClick"
    >
      <slot></slot>
      <slot name="label" v-if="showLabel && (innerLabel || helpText)">
        {{ innerLabel }}
        <HelpIcon
          v-if="helpText"
          :helpText="helpText"
        />
      </slot>
    </b-button>
  </b-form-group>
</template>

<script>
import HelpIcon from '@/components/form/HelpIcon.vue';

export default {
  name: 'Button',
  components: {
    HelpIcon,
  },
  props: {
    id: {
      type: String,
      default: 'button-',
    },
    size: {
      type: String,
      default: 'sm',
    },
    color: {
      type: String,
      default: '',
    },
    btnClass: {
      type: [String, Array],
      default: '',
    },
    square: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    innerLabel: String,
    desc: String,
    helpText: String,
    pill: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleButtonClick(event) {
      this.$emit('handleButtonClick', event);
    },
  },
};
</script>

<style lang="scss">
div.anvil-button {
  label {
    font-size: $text-small !important;
    text-align: left;
    font-weight: $normal-text;
  }
  button.square {
    @include squareButton();
    @include flexRowCenter();
  }
}
</style>
