<template>
  <div class="spell-manager fade-in">
    <div class="spell-slots">
      <div class="spell-manager-header-wrapper">
        <div class="spell-manager-header-bar">{{magicClass.name}} Spell Slots
          <span v-if="spellCastingData.spellType === 'Arcane' && getArcaneSpellFailureChances.length > 0">
            <b-badge
              v-for="(chance, index) in getArcaneSpellFailureChances" :key="index"
              v-b-popover.hover.focus.blur.top="`Arcane Spell Failure Chance (${chance.name})`"
            >
              {{ chance.value }}%
            </b-badge>
          </span>
        </div>
        <div class="refresh-spells">
          <Button
            v-if="hasClassSpells(magicClass)"
            v-b-popover.hover.focus.blur.top="`Reset ${magicClass.name} Spells`"
            size="sm"
            :id="'reset-'+magicClass.name+'-spells-btn'"
            v-b-modal="'confirmReset'+magicClass.name+'SpellsModal'"
            btnClass="reset-spells-btn"
            square
          >
            <div>
              <FontAwesomeIcon
                :icon="resetSpellsIcon"
              />
            </div>
          </Button>
          <Modal
            :modalTitle="`Reset ${magicClass.name} Spells`"
            modalSize="md"
            :modalId="'confirmReset'+magicClass.name+'SpellsModal'"
            @handleModalSubmit="resetAllSpellSlotsForClass({ charClass: magicClass })"
          >
            {{ `Are you sure you want to reset all ${magicClass.name} spells?` }}
            {{ `This will empty all ${magicClass.name} spell slots.` }}
          </Modal>
          <Button
            v-if="hasClassSpells(magicClass) && !isKnownCaster(magicClass)"
            v-b-popover.hover.focus.blur.top="`Refresh ${magicClass.name} Spells`"
            size="sm"
            :id="'refresh-'+magicClass.name+'-spells-btn'"
            v-b-modal="'confirmRefresh'+magicClass.name+'SpellsModal'"
            btnClass="refresh-spells-btn"
            square
          >
            <div>
              <FontAwesomeIcon
                :icon="refreshSpellsIcon"
              />
            </div>
          </Button>
          <Modal
            :modalTitle="`Refresh ${magicClass.name} Spells`"
            modalSize="md"
            :modalId="'confirmRefresh'+magicClass.name+'SpellsModal'"
            @handleModalSubmit="refreshAllSpellSlotsForClass({ charClass: magicClass })"
          >
            {{ `Are you sure you want to refresh all ${magicClass.name} spells?` }}
            {{ `This will return all expended ${magicClass.name} spells back to 'Prepared' status.` }}
          </Modal>
        </div>
      </div>
      <div
        v-for="(spellsPerDay, spellLevelIndex) in getDailySpellSlots(magicClass)"
        :key="spellLevelIndex"
      >
        <div v-if="spellsPerDay !== null && (spellLevelIndex > 0 || (spellLevelIndex === 0 && spellCastingData.Cantrips))">
          <SectionDivider
            :title="'Level '+spellLevelIndex"
          />
          <SpellButton
            v-for="(spellSlot, slotIndex) in spellsPerDay"
            :key="slotIndex"
            :spellLevel="spellLevelIndex"
            :spellSlot="slotIndex"
            :charClass="magicClass"
          />
        </div>
        <div v-if="!spellsPerDay && spellsPerDay !== null">
          Your {{ spellCastingData.castingAbility }} isn't high enough for bonus spells of this level.
        </div>
      </div>
    </div>
    <div class="spells-known" v-if="isKnownCaster(magicClass)">
      <div class="spell-manager-header-bar">{{magicClass.name}} Spells Known</div>
      <div
        v-for="(knownSpellsOfLevel, spellLevelIndex) in getSpellsKnownPerLevel(magicClass)"
        :key="spellLevelIndex"
      >
        <MultiSelectField
          v-if="knownSpellsOfLevel"
          :id="'known-'+magicClass.name+'-spells-level-'+spellLevelIndex"
          :label="'Level '+spellLevelIndex+' ('+magicClass.knownSpells[spellLevelIndex].length+'/'+knownSpellsOfLevel+')'"
          :options="filteredSpellsList(spellLevelIndex)"
          textField="name"
          valueField="name"
          :value="magicClass.knownSpells[spellLevelIndex]"
          :placeholder="'Select / Enter ('+knownSpellsOfLevel+') Known '+magicClass.name+' Spells of Level '+spellLevelIndex"
          @handleMultiSelectFieldChange="gatherKnownSpellsOfLevel( { charClass: magicClass, spellLevelIndex, spellArray: $event })"
        />
      </div>
    </div>
    <div class="spell-book" v-if="hasSpellbook(magicClass) || hasFamiliar(magicClass)">
      <div class="spell-manager-header-bar">{{ setRecordedSpellsHeader() }}</div>
      <div
        v-for="(spellSlotsOfLevel, spellLevelIndex) in 10"
        :key="spellLevelIndex"
      >
        <MultiSelectField
          v-if="spellSlotsOfLevel"
          :id="'spellbook-'+magicClass.name+'-spells-level-'+spellLevelIndex"
          :label="'Level '+spellLevelIndex+' '"
          :options="filteredSpellsList(spellLevelIndex)"
          textField="name"
          valueField="name"
          :value="magicClass.knownSpells[spellLevelIndex]"
          :placeholder="'Select / Enter Spells of Level '+spellLevelIndex"
          @handleMultiSelectFieldChange="gatherKnownSpellsOfLevel( { charClass: magicClass, spellLevelIndex, spellArray: $event })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import MultiSelectField from '@/components/form/MultiSelectField.vue';
import SectionDivider from '@/components/SectionDivider.vue';
import SpellButton from '@/components/SpellButton.vue';
import Button from '@/components/form/Button.vue';
import Modal from '@/components/Modal.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUndoAlt, faEraser } from '@fortawesome/free-solid-svg-icons';
import {
  isKnownCaster, hasClassSpells, hasSpellbook, hasFamiliar,
} from '../utils/utils';

export default {
  name: 'ClassSpellManager',
  components: {
    MultiSelectField,
    SectionDivider,
    SpellButton,
    Button,
    Modal,
    FontAwesomeIcon,
  },
  props: {
    magicClass: Object,
  },
  data() {
    return {
      refreshSpellsIcon: faUndoAlt,
      resetSpellsIcon: faEraser,
    };
  },
  computed: {
    ...mapGetters('characterSheet', [
      'getDailySpellSlots',
      'getSpellsKnownPerLevel',
      'getArcaneSpellFailureChances',
    ]),
    spellCastingData() {
      return this.magicClass.spellcasting;
    },
  },
  methods: {
    isKnownCaster,
    hasClassSpells,
    hasSpellbook,
    hasFamiliar,
    ...mapMutations('characterSheet', [
      'refreshAllSpellSlotsForClass',
      'resetAllSpellSlotsForClass',
    ]),
    ...mapActions('characterSheet', [
      'gatherKnownSpellsOfLevel',
    ]),
    filteredSpellsList(spLvl) {
      return this.magicClass.classSpellsList[spLvl].filter((spell) => {
        const selectedSpellNames = this.magicClass.knownSpells[spLvl].map((selSpl) => selSpl.name);
        return !(selectedSpellNames.indexOf(spell.name) > -1);
      });
    },
    setRecordedSpellsHeader() {
      const hasBook = hasSpellbook(this.magicClass) && 'Spellbook';
      const hasFam = hasFamiliar(this.magicClass) && 'Familiar';
      return `${this.magicClass.name} ${hasBook || hasFam}`;
    },
  },
};
</script>

<style scoped lang="scss">
.spell-manager {
  .spell-slots {
    .spell-manager-header-wrapper {
      position: relative;
      .refresh-spells {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .form-group.form-button.anvil-button {
          margin-left: 0.5rem;
        }
      }
    }
    .arcane-spell-failure {
      font-size: $text-smaller;
    }
  }
}
</style>
