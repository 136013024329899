<template>
  <div class="dropdown-button">
    <label
      :for="'multi-select-btn-group-'+id"
      :class="`d-block ${showLabel ? '' : 'sr-only'}`"
    >
    {{ label }}
      <HelpIcon
        v-if="helpText"
        :helpText="helpText"
      />
    </label>
    <b-dropdown
      :id="`dropdown-button-${id}`"
      size="sm"
      :class="`${dropdownWrapperClass} dropdown-button-wrapper`"
      no-caret
      v-b-popover.hover.focus.blur.top="dropdownPopoverContent"
    >
      <template #button-content>
        <FontAwesomeIcon v-if="buttonIcon" :icon="buttonIcon"/>
        {{ buttonText }}
      </template>
      <b-form-checkbox-group
        v-model="selectedValues"
        @change="handleDropdownButtonChange"
        @input="handleDropdownButtonInput"
        :class="'dropdown-btn-group dropdown-btn-group-'+id"
      >
        <b-form-checkbox
          v-for="(option, index) in dropdownOptions"
          :key="`dropdown-checkbox-${id}-${index}`"
          :id="`dropdown-checkbox-${id}-${index}`"
          :class="`dropdown-checkbox ${dropdownClass}`"
          :value="option[textField] || option"
        >
          {{ option[textField]|| option }}
        </b-form-checkbox>
      </b-form-checkbox-group>
    </b-dropdown>
  </div>
</template>

<script>
import HelpIcon from '@/components/form/HelpIcon.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'DropdownButton',
  components: {
    HelpIcon,
    FontAwesomeIcon,
  },
  computed: {
    selectedValues: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    label: String,
    showLabel: {
      type: Boolean,
      default: false,
    },
    value: [Array, Object],
    helpText: String,
    buttonText: {
      type: String,
      default: '',
    },
    buttonIcon: {
      type: Object,
      default: () => {},
    },
    dropdownOptions: {
      type: [Array, Object],
    },
    dropdownPopoverContent: {
      type: String,
      default: '',
    },
    textField: {
      type: String,
      default: 'text',
    },
    valueField: {
      type: String,
      default: 'value',
    },
    dropdownWrapperClass: {
      type: String,
      default: '',
    },
    dropdownClass: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleDropdownButtonChange(event) {
      this.$emit('handleDropdownButtonChange', event);
    },
    handleDropdownButtonInput(event) {
      this.$emit('handleDropdownButtonInput', event);
    },
  },
};
</script>

<style lang="scss">
.dropdown-button {
  .dropdown-button-wrapper {
    button {
      @include squareButton();
    }
    .dropdown-menu {
      padding: 0.5rem;
      font-size: $text-small;
      filter: drop-shadow(0px 1px 2px $navbar-shadow);
      .dropdown-checkbox {
        display: flex;
        label {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
