import { render, staticRenderFns } from "./BaBOverrideFields.vue?vue&type=template&id=056d2556&scoped=true"
import script from "./BaBOverrideFields.vue?vue&type=script&lang=js"
export * from "./BaBOverrideFields.vue?vue&type=script&lang=js"
import style0 from "./BaBOverrideFields.vue?vue&type=style&index=0&id=056d2556&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "056d2556",
  null
  
)

export default component.exports