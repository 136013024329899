<template>
  <div class="heal-harm-fields fade-in">
    <b-form-row class="mb-3">
      <b-col class="col-12">
        <SpinButton
          id="heal-harm-amount"
          label="Heal/Damage"
          placeholder="- Enter Healing or Damage (+/-) -"
          inputClass="heal-harm-amount"
          min=-10000
          v-model="healHarmAmount"
        />
      </b-col>
    </b-form-row>
    <div class="total-heal-harm-text">{{`You'll ${getHpChange}`}}</div>
    <div class="total-heal-harm-amount">{{ (healHarmAmount === 0 || healHarmAmount === '0' || healHarmAmount === null) ? hpCurrent : Math.abs(healHarmAmount) }} HP</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import SpinButton from '@/components/form/SpinButton.vue';

export default {
  name: 'HealHarmFields',
  components: {
    SpinButton,
  },
  computed: {
    getHpChange() {
      if (this.healHarmAmount > 0) {
        return 'recover';
      }
      if (this.healHarmAmount < 0) {
        return 'lose';
      }
      return 'remain at';
    },
    ...mapState('characterSheet', [
      'hpCurrent',
    ]),
    ...mapFields('characterSheet', {
      healHarmAmount: 'healHarm.amount',
    }),
  },
};
</script>

<style lang="scss">
.heal-harm-fields {
  div#heal-harm-amount {
    margin-bottom: 0;
  }
  .total-heal-harm-text {
    font-size: $text-standard;
    text-align: center;
  }
  .total-heal-harm-amount {
    font-size: $text-header;
    text-align: center;
  }
}
</style>
