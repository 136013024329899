<template>
  <div class="hit-points">
    <HealHarm />
    <div class="hit-points-box">
      <InputField
        id="currentHitPoints"
        type="number"
        label="Current HP"
        size="lg"
        :max="getTotalHitPoints"
        inputClass="current-hit-points"
        v-model="hpCurrent"
      />
      <div class="total-hit-points">
        <label class="total-hp-label d-block">Total HP</label>
        <div class="total-hp-number" v-b-modal.hitPointsModal>/ {{ getTotalHitPoints }}</div>
      </div>
      <InputField
        id="tempHitPoints"
        type="number"
        label="Temp HP"
        size="lg"
        inputClass="temp-hit-points"
        v-model="hpTemp"
      />
    </div>
    <FullRest />
    <Modal
      modalTitle="Edit Hit Point Totals"
      modalSize="xl"
      modalId="hitPointsModal"
      okOnly
    >
      <HitPointFields />
    </Modal>
  </div>
</template>

<script>
import HitPointFields from '@/components/HitPointFields.vue';
import InputField from '@/components/form/InputField.vue';
import Modal from '@/components/Modal.vue';
import FullRest from '@/components/FullRest.vue';
import HealHarm from '@/components/HealHarm.vue';
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'HitPoints',
  components: {
    HitPointFields,
    InputField,
    Modal,
    FullRest,
    HealHarm,
  },
  computed: {
    ...mapFields('characterSheet', [
      'hpCurrent',
      'hpTemp',
    ]),
    ...mapGetters('characterSheet', [
      'getTotalHitPoints',
    ]),
  },
};
</script>

<style lang="scss">
.hit-points {
  .hit-points-box {
    background: $userTheme;
    text-align: center;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    div.input-field {
      display: inline-block;
      margin-bottom: 0;
      label {
        margin-bottom: 0;
      }
      input.form-control {
        font-size: $text-xlarge;
        width: 85px;
        height: 40px;
        border: 0 !important;
        padding: 0;
        margin: 0;
        text-align: center;
      }
    }
    .total-hit-points {
      position: relative;
      display: inline-block;
      .total-hp-label {
        font-size: $text-small;
        margin-bottom: 0;
        font-weight: $normal-text;
      }
      .total-hp-number {
        font-size: $text-xlarge;
        font-weight: $normal-text;
        line-height: 1;
        padding-top: 3px;
        height: 40px;
        @include hoverFocusStyle();
      }
    }
  }
  .full-rest {
    position: absolute;
    top: 0;
    right: 5px;
  }
  .heal-harm {
    position: absolute;
    top: 0;
    left: 5px;
  }
}
</style>
