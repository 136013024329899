<template>
  <div class="spell-button m-1">
    <Button
      v-b-popover.hover.focus.blur.top="spellSlotOrSpellName"
      size="sm"
      :id="charClass.name+'-spell-level-'+spellLevel+'-slot-'+spellSlot+'-btn'"
      v-b-modal="'castSpellModal-'+charClass.name+'-Level'+spellLevel+'-Slot'+spellSlot"
      btnClass="spell-btn"
      square
    >
      <div>
        <FontAwesomeIcon
          :icon="preparedSpellIcon"
          v-if="chosenSpellIsPrepared"
        />
        <FontAwesomeIcon
          :icon="emptySpellIcon"
          v-if="!chosenSpellIsNamed && !chosenSpellIsUsed"
        />
        <FontAwesomeIcon
          :icon="usedSpellIcon"
          v-if="chosenSpellIsUsed"
          color="gray"
        />
      </div>
    </Button>
    <Modal
      :modalTitle="spellDisplayTitle"
      modalSize="lg"
      :modalId="'castSpellModal-'+charClass.name+'-Level'+spellLevel+'-Slot'+spellSlot"
      :modalIcon="emptySpellIcon"
      :okOnly="isKnownCaster(charClass) || chosenSpellIsUsed || isPreparedCantrip"
      :modalOkTitle="getModalOkButtonTitle"
      modalCancelTitle="Prepare"
      @handleModalSubmit="castSpellModalSubmit"
    >
      <SpellFields
        :charClass="charClass"
        :spellLevel="spellLevel"
        :spellSlot="spellSlot"
      />
    </Modal>
  </div>
</template>

<script>
import Button from '@/components/form/Button.vue';
import SpellFields from '@/components/SpellFields.vue';
import Modal from '@/components/Modal.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faWandMagic, faScrollOld, faEmptySet } from '@fortawesome/pro-solid-svg-icons';
import {
  chosenSpellName, chosenSpellUsed, casterType, isKnownCaster, lookupPreparedSpell,
} from '../utils/utils';

export default {
  name: 'SpellButton',
  components: {
    Button,
    SpellFields,
    Modal,
    FontAwesomeIcon,
  },
  data() {
    return {
      emptySpellIcon: faWandMagic,
      preparedSpellIcon: faScrollOld,
      usedSpellIcon: faEmptySet,
    };
  },
  props: {
    spellLevel: {
      type: [Number, String],
      default: null,
    },
    spellSlot: {
      type: [Number, String],
      default: null,
    },
    charClass: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getModalOkButtonTitle() {
      if (this.isPreparedCantrip) {
        return 'Prepare';
      }
      if (this.chosenSpellIsUsed) {
        return 'Refresh';
      }
      return 'Cast';
    },
    isPreparedCantrip() {
      return this.casterType(this.charClass) === 'Prepared' && this.spellLevel === 0;
    },
    spellSlotOrSpellName() {
      return `${this.chosenSpellIsNamed}${this.spellStatusString}` || this.spellDisplayTitle;
    },
    spellDisplayTitle() {
      return `Level ${this.spellLevel} ${this.charClass.name} Spell [Slot ${+this.spellSlot + 1}]`;
    },
    chosenSpellIsNamed() {
      return chosenSpellName(this.charClass, this.spellLevel, this.spellSlot);
    },
    chosenSpellIsUsed() {
      return chosenSpellUsed(this.charClass, this.spellLevel, this.spellSlot);
    },
    chosenSpellIsPrepared() {
      return this.chosenSpellIsNamed && !this.chosenSpellIsUsed;
    },
    spellStatusString() {
      if (!this.chosenSpellIsUsed) {
        if (this.chosenSpellIsNamed) {
          return ' (Prepared)';
        }
        return '';
      }
      return ' (Expended)';
    },
  },
  methods: {
    casterType,
    isKnownCaster,
    castSpellModalSubmit() {
      const spellSlotData = (this.isKnownCaster(this.charClass) && this.chosenSpellIsUsed) ? {} : lookupPreparedSpell(this.charClass, this.spellLevel, this.spellSlot);
      this.$store.commit('characterSheet/updatePreparedSpellSlot', {
        charClass: this.charClass,
        spellLevel: this.spellLevel,
        spellSlot: this.spellSlot,
        spellData: {
          ...spellSlotData,
          hasBeenCast: !this.isPreparedCantrip && !this.chosenSpellIsUsed,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.spell-button {
  display: inline-block;
  .anvil-button {
    margin-bottom: 0;
  }
  .spell-btn {
    @include flexRowCenter();
  }
}
</style>
