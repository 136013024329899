<template>
  <Fragment>
    <div class="single-die-result">
      <FontAwesomeIcon class="die-result-icon mr-3" :icon="getDieIcon(die)" />
      <span class="die-result-text">
        {{ result }}
        <div class="die-type">[{{ die }}]</div>
      </span>
    </div>
    <span v-if="!isLast"><FontAwesomeIcon :icon="plusIcon" /></span>
  </Fragment>
</template>

<script>
import { Fragment } from 'vue-fragment';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faDiceD20, faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  faDiceD4, faDiceD6, faDiceD8, faDiceD10, faDiceD12,
} from '@fortawesome/pro-solid-svg-icons';

export default {
  name: 'SingleDieResult',
  components: {
    Fragment,
    FontAwesomeIcon,
  },
  props: {
    die: {
      type: String,
      default: 'd20',
    },
    result: {
      type: [Number, String],
      default: 0,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      d20: faDiceD20,
      d12: faDiceD12,
      d10: faDiceD10,
      d8: faDiceD8,
      d6: faDiceD6,
      d4: faDiceD4,
      plusIcon: faPlus,
    };
  },
  methods: {
    getDieIcon(die) {
      const icons = {
        d4: this.d4,
        d6: this.d6,
        d8: this.d8,
        d10: this.d10,
        d12: this.d12,
        d20: this.d20,
      };
      if (icons[die]) {
        return icons[die];
      }
      return icons.d4;
    },
  },
};
</script>

<style scoped lang="scss">
.single-die-result {
  @include flexRowCenter();
  .die-result-icon {
    font-size: $text-xlarge;
  }
  .die-result-text {
    display: inline-block;
    text-align: center;
    line-height: 1;
    font-size: $text-title;
    .die-type {
      font-size: $text-small;
    }
  }
}
@media screen and (max-width: 576px) {
  .single-die-result {
    font-size: $text-small;
    min-width: 50px;
    .die-result-icon,
    .die-result-text {
      font-size: $text-small;
      .die-type {
        font-size: $text-min;
      }
    }
  }
}
</style>
