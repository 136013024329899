<template>
  <div class="race-class-alignment-fields fade-in">
    <b-form-row>
      <b-col class="col-12">
        <ButtonGroup
          id="alignment"
          v-model="alignment"
          label="Alignment"
          textField="abbreviation"
          valueField="value"
          popoverField="name"
          helpText="A creature's general moral and personal attitudes are represented by its alignment. Alignment is a tool for developing your character's identity - it is not a straitjacket for restricting your character. Each alignment represents a broad range of personality types or personal philosophies, so two characters of the same alignment can still be quite different from each other."
          :options="alignments"
        />
      </b-col>
    </b-form-row>
    <RaceBuilder />
    <ClassBuilder
      :charClass="characterClasses[0]"
    />
    <ClassBuilder
      v-if="previousClassIsChosen(1)"
      :charClass="characterClasses[1]"
    />
    <ClassBuilder
      v-if="previousClassIsChosen(2)"
      :charClass="characterClasses[2]"
    />
    <ClassBuilder
    v-if="previousClassIsChosen(3)"
      :charClass="characterClasses[3]"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers, mapMultiRowFields } from 'vuex-map-fields';
import ButtonGroup from '@/components/form/ButtonGroup.vue';
import RaceBuilder from '@/components/RaceBuilder.vue';
import ClassBuilder from '@/components/ClassBuilder.vue';
import { isActiveClass } from '../utils/utils';

const { mapFields: characterFields } = createHelpers({
  getterType: 'characterSheet/getField',
  mutationType: 'characterSheet/updateField',
});

export default {
  name: 'RaceClassAlignmentFields',
  components: {
    ButtonGroup,
    RaceBuilder,
    ClassBuilder,
  },
  computed: {
    ...mapState([
      'alignments',
    ]),
    ...characterFields([
      'alignment',
    ]),
    ...mapMultiRowFields('characterSheet', [
      'characterClasses',
    ]),
  },
  methods: {
    previousClassIsChosen(classNum) {
      return isActiveClass(this.characterClasses[classNum - 1]);
    },
  },
};
</script>

<style lang="scss">
.race-class-alignment-fields {
  margin: 0 1%;
  .img-restrainer {
    max-height: 60px;
    max-width: 60px;
    overflow: hidden;
    text-align: center;
    margin: 0 auto;
  }
  div.form-group {
    div#btn-group-alignment {
      label.btn {
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        line-height: 1;
        @include flexRowCenter();
      }
    }
  }
}
</style>
