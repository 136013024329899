<template>
  <div class="equipment-block fade-in m-1">
    <div class="equipment-block-box" v-b-modal="'equipmentSlotModal'+equipmentSlot.name" v-b-popover.hover.focus.blur.top="equippedItemName">
      <img v-if="!!equipmentSlot.equippedItemId" :src="equippedIcons[equipmentSlot.name]" class="equipment-img">
      <img v-if="!equipmentSlot.equippedItemId" :src="emptyIcons[equipmentSlot.name]" class="equipment-img">
    </div>
    <div class="equipment-block-text">{{ equipmentSlot.name }}</div>
    <Modal
      :modalTitle="'Equip '+equipmentSlot.name"
      :modalId="'equipmentSlotModal'+equipmentSlot.name"
      okOnly
      modalOkTitle="Done"
    >
      <EquipItemFields :equipmentSlot="equipmentSlot" />
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal.vue';
import EquipItemFields from '@/components/EquipItemFields.vue';

const equippedIcons = {
  Head: require('../assets/images/equipment/equipped/head.png'),
  Headband: require('../assets/images/equipment/equipped/headband.png'),
  Eyes: require('../assets/images/equipment/equipped/eyes.png'),
  Shoulders: require('../assets/images/equipment/equipped/shoulders.png'),
  Neck: require('../assets/images/equipment/equipped/neck.png'),
  Chest: require('../assets/images/equipment/equipped/chest.png'),
  Body: require('../assets/images/equipment/equipped/body.png'),
  Armor: require('../assets/images/equipment/equipped/armor.png'),
  Belt: require('../assets/images/equipment/equipped/belt.png'),
  Wrists: require('../assets/images/equipment/equipped/wrists.png'),
  'Left Hand': require('../assets/images/equipment/equipped/left_hand.png'),
  'Right Hand': require('../assets/images/equipment/equipped/right_hand.png'),
  'Left Ring': require('../assets/images/equipment/equipped/left_ring.png'),
  'Right Ring': require('../assets/images/equipment/equipped/right_ring.png'),
  Feet: require('../assets/images/equipment/equipped/feet.png'),
};

const emptyIcons = {
  Head: require('../assets/images/equipment/empty/head.png'),
  Headband: require('../assets/images/equipment/empty/headband.png'),
  Eyes: require('../assets/images/equipment/empty/eyes.png'),
  Shoulders: require('../assets/images/equipment/empty/shoulders.png'),
  Neck: require('../assets/images/equipment/empty/neck.png'),
  Chest: require('../assets/images/equipment/empty/chest.png'),
  Body: require('../assets/images/equipment/empty/body.png'),
  Armor: require('../assets/images/equipment/empty/armor.png'),
  Belt: require('../assets/images/equipment/empty/belt.png'),
  Wrists: require('../assets/images/equipment/empty/wrists.png'),
  'Left Hand': require('../assets/images/equipment/empty/left_hand.png'),
  'Right Hand': require('../assets/images/equipment/empty/right_hand.png'),
  'Left Ring': require('../assets/images/equipment/empty/left_ring.png'),
  'Right Ring': require('../assets/images/equipment/empty/right_ring.png'),
  Feet: require('../assets/images/equipment/empty/feet.png'),
};

export default {
  name: 'EquipmentBlock',
  components: {
    Modal,
    EquipItemFields,
  },
  data() {
    return {
      equippedIcons,
      emptyIcons,
    };
  },
  props: {
    equipmentSlot: Object,
  },
  computed: {
    ...mapState('characterSheet', [
      'inventory',
    ]),
    equippedItemName() {
      return this.equipmentSlot.equippedItemId ? this.inventory[this.equipmentSlot.equippedItemId].name : '';
    },
  },
};
</script>

<style scoped lang="scss">
.equipment-block {
  border-radius: 5px;
  @include flexColumnCenter();
  .equipment-block-box {
    @include hoverFocusStyle();
    @include flexRowCenter();
    width: 60px;
    height: 60px;
    border-radius: 5px;
    .equipment-img {
      width: 50px;
      height: 50px;
    }
  }
  .equipment-block-text {
    font-size: $text-smallest;
  }
}

</style>
