<template>
  <div class="item">
    <FontAwesomeIcon v-if="icon" class="itemIcon" :icon="icon"/><span class="itemName">{{itemName}}</span> <span class="itemValue">{{itemValue}}</span>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'PanelElement',
  components: {
    FontAwesomeIcon,
  },
  props: {
    icon: String,
    itemName: String,
    itemValue: [String, Number],
  },
};
</script>

<style scoped lang="scss">
.item {
  text-align: left;
  font-size: $text-smaller;
  .itemName {
    font-weight: $bold-text;
  }
  .itemValue {
    font-weight: $thin-text;
  }
}

</style>
