<template>
  <div class="equip-item-fields fade-in">
    <b-form-row>
      <b-col class="col-12">
        <Button
          v-for="(invItem, index) in filteredInventoryList()"
          :key="index"
          size="sm"
          :btnClass="`w-100 equip-item-btn-${invItem.id} ${setButtonClass(invItem.id)}`"
          :disabled="disableEquipButton(invItem)"
          :label="'Equip '+invItem.name"
          :showLabel="false"
          @handleButtonClick="handleItemEquip(invItem)"
        >
          {{ invItem.name }} {{ itemIsEquipped(invItem, equipment) ? '[Equipped - '+itemIsEquipped(invItem, equipment)+']' : '' }}
        </Button>
        <div class="text-center" v-if="filteredInventoryList().length === 0">None of your inventory items can be equipped to the '{{ this.equipmentSlot.name }}' slot.</div>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Button from '@/components/form/Button.vue';
import { itemIsEquipped, bothHandsAreFree, itemIsTwoHanded } from '../utils/utils';

export default {
  name: 'EquipItemFields',
  components: {
    Button,
  },
  props: {
    equipmentSlot: Object,
  },
  computed: {
    ...mapState('characterSheet', [
      'inventory',
      'equipment',
    ]),
    slotName: {
      get() {
        const slotName = this.equipmentSlot.name.toLowerCase();
        if (slotName === 'left ring' || slotName === 'right ring') {
          return ['ring'];
        }
        if (slotName === 'left hand' || slotName === 'right hand') {
          return ['one-handed', 'two-handed'];
        }
        return [slotName];
      },
    },
    equippedItemId: {
      get() {
        return this.equipmentSlot.equippedItemId;
      },
    },
  },
  methods: {
    itemIsEquipped,
    ...mapMutations('characterSheet', [
      'equipItem',
    ]),
    setButtonClass(id) {
      return this.equippedItemId === id ? 'active' : '';
    },
    disableEquipButton(item) {
      return !!((this.equippedItemId && this.equippedItemId !== item.id) || (itemIsEquipped(item, this.equipment) && this.equippedItemId !== item.id) || (!itemIsEquipped(item, this.equipment) && itemIsTwoHanded(item) && !bothHandsAreFree(this.equipment)));
    },
    filteredInventoryList() {
      return Object.values(this.inventory).filter((item) => {
        const itemSlot = item.slot.toLowerCase();
        for (let i = 0; i < this.slotName.length; i += 1) {
          if (itemSlot === this.slotName[i]) {
            return true;
          }
        }
        return false;
      });
    },
    handleItemEquip(item) {
      this.$store.commit('characterSheet/toggleItemEquip', { item, slot: this.equipmentSlot.name });
    },
  },
};
</script>

<style scoped lang="scss">
.equip-item-fields {
  margin: 0 1%;
}
</style>
