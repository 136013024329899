<template>
  <div class="class-and-race-panel fade-in">
    <div class="class-and-race-content">
      <div v-if="classAndRaceAreEmpty()">
        You haven't chosen a race or class yet. Click 'Select Your Race and Class(es)' at the top right to get started.
      </div>
      <b-form-row>
        <b-col class="col-12 col-lg-6 class-features">
          <ClassFeaturesPanel
            v-for="(charClass, index) in getActiveClasses"
            :key="index"
            :charClass="charClass"
          />
        </b-col>
        <b-col class="col-12 col-lg-6 racial-traits">
          <RacialTraitsPanel />
        </b-col>
      </b-form-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClassFeaturesPanel from '@/components/ClassFeaturesPanel.vue';
import RacialTraitsPanel from '@/components/RacialTraitsPanel.vue';

export default {
  name: 'ClassAndRacePanel',
  components: {
    ClassFeaturesPanel,
    RacialTraitsPanel,
  },
  computed: {
    ...mapGetters('characterSheet', [
      'getActiveClasses',
      'getRace',
    ]),
  },
  methods: {
    classAndRaceAreEmpty() {
      return this.getActiveClasses.length === 0 && this.getRace === '';
    },
  },
};
</script>
