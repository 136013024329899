<template>
  <div
    class="modifier-panel"
    v-if="parentElement"
  >
    <ModifierFields
      v-for="(mod, index) in parentElement.modifierIds"
      :key="index"
      :mod="modifiers[mod]"
      :parentElement="parentElement"
      :parentList="parentList"
      :labelType="labelType"
    />
    <Button
      label="Add a Modifier"
      :showLabel="false"
      @handleButtonClick="setModifier({ list: parentList, id: parentElement.id, payload: { name: parentElement.selection || parentElement.name || '' } })"
    >
      {{ addModifierButtonLabel }}
    </Button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ModifierFields from '@/components/ModifierFields.vue';
import Button from '@/components/form/Button.vue';

export default {
  name: 'ModifierPanel',
  components: {
    ModifierFields,
    Button,
  },
  props: {
    parentElement: {
      type: Object,
      default: () => {},
    },
    parentList: {
      type: String,
      default: '',
    },
    addModifierButtonLabel: {
      type: String,
      default: '+ Add Modifier',
    },
    labelType: String,
  },
  computed: {
    ...mapState('characterSheet', [
      'modifiers',
    ]),
  },
  methods: {
    ...mapActions('characterSheet', [
      'setModifier',
    ]),
  },
};
</script>
