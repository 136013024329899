<template>
  <div class="combat-panel fade-in">
    <div class="combat">
      <b-form-row>
        <b-col class="col-12 d-flex">
          <StatBox
            shortTitle="BaB"
            title="Base Attack Bonus"
            :stat="plusMinus(getBaseAttackBonus)"
            :statStringArray="getBaseAttackBonusStringArray"
            :modalIcon="babIcon"
          >
            <BaBOverrideFields />
          </StatBox>
          <StatBox
            shortTitle="CMB"
            title="Combat Maneuver Bonus"
            :stat="plusMinus(getCombatManeuverBonus)"
            :statStringArray="getCombatManeuverBonusStringArray"
            :modalIcon="cmbIcon"
            showCheck
          />
          <StatBox
            shortTitle="CMD"
            title="Combat Maneuver Defense"
            :stat="getCombatManeuverDefense"
            :statStringArray="getCombatManeuverDefenseStringArray"
            :modalIcon="cmdIcon"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col class="col-12">
          <div class="attacks-subpanel">
            <Attack
              v-for="(atk, index) in attacks"
              :key="'attack'+index"
              :attack="atk"
            />
            <Button
              alt="Add Attack"
              size="sm"
              btnClass="add-attack-btn"
              @handleButtonClick="handleAddAttackButtonClick"
            >
              + Add Attack
            </Button>
          </div>
        </b-col>
      </b-form-row>
    </div>
  </div>
</template>

<script>
import StatBox from '@/components/StatBox.vue';
import Attack from '@/components/Attack.vue';
import Button from '@/components/form/Button.vue';
import BaBOverrideFields from '@/components/BaBOverrideFields.vue';
import { mapGetters } from 'vuex';
import { mapMultiRowFields } from 'vuex-map-fields';
import { faAxeBattle } from '@fortawesome/pro-solid-svg-icons';
import { faExpand, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { plusMinus } from '../utils/utils';

export default {
  name: 'CombatPanel',
  components: {
    StatBox,
    Attack,
    Button,
    BaBOverrideFields,
  },
  data() {
    return {
      babIcon: faAxeBattle,
      cmdIcon: faExpand,
      cmbIcon: faExpandArrowsAlt,
    };
  },
  computed: {
    ...mapMultiRowFields('characterSheet', [
      'attacks',
    ]),
    ...mapGetters('characterSheet', [
      'getBaseAttackBonus',
      'getBaseAttackBonusStringArray',
      'getCombatManeuverBonus',
      'getCombatManeuverBonusStringArray',
      'getCombatManeuverDefense',
      'getCombatManeuverDefenseStringArray',
    ]),
  },
  methods: {
    plusMinus,
    handleAddAttackButtonClick() {
      this.$store.commit('characterSheet/addAttack');
      const addedAttackId = this.attacks[this.attacks.length - 1].id;
      this.$nextTick(() => {
        this.$bvModal.show(`editAttackModal${addedAttackId}`);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.combat-panel {
  text-align: right;
  position: relative;
  margin: 10px 0 0;
  .combat {
    position: relative;
    font-size: $text-smaller;
    font-weight: 600;
    .attacks-subpanel {
      text-align: center;
      div.anvil-button {
        margin-bottom: 0;
        margin-top: 1rem;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .combat-panel {
    margin: 1rem 0;
  }
}
</style>
