<template>
  <div class="check-results">
    <SingleDieResult
      die="d20"
      :result="d20roll"
    />
    <ModifierDisplay
      :modNumber="modifier"
      :modText="checkName"
      :showPlus="getModCheck.length > 0"
    />
    <ModifierDisplay
      v-for="(mod, index) in getModCheck"
      :key="index"
      :modNumber="mod.value"
      :modText="mod.name"
      :showPlus="index < (getModCheck.length -1)"
    />
    <FontAwesomeIcon :icon="equalsIcon" />
    <div class="total-result">{{ checkResult }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SingleDieResult from '@/components/SingleDieResult.vue';
import ModifierDisplay from '@/components/ModifierDisplay.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEquals } from '@fortawesome/free-solid-svg-icons';
import { rollADie } from '../utils/diceUtils';
import { plusMinus } from '../utils/utils';

export default {
  name: 'CheckResults',
  created() {
    this.makeACheck();
  },
  components: {
    SingleDieResult,
    ModifierDisplay,
    FontAwesomeIcon,
  },
  props: {
    checkName: {
      type: String,
      default: '',
    },
    modifier: {
      type: [Number, String],
      default: 0,
    },
    checkForModifiers: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      d20roll: 0,
      equalsIcon: faEquals,
    };
  },
  computed: {
    getModCheck() {
      return this.buildModArray(this.checkForModifiers);
    },
    checkResult() {
      return +this.d20roll + +this.modifier + this.getModCheck.reduce((a, b) => +a + +b.value, 0);
    },
    ...mapGetters('characterSheet', [
      'buildModArray',
    ]),
  },
  methods: {
    rollADie,
    plusMinus,
    makeACheck() {
      this.d20roll = rollADie(20);
      this.$store.commit('characterSheet/logDiceCheck', {
        name: this.checkName,
        modifier: +this.modifier + this.getModCheck.reduce((a, b) => +a + +b.value, 0),
        roll: this.d20roll,
        result: this.checkResult,
        datetime: new Date(),
      });
    },
  },
};
</script>

<style lang="scss">
.check-results {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .total-result {
    font-size: $text-header;
  }
}
@media screen and (max-width: 576px) {
  .check-results {
    .total-result {
      font-size: $text-title;
    }
  }
}
</style>
