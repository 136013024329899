<template>
  <div>
    <div>{{ armorStats.name }} ({{ capIt(armorStats.proficiency) }}) - {{ armorStats.cost }}{{ denomAbbrev(armorStats.denomination) }}</div>
    <div>AC {{ armorStats["Armor/Shield Bonus"] }}</div>
    <div>Max Dex {{ armorStats["Maximum Dex Bonus"] }}</div>
    <div>Check Penalty {{ armorStats["Armor Check Penalty"] }}</div>
    <div>Spell Failure {{ armorStats["Arcane Spell Failure Chance"] }}</div>
    <div>Weight {{ armorStats.weight }} lb.</div>
  </div>
</template>

<script>
import { denomAbbrev, capIt } from '../utils/utils';

export default {
  name: 'ItemStatsDisplayArmor',
  props: {
    armorStats: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    denomAbbrev,
    capIt,
  },
};
</script>
