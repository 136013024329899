<template>
  <b-form-group
    class="input-field"
    :id="id+'group'"
    :label="label"
    :label-for="id"
    :label-sr-only="!showLabel"
    :description="desc"
  >
    <b-form-input
      :min="min"
      :max="max"
      :id="id"
      :type="type"
      :placeholder="placeholder || label"
      :size="size"
      :class="inputClass"
      :required="required"
      :disabled="disabled"
      v-model="inputValue"
      @change="handleInputFieldChange"
      @input="handleInputFieldInput"
    ></b-form-input>
  </b-form-group>
</template>

<script>
export default {
  name: 'InputField',
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  props: {
    id: String,
    value: [String, Number],
    type: {
      type: String,
      default: 'text',
    },
    size: {
      type: String,
      default: 'sm',
    },
    inputClass: {
      type: String,
      default: '',
    },
    label: String,
    showLabel: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    desc: {
      type: String,
      default: '',
    },
    min: [String, Number],
    max: [String, Number],
  },
  methods: {
    handleInputFieldChange(typedValue) {
      this.$emit('handleInputFieldChange', typedValue);
    },
    handleInputFieldInput(typedValue) {
      this.$emit('handleInputFieldInput', typedValue);
    },
  },
};
</script>
