<template>
    <b-spinner
      class="multi-select-loading-spinner"
      small
      label="Loading Data..."
    ></b-spinner>
</template>

<script>
export default {
  name: 'DeselectSpinner',
};
</script>
