<template>
  <div class="range-slider">
    <label>{{label}}</label>
    <input
      type="range"
      :min="+min"
      :max="+max"
      v-model="inputValue"
      @change="handleRangeSliderChange"
      @input="handleRangeSliderInput"
    />
  </div>
</template>

<script>
export default {
  name: 'RangeSlider',
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  props: {
    id: String,
    value: [Number, String],
    label: {
      type: String,
      default: '',
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    handleRangeSliderChange(value) {
      this.$emit('handleRangeSliderChange', value);
    },
    handleRangeSliderInput(value) {
      this.$emit('handleRangeSliderInput', value);
    },
  },
};
</script>
