<template>
  <div class="full-rest-fields fade-in">
    <b-form-row class="mb-3">
      <b-col class="col-12">
        <SpinButton
          id="full-rest-hours-"
          label="Hours to Rest"
          min=0
          placeholder="Enter Hours"
          spinButtonClass="full-rest-hours"
          v-model="hoursOfRest"
        />
      </b-col>
    </b-form-row>
    <b-form-row class="mb-3">
      <b-col class="d-flex justify-content-flex-start align-items-end">
        <CheckboxField
          id="checkbox-use-con"
          checkboxClass="mr-1"
          v-model="useCon"
        >
          Use Con Bonus
        </CheckboxField>
        <CheckboxField
          id="checkbox-long-term-care"
          checkboxClass="mr-1"
          v-model="longTermCare"
        >
          Long-term Care
        </CheckboxField>
        <span
          v-for="(charClass, index) in getActiveClasses"
          :key="index"
        >
          <CheckboxField
            v-if="hasClassSpells(charClass)"
            :id="'checkbox-refresh-'+charClass.name+'-spells'"
            checkboxClass="mr-1"
            :value="refreshClassSpells[index]"
            @handleCheckboxFieldChange="setRefreshClassSpells({ index, value: $event })"
          >
            {{ `Refresh ${charClass.name} Spells` }}
          </CheckboxField>
        </span>
      </b-col>
    </b-form-row>
    <div class="rest-healing-text">You'll recover</div>
    <div class="rest-hp-recovered-text">{{ getHitPointsRecoveredFromRest || 0 }} HP</div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import SpinButton from '@/components/form/SpinButton.vue';
import CheckboxField from '@/components/form/CheckboxField.vue';
import { hasClassSpells } from '../utils/utils';

const { mapFields } = createHelpers({
  getterType: 'characterSheet/getField',
  mutationType: 'characterSheet/updateField',
});

export default {
  name: 'FullRestFields',
  components: {
    SpinButton,
    CheckboxField,
  },
  computed: {
    ...mapFields([
      'rest.hoursOfRest',
      'rest.useCon',
      'rest.longTermCare',
      'rest.refreshClassSpells',
    ]),
    ...mapGetters('characterSheet', [
      'getActiveClasses',
      'getHitPointsRecoveredFromRest',
    ]),
  },
  methods: {
    hasClassSpells,
    ...mapMutations('characterSheet', [
      'setRefreshClassSpells',
    ]),
  },
};
</script>

<style lang="scss">
.full-rest-fields {
  div#full-rest-hours-group {
    margin-bottom: 0;
  }
  .rest-healing-text {
    font-size: $text-standard;
    text-align: center;
  }
  .rest-hp-recovered-text {
    font-size: $text-header;
    text-align: center;
  }
}
</style>
