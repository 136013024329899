<template>
  <div class="roll-a-check">
    <Button
      v-b-modal="checkName+'-check-roll'"
      :alt="checkName+' '+checkType"
      v-b-popover.hover.click.focus.blur.top="checkName+' '+checkType"
      size="sm"
      btnClass="roll-a-check-btn"
      label=""
    >
      <FontAwesomeIcon :icon="d20" />
    </Button>
    <Modal
      :modalTitle="checkName+' '+checkType"
      :modalSize="modalSize"
      :modalId="checkName+'-check-roll'"
      :modalIcon="d20"
      okOnly
    >
      <CheckResults
        :checkName="checkName"
        :modifier="modifier"
      />
      <Button
        centered
        btnClass="view-dice-check-log-btn"
        @handleButtonClick="toggleDiceCheckLog()"
      >
        {{ viewDiceCheckLog ? 'Hide Dice Check Log' : 'View Dice Check Log' }}
      </Button>
      <div
        v-if="viewDiceCheckLog"
        class="b-table-sticky-header"
      >
        <b-table
          id="dice-check-log-table"
          striped
          small
          responsive
          sticky-header="200px"
          stacked="md"
          :per-page="perPage"
          :current-page="currentPage"
          :items="formattedDiceCheckLog"
          :fields="diceCheckLogTableFields"
          class="dice-check-log-table"
        />
        <Button
          centered
          v-if="diceCheckLog.length > 0"
          btnClass="clear-dice-check-log-btn m-2"
          @handleButtonClick="clearDiceCheckLog()"
        >
          Clear Log
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { createHelpers, mapMultiRowFields } from 'vuex-map-fields';
import Modal from '@/components/Modal.vue';
import Button from '@/components/form/Button.vue';
import CheckResults from '@/components/CheckResults.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faDiceD20 } from '@fortawesome/free-solid-svg-icons';
import { plusMinus } from '../utils/utils';
import formatDateTimeString from '../utils/stringUtils';

const { mapFields: mapUserSettings } = createHelpers({
  getterType: 'getUserSettings',
  mutationType: 'updateUserSettings',
});

export default {
  name: 'RollACheck',
  components: {
    Modal,
    Button,
    CheckResults,
    FontAwesomeIcon,
  },
  data() {
    return {
      d20: faDiceD20,
      d20roll: '',
      currentPage: 1,
      perPage: 100,
    };
  },
  props: {
    checkName: String,
    modifier: {
      type: [Number, String],
      default: 0,
    },
    modalSize: {
      type: String,
      default: 'md',
    },
    checkType: {
      type: String,
      default: 'Check',
    },
  },
  computed: {
    ...mapUserSettings([
      'viewDiceCheckLog',
    ]),
    ...mapMultiRowFields('characterSheet', [
      'diceCheckLog',
    ]),
    diceCheckLogTableFields() {
      return [
        {
          key: 'name',
          label: 'Check Type',
          sortable: true,
        },
        {
          key: 'roll',
          label: 'Roll',
          sortable: true,
          tdClass: 'align-table-text',
        },
        {
          key: 'modifier',
          label: 'Modifier',
          sortable: true,
          formatter: (val) => plusMinus(val),
          tdClass: 'align-table-text',
        },
        {
          key: 'result',
          label: 'Result',
          sortable: true,
          tdClass: ['align-table-text', 'die-roll-result'],
        },
        {
          key: 'datetime',
          label: 'Date & Time',
          sortable: true,
          formatter: (val) => formatDateTimeString(val),
        },
      ];
    },
    formattedDiceCheckLog() {
      return this.diceCheckLog.slice().reverse();
    },
  },
  methods: {
    toggleDiceCheckLog() {
      this.$store.commit('toggleDeepProperty', { property: 'userSettings', element: 'viewDiceCheckLog' });
    },
    clearDiceCheckLog() {
      this.$store.commit('characterSheet/clearDiceCheckLog');
    },
  },
};
</script>

<style lang="scss">
.align-table-text {
  text-align: center;
}
.die-roll-result {
  font-weight: bold;
}
</style>
