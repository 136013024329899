<template>
  <div id="app">
    <Nav v-if="$route.path !== '/print'" />
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue';

export default {
  name: 'App',
  components: {
    Nav,
  },
  watch: {
    $route(to) {
      document.title = to.meta.title;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'cardo';
  font-weight: $normal-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $base-text;
}
</style>
