<template>
  <b-navbar fixed sticky toggleable="md" type="dark">
    <b-navbar-brand to="/"><img src="../assets/images/anvil_white_logo.png" height="28px"></b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/about">
          <FontAwesomeIcon :icon="questionIcon" />
          <div
            class="nav-item-text"
            v-b-popover.hover.focus.blur.bottom="'The premiere Pathfinder Character Generator for the 1st Edition of Paizo\'s Pathfinder Role Playing Game. Responsive and mobile-friendly, ANViL runs in your browser and generates printable character sheets. With additional support via Patreon.com/treasuretrove, you can save multiple characters to your device, access over a dozen visual themes, and unlock additional pre-loaded game content.'"
          >
            About
          </div>
        </b-nav-item>
        <b-nav-item-dropdown right>
          <template #button-content>
            <span class="d-inline-block">
              <FontAwesomeIcon :icon="paletteIcon" />
              <div class="nav-item-text">Themes</div>
            </span>
          </template>
          <div v-if="(isAuthenticated && isSupporter)">
            <b-dropdown-item v-for="(theme, index) in themes" :key="index" :active="getActiveTheme(index)" @click="setTheme">{{ index }}</b-dropdown-item>
          </div>
          <div class="nav-patreon-text" v-if="!isSupporter">
            Want access to over a dozen visual themes, import/export, save-on-device, expanded datasets, and an abundance of the deep pride and satisfaction commensurate with supporting indie development in the tabletop RPG community?<br> <a href="https://www.patreon.com/treasuretrove" target="_blank">Join us on Patreon</a>!
          </div>
        </b-nav-item-dropdown>
        <b-nav-item @click="generatePrintableSheet">
          <FontAwesomeIcon :icon="printIcon" />
          <div class="nav-item-text">Print</div>
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav justified class="ml-auto">
        <b-nav-item-dropdown :text="characterName" right>
          <b-dropdown-item v-if="(isAuthenticated && isSupporter)" @click="handleManageCharactersClick">Manage Characters</b-dropdown-item>
          <b-dropdown-item v-if="!isSupporter" @click="handleManageCharactersClick">Create New Character</b-dropdown-item>
          <b-dropdown-item v-if="(isAuthenticated && isSupporter && isPrestige)" @click="handleImportExportClick">Import/Export Character Sheet</b-dropdown-item>
          <b-dropdown-item v-if="!isAuthenticated" @click="handlePatreonLogin">Patreon Login</b-dropdown-item>
          <b-dropdown-item v-if="!isProduction()" @click="mockPatreonLogin">Mock Patreon Login/Logout</b-dropdown-item>
          <b-dropdown-item v-if="isAuthenticated" @click="handlePatreonLogout">Disconnect Patreon</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item v-if="isAuthenticated">
          <FontAwesomeIcon :icon="patreonIcon" v-b-popover.hover.click.focus.blur.bottom="setPatreonNotification" />
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
    <ImportExportCharacterSheet />
  </b-navbar>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faQuestion, faPalette, faPrint } from '@fortawesome/free-solid-svg-icons';
import { faPatreon } from '@fortawesome/free-brands-svg-icons';
import ImportExportCharacterSheet from '@/components/ImportExportCharacterSheet.vue';
import { setThemeBodyClass } from '../utils/utils';

export default {
  name: 'Nav',
  created() {
    setThemeBodyClass(this.characterTheme.toLowerCase());
  },
  components: {
    FontAwesomeIcon,
    ImportExportCharacterSheet,
  },
  data() {
    return {
      questionIcon: faQuestion,
      paletteIcon: faPalette,
      printIcon: faPrint,
      patreonIcon: faPatreon,
    };
  },
  computed: {
    ...mapState([
      'themes',
      'isAuthenticated',
      'isSupporter',
      'isPrestige',
    ]),
    ...mapFields('characterSheet', [
      'characterName',
      'characterTheme',
    ]),
    setPatreonNotification() {
      const connectMessage = this.isAuthenticated ? 'Patreon Connected' : '';
      const noTierMessage = (!this.isSupporter && !this.isPrestige) ? ' (Non-Member)' : '';
      const coreTierMessage = (this.isSupporter && !this.isPrestige) ? ' (Core Tier)' : '';
      const prestigeTierMessage = (this.isSupporter && this.isPrestige) ? ' (Prestige Tier)' : '';
      return connectMessage + noTierMessage + coreTierMessage + prestigeTierMessage;
    },
  },
  methods: {
    isProduction() {
      return process.env.NODE_ENV === 'production';
    },
    handleManageCharactersClick() {
      this.$nextTick(() => {
        this.$bvModal.show('manageCharactersModal');
      });
    },
    generatePrintableSheet() {
      const routeData = this.$router.resolve({ name: 'Print' });
      window.open(routeData.href, '_blank');
    },
    setTheme(value) {
      setThemeBodyClass(value.target.innerText.toLowerCase());
      this.$store.commit('characterSheet/setCharacterTheme', value.target.innerText.toLowerCase());
    },
    getActiveTheme(value) {
      return this.characterTheme === value.toLowerCase();
    },
    handleImportExportClick() {
      this.$nextTick(() => {
        this.$bvModal.show('importExportCharacterSheetModal');
      });
    },
    handlePatreonLogin() {
      const {
        VUE_APP_PATREON_CLIENTID,
        VUE_APP_REDIRECTURL,
        VUE_APP_PATREON_AUTHURL,
      } = process.env;
      const patreonRoute = `${VUE_APP_PATREON_AUTHURL}?response_type=code&client_id=${VUE_APP_PATREON_CLIENTID}&redirect_uri=${VUE_APP_REDIRECTURL}&scope=identity`;
      window.open(patreonRoute, '_self');
    },
    handlePatreonLogout() {
      this.$store.commit('authenticateUser', false);
    },
    mockPatreonLogin() {
      if (this.isAuthenticated) {
        this.$store.commit('mockPatreonLogin', false);
      } else {
        this.$store.commit('mockPatreonLogin', true);
      }
    },
  },
};
</script>

<style lang="scss">
nav {
  transition: background-color 1s ease;
  background-color: rgba(darken($userTheme, 10%), 0.9);
  filter: drop-shadow(0px 1px 2px $navbar-shadow);
  ul.navbar-nav {
    li.nav-item {
      a.nav-link {
        padding: 0 1rem !important;
        .nav-item-text {
          font-size: $text-smaller;
          line-height: 1;
        }
        span {
          padding-right: 5px;
        }
      }
    }
    .nav-patreon-text {
      font-size: $text-small;
      margin: 0 1rem;
      text-align: center;
    }
  }
  @media screen and (max-width: 768px) {
    ul.navbar-nav {
      li.nav-item {
        padding: 0.5rem 1rem !important;
        ul.dropdown-menu {
          text-align: center;
        }
      }
    }
  }
}
</style>
