import httpClient from './httpClient';

const actionScope = 'loader';

export default function setupInterceptors({ dispatch }) {
  let requestsPending = 0;
  const req = {
    pending: () => {
      requestsPending += 1;
      dispatch(`${actionScope}/show`);
    },
    done: () => {
      requestsPending -= 1;
      if (requestsPending <= 0) {
        dispatch(`${actionScope}/hide`);
      }
    },
  };
  httpClient.interceptors.request.use(
    (config) => {
      req.pending();
      return config;
    },
    (error) => {
      requestsPending -= 1;
      req.done();
      return Promise.reject(error);
    },
  );
  httpClient.interceptors.response.use(
    ({ data }) => {
      req.done();
      return Promise.resolve(data);
    },
    (error) => {
      req.done();
      return Promise.reject(error);
    },
  );
}
