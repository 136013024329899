<template>
  <div class="defenses-panel fade-in">
    <div class="defenses">
      <b-form-row class="mb-1">
        <b-col class="col-12 col-lg-5">
          <ArmorClass />
        </b-col>
        <b-col class="col-12 col-lg-7">
          <HitPoints />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col class="col-12 col-lg-5 init-speed mb-1">
          <StatBox
            shortTitle="Initiative"
            title="Initiative"
            :stat="getInitiative"
            :statStringArray="getInitiativeStringArray"
            :modalIcon="initIcon"
            showCheck
            checkType="Roll"
          />
          <StatBox
            shortTitle="Speed"
            title="Speed"
            :stat="getSpeed"
            :statStringArray="getSpeedStringArray"
            :modalIcon="feetIcon"
          />
        </b-col>
        <b-col class="col-12 col-lg-7 saving-throws mb-1">
          <StatBox
            shortTitle="Fort"
            title="Fortitude Save"
            :stat="getSavingThrow('Fort', 'Constitution')"
            :statStringArray="getSavingThrowStringArray('Fort', 'Constitution')"
            :modalIcon="fortIcon"
            showCheck
            checkType=""
          />
          <StatBox
            shortTitle="Ref"
            title="Reflex Save"
            :stat="getSavingThrow('Ref', 'Dexterity')"
            :statStringArray="getSavingThrowStringArray('Ref', 'Dexterity')"
            :modalIcon="refIcon"
            showCheck
            checkType=""
          />
          <StatBox
            shortTitle="Will"
            title="Will Save"
            :stat="getSavingThrow('Will', 'Wisdom')"
            :statStringArray="getSavingThrowStringArray('Will', 'Wisdom')"
            :modalIcon="willIcon"
            showCheck
            checkType=""
          />
        </b-col>
      </b-form-row>
    </div>
  </div>
</template>

<script>
import StatBox from '@/components/StatBox.vue';
import ArmorClass from '@/components/ArmorClass.vue';
import HitPoints from '@/components/HitPoints.vue';
import { mapGetters } from 'vuex';
import { faShoePrints, faFistRaised, faBrain } from '@fortawesome/free-solid-svg-icons';
import { faTachometerSlow } from '@fortawesome/pro-regular-svg-icons';
import { faRabbitFast } from '@fortawesome/pro-solid-svg-icons';

export default {
  name: 'DefensesPanel',
  components: {
    StatBox,
    ArmorClass,
    HitPoints,
  },
  data() {
    return {
      feetIcon: faShoePrints,
      initIcon: faTachometerSlow,
      fortIcon: faFistRaised,
      refIcon: faRabbitFast,
      willIcon: faBrain,
    };
  },
  computed: {
    ...mapGetters('characterSheet', [
      'getSpeed',
      'getAbilityModifier',
      'getSavingThrow',
      'getSavingThrowStringArray',
      'getInitiative',
      'getInitiativeStringArray',
      'getSpeedStringArray',
    ]),
  },
};
</script>

<style lang="scss">
.defenses-panel {
  text-align: right;
  position: relative;
  margin: 1rem;
  .defenses {
    position: relative;
    font-size: $text-smaller;
    font-weight: 600;
    .saving-throws,
    .init-speed {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
@media screen and (max-width: 576px) {
  .defenses-panel {
    margin: 1rem 0;
  }
}
</style>
