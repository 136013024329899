<template>
  <div class="feat-fields fade-in">
    <div class="feat-fields-content">
      <div class="feat-chooser">
        <SectionDivider
          :title="formatFeatName(feat)"
          dividerClass="my-4"
        />
        <b-form-row>
          <b-col class="col-12 col-md-6 flex-row">
            <b-form-row>
              <b-col class="col-8">
                <VueSelectField
                  :id="'feat-selector-'+feat.id"
                  label="Feat Name"
                  v-model="feat.selection"
                  :options="getFeatOptions(feat.options)"
                  taggable
                  textField="name"
                  valueField="id"
                  placeholder="- Select / Enter Feat Name -"
                  @handleVueSelectFieldChange="setFeat(feat)"
                />
              </b-col>
              <b-col class="col-4">
                <Button
                  v-b-popover.hover.focus.blur.top="'Limit the available dropdown options to only the feat types chosen (default all types). If this feat normally has a preselected list of options (such as with Monk bonus feats), using these filters will override those options.'"
                  alt="Filter By Feat Type"
                  label="Filter By Type"
                  showLabel
                  size="sm"
                  btnClass="filter-by-feat-type-btn"
                  square
                  v-b-modal="'filterByFeatType'+feat.id"
                >
                  <FontAwesomeIcon :icon="filterIcon" />
                </Button>
              </b-col>
            </b-form-row>
          </b-col>
          <b-col class="col-12 col-md-6">
            <MultiSelectField
              :id="'feat-type-'+feat.id"
              label="Feat Type(s)"
              :options="featTypes"
              textField="name"
              valueField="name"
              v-model="feat.selectedTypes"
              placeholder="- Select / Enter Feat Type(s) -"
            />
          </b-col>
          <b-col class="col-12">
            <InputField
              :id="'feat-prereqs-'+feat.id"
              label="Prerequisites"
              placeholder="- Enter Prerequisites -"
              v-model="feat.prerequisites"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col class="col-12">
            <TextAreaField
              :id="'feat-benefit-'+feat.id"
              label="Benefit"
              rows="5"
              v-model="feat.benefit"
            />
          </b-col>
          <b-col>
            <ModifierPanel
              :parentElement="feat"
              parentList="characterFeats"
              :addModifierButtonLabel="`+ Add Modifier to ${feat.selection ? feat.selection : 'Feat'}`"
            />
          </b-col>
        </b-form-row>
        <Modal
          modalTitle="Filter Feats By Type"
          modalSize="xl"
          :modalId="'filterByFeatType'+feat.id"
          :modalIcon="filterIcon"
          okOnly
        >
          <span
            class="checkbox-group"
            v-for="(featType, index) in featTypes"
            :key="index"
          >
            <b-form-checkbox
              class="condition-checkbox m-1"
              v-model="selectedFeatTypeFilters"
              :id="featType"
              :name="'checkbox-'+index+'-'+featType"
              size="sm"
              :value="featType"
              button
              button-variant="outline-theme-main"
            >
              {{ featType }}
            </b-form-checkbox>
          </span>
          <div class="feat-filter-summary" v-if="selectedFeatTypeFilters.length > 0">
            Displaying only <span v-for="(type, index) in selectedFeatTypeFilters" :key="index"><template v-if="index > 0 && index < selectedFeatTypeFilters.length - 1">, </template><template v-if="selectedFeatTypeFilters.length > 1 && index === selectedFeatTypeFilters.length - 1"> and </template>{{type}}</span> feats.
          </div>
          <div class="feat-filter-summary" v-if="selectedFeatTypeFilters.length === 0">
            Displaying all feats.
          </div>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import InputField from '@/components/form/InputField.vue';
import VueSelectField from '@/components/form/VueSelectField.vue';
import MultiSelectField from '@/components/form/MultiSelectField.vue';
import TextAreaField from '@/components/form/TextAreaField.vue';
import Button from '@/components/form/Button.vue';
import SectionDivider from '@/components/SectionDivider.vue';
import ModifierPanel from '@/components/ModifierPanel.vue';
import Modal from '@/components/Modal.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { alphabetize, formatFeatName, lowerString } from '../utils/utils';

export default {
  name: 'FeatFields',
  created() {
    if (!this.feats.length) {
      this.$store.dispatch('fetchList', { urlPath: 'feats?all=true', targetList: 'feats' });
    }
  },
  components: {
    InputField,
    VueSelectField,
    MultiSelectField,
    TextAreaField,
    Button,
    SectionDivider,
    ModifierPanel,
    Modal,
    FontAwesomeIcon,
  },
  props: {
    feat: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filterIcon: faFilter,
      selectableFeats: [],
      selectedFeatTypeFilters: [],
    };
  },
  computed: {
    ...mapState([
      'feats',
      'featTypes',
    ]),
  },
  methods: {
    formatFeatName,
    ...mapActions('characterSheet', [
      'setFeat',
    ]),
    filterFeatsList() {
      const selectedFilters = this.selectedFeatTypeFilters;
      if (selectedFilters.length > 0) {
        const featsOfSelectedTypes = this.feats.filter((feat) => {
          let typeIsIncluded = false;
          for (const type of selectedFilters) {
            if (feat?.type && lowerString(feat.type).includes(lowerString(type))) {
              typeIsIncluded = true;
            }
          }
          return typeIsIncluded;
        });
        this.selectableFeats = alphabetize(featsOfSelectedTypes);
      } else {
        this.selectableFeats = this.feats;
      }
    },
    getFeatOptions(options) {
      const hasFilteredOptions = this.selectedFeatTypeFilters?.length > 0;
      const hasDefaultOptions = options && options?.length > 0;
      if (hasFilteredOptions) {
        return this.selectableFeats;
      }
      if (hasDefaultOptions) {
        return options;
      }
      return this.feats;
    },
  },
  watch: {
    selectedFeatTypeFilters() {
      this.filterFeatsList();
    },
  },
};
</script>

<style lang="scss">
.feat-fields-content {
  .feat-chooser {
    .filter-by-feat-type-btn.square {
      @include squareButton(28px);
    }
  }
}
.feat-filter-summary {
  width: 100%;
  text-align: center;
  padding: 1.5rem 0 0;
}
</style>
