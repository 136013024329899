<template>
  <div class="stat-box fade-in ml-1">
    <div class="title">
      {{ shortTitle }}
    </div>
    <div class="modifier" v-b-modal="`${title}-modal`">
      <span class="mod-number">{{ stat }}</span>
    </div>
    <RollACheck
      v-if="showCheck"
      :checkName="title"
      :modifier="stat"
      :checkType="checkType"
    />
    <Modal
      :modalTitle="`Detail - ${title}`"
      modalSize="xl"
      :modalId="`${title}-modal`"
      :modalIcon="modalIcon"
      okOnly
    >
      <StatModifiersStringDisplay
        :statTotal="stat"
        :statTotalText="title"
        :statStringArray="statStringArray"
      />
      <slot />
    </Modal>
  </div>
</template>

<script>
import RollACheck from '@/components/RollACheck.vue';
import Modal from '@/components/Modal.vue';
import StatModifiersStringDisplay from '@/components/StatModifiersStringDisplay.vue';

export default {
  name: 'StatBox',
  components: {
    RollACheck,
    Modal,
    StatModifiersStringDisplay,
  },
  props: {
    shortTitle: String,
    title: String,
    stat: [String, Number],
    checkType: String,
    showCheck: {
      type: Boolean,
      default: false,
    },
    statStringArray: {
      type: Array,
      default: () => [],
    },
    modalIcon: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped lang="scss">
.stat-box {
  position: relative;
  display: inline-block;
  flex-grow: 1;
  font-size: 0.875rem;
  height: 50px;
  min-width: 50px;
  text-align: center;
  border-bottom-right-radius: 10px;
  background: $userTheme;
  @include cutTopLeftCorner(10px);
  .title {
    width: 100%;
    padding: 0 10px;
    font-weight: $normal-text;
    font-size: $text-smaller;
    text-transform: uppercase;
  }
  .modifier {
    display: block;
    position: relative;
    font-size: $text-large;
    font-weight: $bold-text;
    line-height: 1;
    @include hoverFocusStyle();
  }
  .roll-a-check {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
