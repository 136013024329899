<template>
  <div class="attack fade-in">
    <SectionDivider
      :title="attack.weapon"
      dividerClass=""
      v-b-modal="'editAttackModal'+attack.id"
    />
    <div class="attack-info my-3">
      <div class="action-buttons p-2">
        <Button
          v-b-popover.hover.focus.blur.top="`Attack w/ ${attack.weapon}`"
          size="sm"
          :id="'attack-roll-btn'+attack.id"
          v-b-modal="'attackModal'+attack.id"
          btnClass="attack-roll-btn"
          square
        >
          <FontAwesomeIcon
            :icon="d20Icon"
          />
        </Button>
      </div>
      <div class="attack-bonus p-2">
        <div class="attack-bonus-and-type">
          {{ plusMinus(getThisAttackBonus(attack)) }}
          <div class="attack-type">
            <FontAwesomeIcon
              :icon="getAttackTypeIcon(attack.attackType)"
            />
          </div>
        </div>
        <div class="label">
          {{ attack.range }}
        </div>
      </div>
      <div class="damage p-2" v-for="(dmg, index) in attack.damage" :key="index">
        {{ dmg.amount }}
        <div class="damage-type">
          {{ dmg.type }}
        </div>
      </div>
      <div class="crit p-2">
        {{ attack.critRange }} {{ 'x'+attack.critMultiplier }}
        <div class="label">
          Crit
        </div>
      </div>
      <div class="action-buttons p-2">
        <Button
          v-b-popover.hover.focus.blur.top="'Delete Attack'"
          size="sm"
          :id="'delete-attack-btn'+attack.id"
          v-b-modal="'deleteAttackModal'+attack.id"
          btnClass="delete-attack-btn"
          square
        >
          <FontAwesomeIcon
            :icon="deleteIcon"
          />
        </Button>
      </div>
    </div>
    <div v-if="attack.ammo" class="attack-ammo mt-2">
      <div
        v-for="(ammoBox, index) in 100"
        :key="index"
        class="attack-ammo-box active"
        @click="toggleAmmoBox(index)"
      />
    </div>
    <Modal
      :modalTitle="`Attack - ${attack.weapon}`"
      modalSize="lg"
      :modalId="'attackModal'+attack.id"
      :modalIcon="getAttackTypeIcon(attack.attackType)"
      okOnly
      @handleModalHide="resetCrit"
    >
      <SectionDivider
        title="To Hit"
        dividerClass=""
      />
      <CheckResults
        checkName="Attack Bonus"
        :modifier="plusMinus(getThisAttackBonus(attack))"
        checkForModifiers="Attack Rolls"
      />
      <SectionDivider
        title="Damage"
        dividerClass=""
      />
      <DamageRoll
        v-for="(dmg, index) in attack.damage"
        :key="index"
        :damageString="dmg.amount"
        :damageType="dmg.type"
      />
      <div
        v-if="critThreat()"
        class="crit-confirm"
      >
        <Button
          @handleButtonClick="rollToConfirm"
        >
          Critical Threat - Roll to Confirm!
        </Button>
      </div>
      <span v-if="canConfirm">
        <SectionDivider
          title="To Confirm Crit"
          dividerClass=""
        />
        <CheckResults
          checkName="Attack Bonus"
          :modifier="plusMinus(getThisAttackBonus(attack))"
          checkForModifiers="Attack Rolls"
        />
        <SectionDivider
          :title="`Critical Damage (x${attack.critMultiplier})`"
          dividerClass=""
        />
        <div
          class='crit-damage-rolls'
          v-for="(mult, index) in (+attack.critMultiplier-1)"
          :key="index"
        >
          <DamageRoll
            v-for="(dmg, index) in attack.damage"
            :key="index"
            :damageString="dmg.amount"
            :damageType="dmg.type"
          />
        </div>
      </span>
    </Modal>
    <Modal
      modalTitle="Edit Attack"
      modalSize="xl"
      :modalId="'editAttackModal'+attack.id"
      :modalIcon="getAttackTypeIcon(attack.attackType)"
      okOnly
    >
      <AttackFields
        :attack="attack"
      />
    </Modal>
    <Modal
      modalTitle="Delete Attack"
      modalSize="md"
      :modalId="'deleteAttackModal'+attack.id"
      :modalIcon="getAttackTypeIcon(attack.attackType)"
      @handleModalSubmit="handleDeleteAttackModalSubmit"
    >
      Are you sure you want to delete this attack?
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SectionDivider from '@/components/SectionDivider.vue';
import Button from '@/components/form/Button.vue';
import Modal from '@/components/Modal.vue';
import CheckResults from '@/components/CheckResults.vue';
import DamageRoll from '@/components/DamageRoll.vue';
import AttackFields from '@/components/AttackFields.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faDiceD20, faFistRaised, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faSword, faBowArrow, faPawClaws } from '@fortawesome/pro-solid-svg-icons';
import { plusMinus, critRangeLow } from '../utils/utils';

export default {
  name: 'Attack',
  components: {
    SectionDivider,
    FontAwesomeIcon,
    Button,
    Modal,
    CheckResults,
    DamageRoll,
    AttackFields,
  },
  data() {
    return {
      canConfirm: false,
      d20Icon: faDiceD20,
      deleteIcon: faTimes,
      meleeIcon: faSword,
      rangedIcon: faBowArrow,
      unarmedIcon: faFistRaised,
      naturalIcon: faPawClaws,
    };
  },
  props: {
    attack: Object,
  },
  computed: {
    ...mapState('characterSheet', [
      'diceCheckLog',
    ]),
    ...mapGetters('characterSheet', [
      'getBaseAttackBonus',
      'getThisAttackBonus',
    ]),
  },
  methods: {
    plusMinus,
    critRangeLow,
    getAttackTypeIcon(type) {
      const attackTypes = {
        Melee: this.meleeIcon,
        Ranged: this.rangedIcon,
        Unarmed: this.unarmedIcon,
        Natural: this.naturalIcon,
      };
      if (attackTypes[type]) {
        return attackTypes[type];
      }
      return attackTypes.Melee;
    },
    toggleAmmoBox() {

    },
    handleDeleteAttackModalSubmit() {
      this.$store.commit('characterSheet/deleteAttack', { attackData: this.attack });
    },
    critThreat() {
      return this.diceCheckLog.length > 0 && +this.diceCheckLog[this.diceCheckLog.length - 1].roll >= +critRangeLow(this.attack.critRange);
    },
    rollToConfirm() {
      this.canConfirm = true;
    },
    resetCrit() {
      this.canConfirm = false;
    },
  },
};
</script>

<style lang="scss">
.attack {
  position: relative;
  display: block;
  font-size: $text-standard;
  font-weight: $normal-text;
  width: auto;
  text-align: left;
  margin: 0.5rem;
  .section-divider {
    @include hoverFocusStyle();
  }
  .attack-info {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    .attack-bonus {
      font-size: $text-large;
      font-weight: $bold-text;
      line-height: 1;
      .attack-bonus-and-type {
        text-align: center;
        .attack-type {
          display: inline-block;
          font-size: $text-standard;
        }
      }
      .label {
        font-size: $text-smaller;
        font-weight: $normal-text;
        text-align: center;
        line-height: 1;
      }
    }
    .damage,
    .range,
    .crit {
      font-size: $text-standard;
      font-weight: $bold-text;
      text-align: center;
      line-height: 1;
      .damage-type,
      .label {
        font-size: $text-smaller;
        font-weight: $normal-text;
        text-align: center;
        line-height: 1;
      }
    }
    .form-group {
      margin-bottom: 0;
    }
    .action-buttons {
      @include flexRowCenter();
    }
  }
  .attack-ammo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    .attack-ammo-box {
      border-radius: 2px;
      height: 10px;
      width: 10px;
      min-width: 10px;
      margin: 2px;
    }
  }
}
.crit-confirm {
  width: 100%;
  text-align: center;
}
</style>
