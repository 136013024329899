import './scss/custom.scss';
import { BootstrapVue } from 'bootstrap-vue';
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import vSelect from 'vue-select';
import App from './App.vue';
import router from './router';
import store from './store';
import titleMixin from './utils/titleMixin';
import setupInterceptors from './utils/httpInterceptors';

Vue.mixin(titleMixin);

Vue.use(BootstrapVue);

Vue.component('fa-icon', FontAwesomeIcon);

Vue.component('v-select', vSelect);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  data() {
    return {
    };
  },
  created() {
    setupInterceptors(store);
  },
  store,
}).$mount('#app');

store.subscribe((mutation, state) => {
  if (state.saveUpdatesToLocalStorage && mutation.name !== 'toggleSaveToLocalStorage') {
    const sheetWithTimestamp = { ...state.characterSheet };
    sheetWithTimestamp.lastSave = new Date();
    localStorage.setItem(state.characterSheet.characterName, JSON.stringify(sheetWithTimestamp));
  }
});
