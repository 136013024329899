<template>
  <div class="inventory fade-in">
    <div class="inventory-content">
      <b-form-row>
        <b-col class="col-12">
          <div class="inventory-subpanel">
            <Button
              alt="Add Inventory Item"
              size="sm"
              btnClass="add-inventory-item-btn"
              @handleButtonClick="handleAddInventoryItemButtonClick"
            >
              + Add Inventory Item
            </Button>
            <b-table
              id="inventory-items-table"
              striped
              small
              foot-clone
              responsive
              stacked="md"
              :per-page="perPage"
              :current-page="currentPage"
              :items="formattedInventoryItems"
              :fields="inventoryTableFields"
              class="inventory-table"
            >
              <template #cell(actions)="row">
                <div class="table-btns d-flex align-items-start">
                  <Button
                    v-b-popover.hover.focus.blur.top="'Edit '+row.item.name"
                    alt="Edit Inventory Item"
                    size="sm"
                    btnClass="edit-inventory-item-btn"
                    square
                    v-b-modal="'editInventoryItemModal'+row.item.id"
                  >
                    <FontAwesomeIcon :icon="editIcon" />
                  </Button>
                  <Button
                    v-b-popover.hover.focus.blur.top="setUseSellMessage(row.item)"
                    :alt="setUseSellMessage(row.item)"
                    size="sm"
                    btnClass="remove-inventory-item-btn"
                    square
                    :disabled="!!itemIsEquipped(row.item, equipment)"
                    @handleButtonClick="handleUseSellInventoryItemButtonClick(row.item)"
                  >
                    <FontAwesomeIcon :icon="removeIcon" />
                  </Button>
                </div>
                <Modal
                  modalTitle="Edit Inventory Item"
                  modalSize="xl"
                  :modalId="'editInventoryItemModal'+row.item.id"
                  :modalIcon="backpackIcon"
                  okOnly
                >
                  <InventoryItemFields
                    :item="row.item"
                  />
                </Modal>
                <Modal
                  modalTitle="Sell/Use Inventory Item"
                  modalSize="md"
                  :modalId="'removeInventoryItemModal'+row.item.id"
                  :modalIcon="backpackIcon"
                  @handleModalSubmit="removeFieldAndModifiers({ list: 'inventory', value: row.item })"
                >
                  Are you sure you want to use/sell {{row.item.name}}?
                </Modal>
              </template>
              <template #foot(quantity)>
                <span>{{ getTotalInventoryItems }} Items</span>
              </template>
              <template #foot(cost)>
                <span>{{ getInventoryValue }} GP</span>
              </template>
              <template #foot(weight)>
                <span>{{ getWeightLoad }}lb. ({{ getTotalInventoryWeight }})</span>
              </template>
            </b-table>
            <div class="table-controls d-flex justify-content-between">
              <ButtonGroup
                id="inventoryItemsPerPage"
                v-model="perPage"
                :options="[10, 25, 50, 100]"
                textField=""
                valueField=""
                label="Items Per Page"
                :showLabel="false"
              />
              <b-pagination
                class="table-pagination"
                v-model="currentPage"
                :total-rows="tableRows"
                :per-page="perPage"
                size="sm"
                aria-controls="inventory-items-table"
              />
            </div>
          </div>
        </b-col>
      </b-form-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { mapMultiRowFields } from 'vuex-map-fields';
import InventoryItemFields from '@/components/InventoryItemFields.vue';
import Button from '@/components/form/Button.vue';
import Modal from '@/components/Modal.vue';
import ButtonGroup from '@/components/form/ButtonGroup.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faBackpack } from '@fortawesome/pro-solid-svg-icons';
import { itemIsEquipped } from '../utils/utils';

export default {
  name: 'InventoryPanel',
  components: {
    InventoryItemFields,
    Button,
    Modal,
    ButtonGroup,
    FontAwesomeIcon,
  },
  data() {
    return {
      removeIcon: faTimes,
      editIcon: faEdit,
      backpackIcon: faBackpack,
      currentPage: 1,
      perPage: 10,
    };
  },
  computed: {
    ...mapState([
      'carryingCapacity',
      'currencyDenominations',
    ]),
    ...mapMultiRowFields('characterSheet', [
      'inventory',
      'equipment',
    ]),
    ...mapGetters('characterSheet', [
      'getWeightLoad',
      'getAbilityScore',
    ]),
    tableRows() {
      return this.formattedInventoryItems.length;
    },
    getTotalInventoryItems() {
      return this.inventory.reduce((acc, item) => acc + +item.quantity, 0);
    },
    getInventoryValue() {
      return this.inventory.reduce((acc, item) => acc + +(this.currencyDenominations[item.denomination] ? (this.currencyDenominations[item.denomination].value * +item.cost * +item.quantity) : 0), 0);
    },
    getTotalInventoryWeight() {
      const weightLoads = this.carryingCapacity[this.getAbilityScore('Strength')];
      if (+this.getWeightLoad > +weightLoads.heavy) { return 'Overencumbered'; }
      if (+this.getWeightLoad > +weightLoads.medium) { return 'Heavy Load'; }
      if (+this.getWeightLoad > +weightLoads.light) { return 'Medium Load'; }
      return 'Light Load';
    },
    formattedInventoryItems() {
      return Object.values(this.inventory).slice().reverse();
    },
    inventoryTableFields() {
      return [
        {
          key: 'quantity',
          label: 'Qty',
          headerAbbr: 'Quantity',
        },
        {
          key: 'name',
          label: 'Item Name',
          sortable: true,
        },
        {
          key: 'cost',
          sortable: true,
          formatter: this.itemCostFormatter,
        },
        {
          key: 'weight',
          label: 'Weight',
          sortable: true,
          formatter: this.itemWeightFormatter,
        },
        {
          key: 'actions',
        },
      ];
    },
  },
  methods: {
    itemIsEquipped,
    ...mapActions('characterSheet', [
      'removeFieldAndModifiers',
    ]),
    handleAddInventoryItemButtonClick() {
      this.$store.commit('characterSheet/addInventoryItem');
      const addedInventoryItemId = this.inventory[this.inventory.length - 1].id;
      this.$nextTick(() => {
        this.$bvModal.show(`editInventoryItemModal${addedInventoryItemId}`);
      });
    },
    handleUseSellInventoryItemButtonClick(item) {
      if (this.itemIsEquipped(item, this.equipment) === false) {
        this.$nextTick(() => {
          this.$bvModal.show(`removeInventoryItemModal${item.id}`);
        });
      }
    },
    itemWeightFormatter(value, key, item) {
      return `${(+value * +item.quantity) || 0}lb.`;
    },
    itemCostFormatter(value, key, item) {
      const getCurrencyObject = this.currencyDenominations[item.denomination];
      return `${value}${getCurrencyObject ? getCurrencyObject.abbreviation.toLowerCase() : ''}`;
    },
    setUseSellMessage(item) {
      return this.itemIsEquipped(item, this.equipment) ? `${item.name} is Equipped - Cannot Use/Sell` : `Use/Sell ${item.name}`;
    },
  },
};
</script>

<style lang="scss">
table {
  color: revert !important;
  .table-btns {
    div.anvil-button {
      margin: 0.25rem;
    }
  }
}
</style>
