<template>
  <div class="spell-fields fade-in">
    <VueSelectField
      :id="'spell-selection-'+charClass.name+'-level-'+spellLevel+'-slot-'+spellSlot"
      label="Spell Name"
      :options="getSpellSlotOptions()"
      taggable
      textField="name"
      valueField="name"
      :value="nameOfSpell"
      size="sm"
      :placeholder="`- Select / Enter a Spell Name${isKnownCaster(charClass) ? ' (Optional)' : ''} -`"
      @handleVueSelectFieldChange="updateSpellSlot"
    />
    <div class="spell-info fade-in">
      <SpellCard :spellData="existingSpellData" />
    </div>
  </div>
</template>

<script>
import VueSelectField from '@/components/form/VueSelectField.vue';
import SpellCard from '@/components/SpellCard.vue';
import {
  chosenSpellName, isKnownCaster, hasSpellbook, hasFamiliar, lookupPreparedSpell,
} from '../utils/utils';

export default {
  name: 'SpellFields',
  components: {
    VueSelectField,
    SpellCard,
  },
  props: {
    spellLevel: {
      type: Number,
      default: null,
    },
    spellSlot: {
      type: Number,
      default: null,
    },
    charClass: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    nameOfSpell() {
      return chosenSpellName(this.charClass, this.spellLevel, this.spellSlot);
    },
    existingSpellData() {
      return lookupPreparedSpell(this.charClass, this.spellLevel, this.spellSlot);
    },
  },
  methods: {
    isKnownCaster,
    updateSpellSlot(value) {
      if (value) {
        if (!this.existingSpellData?.school || this.existingSpellData?.name !== value) {
          this.$store.dispatch('fetchUrlPath', `spells/${encodeURIComponent(value)}`)
            .then((response) => {
              if (response.length > 0) {
                this.setSpellSlot(response[0]);
              } else {
                this.setSpellSlot({});
              }
            });
        } else {
          this.setSpellSlot(this.existingSpellData);
        }
      } else {
        this.setSpellSlot({});
      }
    },
    setSpellSlot(spellSlotData) {
      this.$store.commit('characterSheet/updatePreparedSpellSlot', {
        charClass: this.charClass, spellLevel: this.spellLevel, spellSlot: this.spellSlot, spellData: { ...spellSlotData, hasBeenCast: false },
      });
    },
    getSpellSlotOptions() {
      if (isKnownCaster(this.charClass) || hasSpellbook(this.charClass) || hasFamiliar(this.charClass)) {
        return this.charClass.knownSpells[this.spellLevel];
      }
      return this.charClass.classSpellsList[this.spellLevel];
    },
  },
};
</script>
