const formatDateTimeString = (dateString) => {
  const formatter = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium',
    timeStyle: 'short',
  });

  if (dateString) {
    return formatter.format(new Date(dateString));
  }
  return '';
};

export default formatDateTimeString;
