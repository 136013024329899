<template>
  <div class="skill-user-added-fields fade-in">
    <div class="skill-user-added-fields-content">
      <b-form-row>
        <b-col class="col-6 col-sm-3">
          <InputField
            :id="'skill-user-added-name-'+userSkill.id"
            label="Skill Name"
            placeholder="- Enter Skill Name -"
            v-model="userSkill.name"
          />
        </b-col>
        <b-col class="col-6 col-sm-3">
          <VueSelectField
            :id="'skill-user-added-ability-score-'+userSkill.id"
            label="Ability Score"
            :options="abilityScores"
            placeholder="- Enter Skill Ability Score -"
            v-model="userSkill.abilityScore"
          />
        </b-col>
        <b-col class="col-4 col-sm-2 text-center">
          <CheckboxField
            :id="'skill-user-added-trained-only-'+userSkill.id"
            checkboxClass="mt-4"
            v-model="userSkill.trainedOnly"
          >
            Trained Only
          </CheckboxField>
        </b-col>
        <b-col class="col-4 col-sm-3 text-center">
          <CheckboxField
            :id="'skill-user-added-trained-only-'+userSkill.id"
            checkboxClass="mt-4"
            v-model="userSkill.armorCheckPenalty"
          >
            Armor Check Penalty
          </CheckboxField>
        </b-col>
        <b-col class="col-4 col-sm-1 d-flex justify-content-center align-items-center">
          <Button
            size="sm"
            :btnClass="'remove-user-skill-btn-'+userSkill.id"
            label="Remove This Custom Skill"
            :showLabel="false"
            @handleButtonClick="deleteField({ list: 'userAddedSkills', id: userSkill.id })"
            v-b-popover.hover.focus.blur.top="'Remove This Custom Skill'"
          >
            <FontAwesomeIcon :icon="removeIcon" />
          </Button>
        </b-col>
      </b-form-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import InputField from '@/components/form/InputField.vue';
import VueSelectField from '@/components/form/VueSelectField.vue';
import CheckboxField from '@/components/form/CheckboxField.vue';
import Button from '@/components/form/Button.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'SkillUserAddedFields',
  components: {
    VueSelectField,
    InputField,
    CheckboxField,
    Button,
    FontAwesomeIcon,
  },
  data() {
    return {
      removeIcon: faMinus,
    };
  },
  props: {
    userSkill: Object,
  },
  computed: {
    ...mapState([
      'abilityScores',
    ]),
  },
  methods: {
    ...mapMutations('characterSheet', [
      'deleteField',
    ]),
    setButtonClass(prop) {
      return prop ? 'active' : '';
    },
  },
};
</script>

<style scoped lang="scss">
  div.anvil-button {
    margin-bottom: 0rem;
  }
</style>
