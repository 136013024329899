<template>
  <div class="skill-fields fade-in">
    <div class="skill-points-summary sticky-top text-right">
      <div class="skill-points-budget">{{ getSkillPointsSpent }}/{{ getTotalSkillPoints }} Skill Points Spent</div>
    </div>
    <SkillRow
      v-for="(userSkill, skillId) in userAddedSkills"
      :key="`user-skill-${skillId}`"
      :skill="userSkill"
    />
    <SkillRow
      v-for="(skill, index) in skillsList"
      :key="`standard-skill-${index}`"
      :skill="skill"
    />
  </div>
</template>

<script>
import SkillRow from '@/components/SkillRow.vue';
import { mapGetters } from 'vuex';
import { mapFields, mapMultiRowFields } from 'vuex-map-fields';

export default {
  name: 'SkillFields',
  components: {
    SkillRow,
  },
  props: {
    skillName: String,
  },
  computed: {
    ...mapMultiRowFields('characterSheet', [
      'skillsList',
      'userAddedSkills',
    ]),
    ...mapFields('characterSheet', [
      'userSelectedClassSkills',
    ]),
    ...mapGetters('characterSheet', [
      'getTotalSkillPoints',
      'getSkillPointsSpent',
      'getArmorCheckPenalty',
    ]),
  },
};
</script>

<style lang="scss">
div.skill-fields {
  .skill-points-summary {
    width: 100%;
    z-index: 10;
    .skill-points-budget {
      display: inline-block;
      text-align: right;
      padding: 0.25rem 0.5rem;
      border-radius: 10px;
      z-index: 100;
    }
  }
}
</style>
