<template>
  <div>
    <div>{{ weaponStats.name }} ({{ weaponStats.proficiency }}, {{ weaponStats.encumbrance }}) - {{ weaponStats.cost }}{{ denomAbbrev(weaponStats.denomination) }}</div>
    <div>{{ weaponStats["Dmg (M)"] }} damage ({{ weaponStats.damageType }})</div>
    <div>Crit {{ weaponStats.critical }}</div>
    <div>Range {{ weaponStats.range }}</div>
    <div>Weight {{ weaponStats.weight }} lb.</div>
  </div>
</template>

<script>
import { denomAbbrev } from '../utils/utils';

export default {
  name: 'ItemStatsDisplayWeapon',
  props: {
    weaponStats: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    denomAbbrev,
  },
};
</script>
