<template>
  <div class="armor-class" v-b-modal.armorClassModal>
    <div class="numbers-wrapper">
      <div class="flat-ac">
        <div class="ac-text">
          <div class="ac-num">{{ getFlatAC }}</div>
          <div class="ac-label">Flat</div>
        </div>
      </div>
      <div class="touch-ac">
        <div class="ac-text">
          <div class="ac-num">{{ getTouchAC }}</div>
          <div class="ac-label">Touch</div>
        </div>
      </div>
      <div class="ac">
        <div class="ac-text">
          <div class="ac-num">{{ getArmorClass }}</div>
          <div class="ac-label">AC</div>
        </div>
        <FontAwesomeIcon class="ac-icon" :icon="acIcon"/>
      </div>
    </div>
    <Modal
      modalTitle="Detail - Armor Class"
      modalId="armorClassModal"
      modalSize="xl"
      :modalIcon="acIcon"
      okOnly
    >
      <SectionDivider title="Armor Class" />
      <ArmorClassDisplay
        :acTotal="getArmorClass"
        acType="Total AC"
      />
      <SectionDivider title="Touch Armor Class" />
      <ArmorClassDisplay
        :acTotal="getTouchAC"
        acType="Touch AC"
        :useDex="true"
        :typeFilters="['armor', 'shield', 'natural']"
      />
      <SectionDivider title="Flat-footed Armor Class" />
      <ArmorClassDisplay
        :acTotal="getFlatAC"
        acType="Flat-Footed AC"
        :useDex="false"
        :typeFilters="['dodge']"
      />
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faShield } from '@fortawesome/pro-solid-svg-icons';
import Modal from '@/components/Modal.vue';
import ArmorClassDisplay from '@/components/ArmorClassDisplay.vue';
import SectionDivider from '@/components/SectionDivider.vue';

export default {
  name: 'ArmorClass',
  components: {
    FontAwesomeIcon,
    Modal,
    ArmorClassDisplay,
    SectionDivider,
  },
  data() {
    return {
      acIcon: faShield,
    };
  },
  computed: {
    ...mapGetters('characterSheet', [
      'getArmorClass',
      'getTouchAC',
      'getFlatAC',
    ]),
  },
};
</script>

<style scoped lang="scss">
.armor-class {
  @include hoverFocusStyle();
  position: relative;
  text-align: center;
  max-width: 110px;
  min-width: 110px;
  margin: 0 auto;
  .ac-icon {
    font-size: $text-header;
    color: $userTheme;
    path {
      stroke: $body-bg;
      stroke-width: 30;
      paint-order: stroke;
    }
  }
  .touch-icon {
    color: $userTheme;
    font-size: $text-title;
  }
  .flat-icon {
    color: $userTheme;
    font-size: $text-title;
  }
  .numbers-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    .ac {
      position: relative;
      .ac-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .ac-label {
          @include cleanReset();
          font-size: $text-smaller;
          font-weight: $superthin-text;
        }
        .ac-num {
          @include cleanReset();
          font-size: $text-subtitle;
        }
      }
    }
    .flat-ac, .touch-ac {
      position: absolute;
      bottom: 20px;
      width: 30px;
      .ac-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $userTheme;
        width: 35px;
        height: 35px;
        border-radius: 10% 10% 50% 50%;
        @include flexColumnCenter();
        .ac-label {
          @include cleanReset();
          font-size: $text-tiny;
          font-weight: $superthin-text;
        }
        .ac-num {
          @include cleanReset();
          font-size: $text-standard;
          font-weight: $normal-text;
        }
      }
    }
    .flat-ac {
      right: 0;
    }
    .touch-ac {
      left: 0;
    }
  }
}
</style>
