<template>
  <div class="feat-user-added-panel fade-in">
    <Button
      alt="Add Custom Feat"
      size="sm"
      btnClass="add-custom-feat-btn"
      centered
      @handleButtonClick="addUserFeat"
    >
      + Add Custom Feat
    </Button>
    <FeatUserAddedFields
      v-for="(userFeat, key) in userAddedFeats"
      :key="`custom-feat-fields-${key}`"
      :userFeat="userFeat"
    />
  </div>
</template>

<script>
import { mapMultiRowFields } from 'vuex-map-fields';
import { mapMutations } from 'vuex';
import Button from '@/components/form/Button.vue';
import FeatUserAddedFields from '@/components/FeatUserAddedFields.vue';

export default {
  name: 'EffectsPanel',
  components: {
    Button,
    FeatUserAddedFields,
  },
  computed: {
    ...mapMultiRowFields('characterSheet', [
      'userAddedFeats',
    ]),
  },
  methods: {
    ...mapMutations('characterSheet', [
      'addUserFeat',
    ]),
  },
};
</script>
