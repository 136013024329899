<template>
  <div class="spellcasting fade-in">
    <div v-for="(magicClass, index) in getActiveClasses" :key="index">
      <ClassSpellManager
        v-if="isSpellcastingClass(magicClass) && hasClassSpells(magicClass)"
        :key="index"
        :magicClass="magicClass"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClassSpellManager from '@/components/ClassSpellManager.vue';
import { hasClassSpells, isSpellcastingClass } from '../utils/utils';

export default {
  name: 'SpellcastingPanel',
  components: {
    ClassSpellManager,
  },
  computed: {
    ...mapGetters('characterSheet', [
      'getActiveClasses',
    ]),
  },
  methods: {
    hasClassSpells,
    isSpellcastingClass,
  },
};
</script>
