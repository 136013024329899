<template>
  <div class="status-panel fade-in">
    <b-form-row class="status-panel-content">
      <b-col class="col-12">
        <b-form-row>
          <b-col class="col-12 active-effects-header" v-b-modal.activeEffectsModal>
            <SectionDivider
              title="Active & Temporary Effects"
              dividerClass="my-3"
            />
          </b-col>
          <b-col class="col-12 my-2">
            <div class="active-effects-label" v-if="effects.length === 0">
              You haven't added any effects.
            </div>
            <div class="active-effects-switches" v-if="effects.length > 0">
              <CheckboxField
                v-for="(effect, index) in effects"
                :key="index"
                checkboxClass="my-1"
                v-model="effect.isActive"
              >
                {{ effect.name }}
              </CheckboxField>
            </div>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col class="col-12 conditions-header" v-b-modal.conditionsModal>
            <SectionDivider
              title="Conditions"
              dividerClass="my-3"
            />
          </b-col>
          <b-col class="col-12 my-2">
            <span
              v-for="(cond, index) in characterConditions"
              :key="index"
              class="condition-label"
              v-b-popover.hover.focus.blur.top="getConditionDescription(cond)"
            >
              {{ cond }}{{ addComma(index) }}
            </span>
            <div class="condition-label" v-if="!characterConditions.length">
              No conditions.
            </div>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col class="col-12">
            <SectionDivider
              title="Experience"
              dividerClass="my-3"
            />
          </b-col>
        </b-form-row>
        <b-form-row class="my-2">
          <b-col class="col-12 col-md-6">
            <ButtonGroup
              id="xp-advancement-speed"
              v-model="xpAdvancementSpeed"
              label="XP Advancement Speed"
              :options="[
                {
                  text: 'Slow',
                  value: 'slow',
                },
                {
                  text: 'Medium',
                  value: 'medium',
                },
                {
                  text: 'Fast',
                  value: 'fast',
                },
              ]"
            />
          </b-col>
          <b-col class="col-12 col-md-6">
            <InputField
              id="experience-points"
              label="Experience Points"
              size="sm"
              type="number"
              min=0
              inputClass="xp-input"
              :desc="currentXpPercent >= 100 ? 'Level Up!' : ''"
              v-model="xp"
            />
          </b-col>
          <b-col class="col-12">
            <div class="next-level-text">Next Level [{{ Math.floor(currentXpPercent) }}%]</div>
            <b-progress :value="currentXpPercent" />
            <div class="next-level-subtext">
              [{{ xp }} / {{ nextLevelXpThreshold }}]
            </div>
          </b-col>
          <b-col class="col-12" v-if="currentXpPercent >= 100">
            <Button
              btnClass="level-up-btn"
              size="sm"
              v-b-modal.raceClassAlignmentModal
            >
              Level Up!
            </Button>
          </b-col>
        </b-form-row>
      </b-col>
    </b-form-row>
    <Modal
      modalTitle="Edit Active & Temporary Effects"
      modalSize="xl"
      modalId="activeEffectsModal"
      okOnly
    >
      <EffectsPanel />
    </Modal>
    <Modal
      modalTitle="Edit Conditions"
      modalSize="xl"
      modalId="conditionsModal"
      okOnly
    >
      <Conditions/>
    </Modal>
  </div>
</template>

<script>
import { mapFields, mapMultiRowFields } from 'vuex-map-fields';
import { mapState, mapGetters } from 'vuex';
import Modal from '@/components/Modal.vue';
import ButtonGroup from '@/components/form/ButtonGroup.vue';
import InputField from '@/components/form/InputField.vue';
import CheckboxField from '@/components/form/CheckboxField.vue';
import Conditions from '@/components/Conditions.vue';
import SectionDivider from '@/components/SectionDivider.vue';
import EffectsPanel from '@/components/EffectsPanel.vue';
import Button from '@/components/form/Button.vue';

export default {
  name: 'StatusPanel',
  components: {
    Button,
    ButtonGroup,
    InputField,
    CheckboxField,
    Conditions,
    SectionDivider,
    Modal,
    EffectsPanel,
  },
  computed: {
    ...mapState([
      'experienceLevels',
      'conditions',
    ]),
    ...mapState('characterSheet', [
      'modifiers',
    ]),
    ...mapGetters('characterSheet', [
      'getTotalClassLevels',
    ]),
    ...mapFields('characterSheet', [
      'xp',
      'xpAdvancementSpeed',
      'characterConditions',
    ]),
    ...mapMultiRowFields('characterSheet', [
      'effects',
    ]),
    amountBetweenXpLevels() {
      return +this.nextLevelXpThreshold - +this.currentLevelXpThreshold;
    },
    currentXpPercent() {
      const perc = ((+this.xp - +this.currentLevelXpThreshold) / this.amountBetweenXpLevels) * 100;
      if (perc > 100) {
        return 100;
      }
      if (perc < 0) {
        return 0;
      }
      return perc;
    },
    nextLevelXpThreshold() {
      return this.experienceLevels[+this.getTotalClassLevels + 1][this.xpAdvancementSpeed || 'medium'];
    },
    currentLevelXpThreshold() {
      return this.experienceLevels[+this.getTotalClassLevels][this.xpAdvancementSpeed || 'medium'];
    },
  },
  methods: {
    getConditionDescription(condName) {
      const foundCond = this.conditions.find((cond) => cond.name.toLowerCase() === condName.toLowerCase());
      return (foundCond && foundCond.description) || '';
    },
    addComma(index) {
      return index < this.characterConditions.length - 1 ? ', ' : '';
    },
  },
};
</script>

<style lang="scss">
.status-panel {
  .status-panel-content {
    .conditions-header,
    .active-effects-header {
      @include hoverFocusStyle();
    }
    .condition-label,
    .active-effects-label {
      font-size: $text-smaller;
      cursor: pointer;
    }
    .active-effects-switches {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .checkbox-field {
        width: 100%;
        label {
          width: 100%;
        }
      }
    }
    .next-level-text {
      font-size: $text-smaller;
      text-align: left;
      margin-bottom: 0.5rem;
    }
    .next-level-subtext {
      font-size: $text-smallest;
    }
    .level-up-btn {
      margin-top: 1rem;
    }
  }
}
</style>
