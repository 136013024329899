<template>
  <div>
    <div>{{ itemStats.name }} - {{ itemStats.cost }}{{ denomAbbrev(itemStats.denomination) }}</div>
    <div v-for="(stat, index) in itemStats" :key="index">
      <div v-if="index !== 'name' && index !== 'cost' && index !== 'denomination'">
        {{ capIt(index) }}: {{ stat }}
      </div>
    </div>
  </div>
</template>

<script>
import { denomAbbrev, capIt } from '../utils/utils';

export default {
  name: 'ItemStatsDisplayWeapon',
  props: {
    itemStats: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    denomAbbrev,
    capIt,
  },
};
</script>
