<template>
  <b-form-group
    class="multi-select-field"
    :id="id+'group'"
    :label="label"
    :label-for="id"
    :label-sr-only="!showLabel"
    :description="desc"
  >
    <v-select
      :id="id"
      :options="options"
      :label="textField"
      :value="valueField"
      :size="size"
      :required="required"
      :disabled="disabled"
      :placeholder="placeholder"
      v-model="inputValue"
      :close-on-select="closeOnSelect"
      :taggable="taggable"
      multiple
      transition=""
      :components="loading ? {Deselect} : null"
      @input="handleMultiSelectFieldChange"
    >
      <template v-if="textField && valueField" #selected-option="{ name, description }">
        <div
          v-b-popover.hover.focus.blur.top="description"
        >
          {{ name }}
        </div>
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import Deselect from '@/components/DeselectSpinner.vue';
import { mapState } from 'vuex';

export default {
  name: 'MultiSelectField',
  data: () => ({
    Deselect,
  }),
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    ...mapState('loader', [
      'loading',
    ]),
  },
  props: {
    id: String,
    options: Array,
    value: [String, Number, Array],
    textField: String,
    valueField: String,
    size: {
      type: String,
      default: 'sm',
    },
    label: String,
    showLabel: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '- Select -',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    taggable: {
      type: Boolean,
      default: true,
    },
    desc: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleMultiSelectFieldChange(selectedValues) {
      this.$emit('handleMultiSelectFieldChange', selectedValues);
    },
  },
};
</script>
