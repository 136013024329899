<template>
  <div class="spell-card fade-in">
    <b-form-row class="mb-3">
      <b-col class="spell-card-header">
        <div class="spell-name">{{ spellData.name }}</div>
        <div class="spell-school">{{getSpellSchoolInfo}}</div>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col class="d-flex flex-row flex-wrap flex-gap align-items-start justify-content-around">
        <StatIcon v-if="spellData.casting_time" statName="Casting Time" :icon="castingTimeIcon" :statText="spellData.casting_time" />
        <StatIcon v-if="spellData.range" statName="Range" :icon="rangeIcon" :statText="spellData.range" />
        <StatIcon v-if="spellData.targets" statName="Targets" :icon="targetsIcon" :statText="spellData.targets" />
        <StatIcon v-if="spellData.components" statName="Components" :icon="componentsIcon" :statText="spellData.components" />
        <StatIcon v-if="spellData.duration" statName="Duration" :icon="durationIcon" :statText="spellData.duration" />
        <StatIcon v-if="spellData.saving_throw" statName="Saving Throw" :icon="savingThrowIcon" :statText="spellData.saving_throw" />
        <StatIcon v-if="spellData.spell_resistence" statName="Spell Resistance" :icon="spellResistanceIcon" :statText="spellData.spell_resistence" />
      </b-col>
    </b-form-row>
    <SectionDivider v-if="spellData.description" title="Description" dividerClass="spell-card-divider" />
    <b-form-row v-if="spellData.description">
      <b-col class="col-10 offset-1 spell-description">
        {{ spellData.description }}
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import {
  faStopwatch, faMortarPestle, faBowArrow, faHourglass, faShieldAlt, faBullseyeArrow, faHandSparkles,
} from '@fortawesome/pro-regular-svg-icons';
import StatIcon from '@/components/StatIcon.vue';
import SectionDivider from '@/components/SectionDivider.vue';
import { capIt } from '../utils/utils';

export default {
  name: 'SpellCard',
  components: {
    StatIcon,
    SectionDivider,
  },
  data() {
    return {
      castingTimeIcon: faStopwatch,
      componentsIcon: faMortarPestle,
      rangeIcon: faBowArrow,
      targetsIcon: faBullseyeArrow,
      durationIcon: faHourglass,
      savingThrowIcon: faShieldAlt,
      spellResistanceIcon: faHandSparkles,
    };
  },
  props: {
    spellData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getSpellSchoolInfo() {
      const { school, subschool, descriptor } = this.spellData;
      return capIt(school) + (subschool ? ` (${capIt(subschool)})` : '') + (descriptor ? ` [${descriptor}]` : '');
    },
  },
  methods: {
    capIt,
  },
};
</script>

<style scoped lang="scss">
.spell-card {
  .spell-card-header {
    .spell-name {
      font-size: $text-large;
      line-height: 1.15;
    }
    .spell-school {
      font-size: $text-small;
      line-height: 1;
    }
  }
  .spell-card-divider {
    margin: 0.5rem;
    margin-bottom: 1rem;
  }
  .spell-description {
    font-size: $text-smaller;
  }
  .stat-icon {
    margin: 0px 5px 15px;
  }
}

</style>
