<template>
  <div class="form-group multi-select-button-group">
    <label
      :for="'multi-select-btn-group-'+id"
      :class="`d-block ${showLabel ? '' : 'sr-only'}`"
    >
    {{ label }}
      <HelpIcon
        v-if="helpText"
        :helpText="helpText"
      />
    </label>
    <b-form-checkbox-group
      v-model="selectedValues"
      @change="handleMultiSelectButtonGroupChange"
      @input="handleMultiSelectButtonGroupInput"
      buttons
      :class="'multiselect-btn-group multi-select-btn-group-'+id"
    >
      <b-form-checkbox
        v-for="(option, index) in options"
        :key="index"
        size="sm"
        :id="`multi-select-checkbox-${id}-${index}`"
        :class="[{ active: (selectedValues.includes(option[valueField]) || selectedValues.includes(option)) }, `multi-select-checkbox m-1 ${multiSelectButtonClass}`]"
        :value="option[textField] || option"
      >
        {{ option[textField] || option }}
      </b-form-checkbox>
    </b-form-checkbox-group>
  </div>
</template>

<script>
import HelpIcon from '@/components/form/HelpIcon.vue';

export default {
  name: 'MultiSelectButtonGroup',
  components: {
    HelpIcon,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    label: String,
    showLabel: {
      type: Boolean,
      default: true,
    },
    options: Array,
    textField: {
      type: [String, Number],
      default: 'text',
    },
    valueField: {
      type: [String, Number],
      default: 'value',
    },
    popoverField: {
      type: String,
      default: '',
    },
    value: [Array, Object],
    helpText: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    multiSelectButtonClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    selectedValues: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    handleMultiSelectButtonGroupChange(event) {
      this.$emit('handleMultiSelectButtonGroupChange', event);
    },
    handleMultiSelectButtonGroupInput(event) {
      this.$emit('handleMultiSelectButtonGroupInput', event);
    },
  },
};
</script>

<style lang="scss">
.multi-select-button-group {
  .multiselect-btn-group {
    flex-wrap: wrap;
  }
}
.multi-select-checkbox {
  @include squareButton(28px);
  border-radius: 0.2rem !important;
  @include flexRowCenter();
  label.btn {
    @include squareButton(28px);
  }
}
</style>
