<template>
  <div class="bab-override-fields fade-in">
    <b-form-row>
      <b-col class="col-12 col-sm-3">
        <CheckboxField
          id="override-bab-checkbox"
          checkboxClass="my-3 mr-2"
          v-model="overrideBaB"
        >
          Override BaB
        </CheckboxField>
      </b-col>
      <b-col class="col-12 col-sm-3">
        <SpinButton
          id="bab-override-number"
          label="BaB (Override)"
          v-if="overrideBaB"
          v-model="babOverride"
        />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import CheckboxField from '@/components/form/CheckboxField.vue';
import SpinButton from '@/components/form/SpinButton.vue';

export default {
  name: 'BaBOverrideFields',
  components: {
    CheckboxField,
    SpinButton,
  },
  computed: {
    ...mapFields('characterSheet', [
      'overrideBaB',
      'babOverride',
    ]),
  },
};
</script>

<style scoped lang="scss">
.bab-override-fields {
  .bab-override-numbergroup {
    margin-bottom: 0px !important;
  }
}
</style>
