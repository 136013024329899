<template>
  <div class="bio-fields fade-in">
    <b-form-row>
      <b-col class="col-12 col-md-6 col-xl-4">
        <InputField
          id="playerName"
          label="Player Name"
          v-model="playerName"
        />
      </b-col>
      <b-col class="col-6 col-md-3 col-xl-1">
        <SpinButton
          id="characterAge"
          label="Age"
          min=1
          v-model="age"
        />
      </b-col>
      <b-col class="col-6 col-md-3 col-xl-2">
        <VueSelectField
          id="characterAgeCategory"
          label="Age Category"
          :options="Object.keys(ageCategories)"
          placeholder="- Age Category -"
          v-model="ageCategory"
        />
      </b-col>
      <b-col class="col-6 col-md-3 col-xl-1">
        <InputField
          id="characterHeight"
          label="Height"
          v-model="height"
        />
      </b-col>
      <b-col class="col-6 col-md-3 col-xl-1">
        <InputField
          id="characterWeight"
          label="Weight"
          v-model="weight"
        />
      </b-col>
      <b-col class="col-4 col-md-2 col-xl-1">
        <InputField
          id="characterHairColor"
          label="Hair"
          v-model="hairColor"
        />
      </b-col>
      <b-col class="col-4 col-md-2 col-xl-1">
        <InputField
          id="characterEyeColor"
          label="Eyes"
          v-model="eyeColor"
        />
      </b-col>
      <b-col class="col-4 col-md-2 col-xl-1">
        <InputField
          id="characterSkinColor"
          label="Skin"
          v-model="skinColor"
        />
      </b-col>
      <b-col class="col-12 col-sm-6 col-md-3 col-xl-3">
        <InputField
          id="characterGender"
          label="Gender"
          v-model="gender"
        />
      </b-col>
      <b-col class="col-12 col-sm-6 col-md-3 col-xl-3">
        <InputField
          id="homeland"
          label="Homeland"
          v-model="homeland"
        />
      </b-col>
      <b-col class="col-12 col-sm-6 col-md-3 col-xl-3">
        <InputField
          id="deity"
          label="Deity"
          v-model="deity"
        />
      </b-col>
      <b-col class="col-12 col-sm-6 col-md-3 col-xl-3">
        <InputField
          id="campaign"
          label="Campaign"
          v-model="campaign"
        />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import InputField from '@/components/form/InputField.vue';
import SpinButton from '@/components/form/SpinButton.vue';
import VueSelectField from '@/components/form/VueSelectField.vue';

const { mapFields } = createHelpers({
  getterType: 'characterSheet/getField',
  mutationType: 'characterSheet/updateField',
});

export default {
  name: 'BioFields',
  components: {
    InputField,
    SpinButton,
    VueSelectField,
  },
  computed: {
    ...mapState([
      'characterSheet',
      'alignments',
      'ageCategories',
    ]),
    ...mapFields([
      'age',
      'ageCategory',
      'hairColor',
      'eyeColor',
      'skinColor',
      'height',
      'weight',
      'gender',
      'homeland',
      'deity',
      'campaign',
      'playerName',
    ]),
  },
};
</script>

<style scoped lang="scss">
.bio-fields {
  margin: 0 1%;
}
</style>
