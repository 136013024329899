<template>
  <div class="carrying-capacity fade-in">
    <div class="carrying-capacity-content" v-if="getCarryingCapacity('light')">
      Light Load: {{ getCarryingCapacity('light') }}lb.<br>
      Medium Load: {{ getCarryingCapacity('medium') }}lb.<br>
      Heavy Load: {{ getCarryingCapacity('heavy') }}lb.<br>
      Lift Over Head: {{ getCarryingCapacity('overHead') }}lb.<br>
      Lift Off Ground: {{ getCarryingCapacity('offGround') }}lb.<br>
      Push or Drag: {{ getCarryingCapacity('pushOrDrag') }}lb.<br>
    </div>
    <div v-if="!getCarryingCapacity('light')">
      You are exceptionally weak. You should consider lifting weights or purchasing a surgically-implanted robotic exoskeleton to improve your strength.
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CarryingCapacityPanel',
  computed: {
    ...mapGetters('characterSheet', [
      'getCarryingCapacity',
    ]),
  },
};
</script>
