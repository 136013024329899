<template>
  <Modal
    modalId="stepByStepBuilderModal"
    modalTitle="ANViL - Character Creation"
    okOnly
    modalOkTitle="Done"
    modalSize="xl"
    modalClass="step-by-step-builder-modal"
    isScrollable
    :hideFooter="false"
    @handleModalSubmit="stepByStepBuilderModalSubmit"
    @handleModalShow="stepByStepBuilderModalShow"
    @handleModalHide="stepByStepBuilderModalHide"
  >
    <SectionDivider
      dividerClass="step-section"
      title="Name Your Character"
    />
    <NameFields />
    <SectionDivider
      dividerClass="step-section"
      title="Choose a Race, Class(es), and Alignment"
    />
    <RaceClassAlignmentFields />
    <SectionDivider
      dividerClass="step-section"
      title="Determine Ability Scores"
    />
    <AbilityScoreFields />
    <SectionDivider
      dividerClass="step-section"
      title="Allocate Skill Ranks"
    />
    <SkillFields />
    <SectionDivider
      dividerClass="step-section"
      title="Choose Feats"
    />
    <FeatsPanel />
    <SectionDivider
      dividerClass="step-section"
      title="Choose Traits"
    />
    <TraitsPanel />
    <SectionDivider
      dividerClass="step-section"
      title="Determine Hit Points"
    />
    <HitPointFields />
    <SectionDivider
      dividerClass="step-section"
      title="Get Equipped"
    />
      <InventoryPanel />
    <SectionDivider
      dividerClass="step-section"
      v-if="isSpellcaster"
      title="Choose Spells"
    />
      <SpellcastingPanel v-if="isSpellcaster" />
    <SectionDivider
      dividerClass="step-section"
      title="Description"
    />
      <BioFields />
    <SectionDivider
      dividerClass="step-section"
      title="Portrait"
    />
      <PortraitFields />
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@/components/Modal.vue';
import SectionDivider from '@/components/SectionDivider.vue';
import NameFields from '@/components/NameFields.vue';
import AbilityScoreFields from '@/components/AbilityScoreFields.vue';
import RaceClassAlignmentFields from '@/components/RaceClassAlignmentFields.vue';
import SkillFields from '@/components/SkillFields.vue';
import FeatsPanel from '@/components/FeatsPanel.vue';
import TraitsPanel from '@/components/TraitsPanel.vue';
import HitPointFields from '@/components/HitPointFields.vue';
import InventoryPanel from '@/components/InventoryPanel.vue';
import BioFields from '@/components/BioFields.vue';
import SpellcastingPanel from '@/components/SpellcastingPanel.vue';
import PortraitFields from '@/components/PortraitFields.vue';

export default {
  name: 'StepByStepBuilder',
  components: {
    Modal,
    SectionDivider,
    NameFields,
    AbilityScoreFields,
    RaceClassAlignmentFields,
    SkillFields,
    FeatsPanel,
    TraitsPanel,
    HitPointFields,
    InventoryPanel,
    BioFields,
    SpellcastingPanel,
    PortraitFields,
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    ...mapGetters('characterSheet', [
      'isSpellcaster',
    ]),
  },
  methods: {
    stepByStepBuilderModalSubmit() {

    },
    stepByStepBuilderModalShow() {
      this.$store.commit('toggleProperty', 'stepEdit');
    },
    stepByStepBuilderModalHide() {
      this.$store.commit('toggleProperty', 'stepEdit');
    },
  },
};
</script>

<style lang="scss">
#stepByStepBuilderModal {
  padding: 0 !important;
  margin: 0;
  .modal-dialog {
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
  div.section-divider.step-section {
    margin: 3rem !important;
    font-size: $text-med-large;
    font-weight: $thinner-text;
    letter-spacing: 1px;
  }
}
.step-by-step-builder-modal {
  .modal-dialog-scrollable {
    min-height: 100% !important;
    max-height: 100% !important;
  }
}

</style>
