<template>
  <div class="modal fade-in">
    <b-modal
      :id="modalId"
      :title="modalTitle"
      :ok-title="modalOkTitle"
      :cancel-title="modalCancelTitle"
      :modal-class="modalClass"
      ok-variant="secondary"
      no-fade
      :ok-only="okOnly"
      :hide-footer="hideFooter"
      :hide-header="hideHeader"
      :size="modalSize"
      :button-size="modalButtonSize"
      :scrollable="isScrollable"
      :no-close-on-esc="preventCloseOnHide"
      :no-close-on-backdrop="preventCloseOnHide"
      :hide-header-close="preventCloseOnHide"
      @show="handleModalShow"
      @hidden="handleModalHide"
      @ok="handleModalSubmit"
    >
      <div slot="modal-title" v-if="!hideHeader">
        <FontAwesomeIcon class="mr-2" :icon="modalIcon || modalDefault" />{{ modalTitle }}
      </div>
    <ErrorBar
      v-for="(error, index) in errors"
      :key="index"
      :error="error"
      @closeMe="handleErrorClose(index)"
    />
      <slot></slot>
  </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ErrorBar from '@/components/ErrorBar.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'Modal',
  components: {
    ErrorBar,
    FontAwesomeIcon,
  },
  data() {
    return {
      modalDefault: faUser,
    };
  },
  props: {
    modalId: String,
    modalClass: String,
    modalIcon: {
      type: Object,
      default: () => {},
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    modalTitle: String,
    modalOkTitle: String,
    modalCancelTitle: String,
    okOnly: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    modalSize: {
      type: String,
      default: 'lg',
    },
    modalButtonSize: {
      type: String,
      default: 'sm',
    },
    isScrollable: {
      type: Boolean,
      default: false,
    },
    preventCloseOnSubmit: {
      type: Boolean,
      default: false,
    },
    preventCloseOnHide: {
      type: Boolean,
      default: false,
    },
    preventSaveWhileOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState([
      'errors',
      'saveUpdatesToLocalStorage',
    ]),
  },
  methods: {
    handleLocalStorageSave() {
      if (this.preventSaveWhileOpen && this.saveUpdatesToLocalStorage) {
        this.$store.commit('toggleSaveToLocalStorage', false);
      }
    },
    handleErrorClose(index) {
      this.$store.commit('removeError', index);
    },
    handleModalShow(bvModalEvt) {
      this.handleLocalStorageSave();
      this.$store.commit('clearErrors');
      this.$emit('handleModalShow', bvModalEvt);
    },
    handleModalHide(bvModalEvt) {
      if (this.preventCloseOnHide) {
        bvModalEvt.preventDefault();
      }
      this.$emit('handleModalHide', bvModalEvt);
    },
    handleModalSubmit(bvModalEvt) {
      if (this.preventCloseOnSubmit) {
        bvModalEvt.preventDefault();
      }
      this.$emit('handleModalSubmit', bvModalEvt);
    },
  },
};
</script>

<style lang="scss">
.modal-header {
  border: 0;
  .modal-title {
    font-size: $text-large;
    font-weight: $thinner-text;
    letter-spacing: 1px;
  }
}
.modal-footer {
  border: 0;
}
.modal-content {
  font-size: $text-small;
  background-color: $panel-bg;
  .close {
    opacity: 1;
  }
}
.modal {
  .modal-dialog-scrollable {
    .modal-content {
      min-height: 100% !important;
      max-height: 100% !important;
    }
  }
}
@media screen and (max-width: 992px) {
  .modal,
  .modal-dialog {
    padding: 0 !important;
    margin: 0;
    border-radius: 0;
    max-width: 100% !important;
  }
  .modal-header,
  .modal-footer {
    border-radius: 0;
  }
  .modal-header {
    .modal-title {
      font-size: $text-med-large;
      letter-spacing: 0px;
    }
  }
}
</style>
