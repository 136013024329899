<template>
  <div class="equipment fade-in">
    <div class="equipment-content">
      <b-form-row>
        <b-col class="col-12">
          <div class="equipment-subpanel">
            <EquipmentBlock
              v-for="(value, key) in equipment" :key="key"
              :equipmentSlot="value"
            />
          </div>
        </b-col>
      </b-form-row>
    </div>
  </div>
</template>

<script>
import { mapMultiRowFields } from 'vuex-map-fields';
import EquipmentBlock from '@/components/EquipmentBlock.vue';

export default {
  name: 'EquipmentPanel',
  components: {
    EquipmentBlock,
  },
  computed: {
    ...mapMultiRowFields('characterSheet', [
      'equipment',
    ]),
  },
};
</script>

<style scoped lang="scss">
.equipment {
  .equipment-content {
    .equipment-subpanel {
      @include flexRowCenter();
      flex-wrap: wrap;
    }
  }
}

</style>
