<template>
  <div class="hit-point-fields fade-in">
    <div class="w-100 text-center" v-if="!getTotalClassLevels">
      You don't have any class levels. You need some levels if you want some hit points.<br>
      <br>
      To gain one or more levels:<br>
      <br>
      Close this modal, then click the panel labeled 'Select Your Race and Class(es)' beneath the Character Name panel.<br>
    </div>
    <div v-for="(activeClass, index) in getActiveClasses" :key="index">
      <HitPointLevelRow
        v-for="(lvlHp, index) in +activeClass.classLevels"
        :key="index"
        :level="+index"
        :charClass="activeClass"
      />
    </div>
  </div>
</template>

<script>
import HitPointLevelRow from '@/components/HitPointLevelRow.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'HitPointFields',
  components: {
    HitPointLevelRow,
  },
  computed: {
    ...mapGetters('characterSheet', [
      'getTotalClassLevels',
      'getActiveClasses',
    ]),
  },
};
</script>
