<template>
  <div class="portrait-panel fade-in"
    v-b-modal.portraitModal
  >
    <CircularImage :imgUrl="portraitImgUrl" imgText="Add a Character Portrait" />
    <Modal
      modalTitle="Edit Character Portrait"
      modalSize="lg"
      modalId="portraitModal"
      okOnly
    >
      <PortraitFields/>
    </Modal>
  </div>

</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import Modal from '@/components/Modal.vue';
import PortraitFields from '@/components/PortraitFields.vue';
import CircularImage from '@/components/CircularImage.vue';

const { mapFields } = createHelpers({
  getterType: 'characterSheet/getField',
  mutationType: 'characterSheet/updateField',
});

export default {
  name: 'PortraitPanel',
  components: {
    Modal,
    PortraitFields,
    CircularImage,
  },
  computed: {
    ...mapFields([
      'portraitImgUrl',
    ]),
  },
};
</script>

<style lang="scss">
.portrait-panel {
  margin: 1rem 1rem 1rem 0;
  text-align: right;
  position: relative;
  @include hoverFocusStyle();
  .image-container {
    box-shadow: inset 0 0 20px 5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: inline-block;
    position: relative;
    filter: drop-shadow(2px 4px 6px black) !important;
  }
  @media screen and (max-width: 576px) {
    margin: 1rem 0;
  }
}
</style>
