<template>
  <div class="name-fields fade-in">
    <b-form-row>
      <b-col :class="`col-12${introName ? ' intro-name' : ''}`">
        <InputField
          id="characterName"
          :label="label"
          :showLabel="showLabel"
          v-model="characterName"
          :desc="desc"
        />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import InputField from '@/components/form/InputField.vue';

export default {
  name: 'NameFields',
  components: {
    InputField,
  },
  props: {
    desc: {
      type: String,
      default: '',
    },
    introName: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Character Name',
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapFields('characterSheet', [
      'characterName',
    ]),
  },
};
</script>

<style lang="scss">
.name-fields {
  margin: 0 1%;
  .intro-name {
    .form-group.input-field {
      label {
        text-align: center;
        font-family: 'cardo';
        font-size: $text-med-large !important;
      }
      input {
        font-size: $text-title !important;
        border: none !important;
        text-align: center;
        font-family: 'cardo-bold';
      }
      small {
        text-align: center;
      }
      @media screen and (max-width: 576px) {
        label {
          font-size: $text-smaller !important;
        }
        input {
          font-size: $text-large !important;
        }
      }
    }
  }
}
</style>
