export const blankSpellSlot = () => ({
  name: '',
  hasBeenCast: false,
});

export const emptyDamage = () => ({
  type: '',
  amount: '',
});

export const emptyAttack = () => ({
  weapon: '',
  attackType: '',
  attackBonus: 0,
  useBaB: false,
  critRange: '',
  critMultiplier: 2,
  range: '',
  ammo: '',
  useAmmo: 0,
  damage: [],
});

export const emptyInventoryItem = () => ({
  quantity: 1,
  category: '',
  name: '',
  type: '',
  slot: '',
  weight: 0,
  cost: 0,
  denomination: '',
  casterLevel: null,
  aura: '',
  description: '',
  stats: {},
  quality: 'Mundane',
  enhancementBonus: 0,
  modifierIds: [],
  source: '',
});

export const emptySkill = () => ({
  ranks: 0,
  subskill: '',
});

export const emptyUserAddedSkill = () => ({
  name: '',
  trainedOnly: false,
  armorCheckPenalty: false,
  abilityScore: '',
  ...emptySkill(),
});

export const emptyFeat = () => ({
  name: '',
  id: 0,
  types: [],
  selectedTypes: [],
  source: '',
  levelGained: 1,
  options: [],
  description: '',
  benefit: '',
  prerequisites: '',
  selection: '',
  modifierIds: [],
});

export const clearedFeat = () => ({
  selectedTypes: [],
  description: '',
  benefit: '',
  prerequisites: '',
  selection: '',
});

export const emptyTrait = () => ({
  name: '',
  type: '',
  subtype: '',
  description: '',
  modifierIds: [],
});

export const emptyEquipmentSlot = () => ({
  equippedItemId: null,
});

export const emptySpellArray = () => ([
  [], [], [], [], [], [], [], [], [], [],
]);

export const abilityScoresSet = (val) => ({
  Strength: val || 0,
  Dexterity: val || 0,
  Constitution: val || 0,
  Intelligence: val || 0,
  Wisdom: val || 0,
  Charisma: val || 0,
});

export const emptyEffect = () => ({
  name: '',
  type: '',
  isActive: false,
  modifierIds: [],
});

export const emptyModifier = () => ({
  name: '',
  categories: [],
  type: '',
  amount: 0,
});

export const emptyDiceCheck = () => ({
  name: '',
  modifier: 0,
  roll: 0,
  result: 0,
});

export const emptySpellcasting = () => ({
  name: 'Spellcasting',
  isSpellcaster: false,
  'Level Increases': [1],
  spellType: '',
  'Spell List': '',
  castingType: '',
  'Caster Level Mod': 0,
  Cantrips: true,
  'Spell Source': '',
  castingAbility: '',
  'Spell DC': '',
  'Casting Focus': '',
  spellsKnown: [],
  spellsPerDay: [],
});

export const emptyClass = () => ({
  name: '',
  classArchetype: '',
  classLevels: 0,
  hitDie: null,
  BaB: null,
  savingThrows: null,
  skillPointsPerLevel: null,
  classSkills: [],
  knownSpells: [...emptySpellArray()],
  preparedSpells: [...emptySpellArray()],
  classSpellsList: [...emptySpellArray()],
  spellBook: '',
  spellcasting: { ...emptySpellcasting() },
  classFeatures: {},
  hpRolls: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  isFavored: false,
  favHp: 0,
  favSp: 0,
});

export const emptyClassFeature = () => ({
  name: 'New Class Feature',
  id: 0,
  modifierIds: [],
  'Level Increases': [],
  Description: '',
});

export const emptyRacialTrait = () => ({
  name: 'New Racial Trait',
  id: 0,
  modifierIds: [],
  'Level Increases': [],
  Description: '',
});

export const blankCharacter = () => ({
  characterTheme: 'tublave',
  characterName: '',
  playerName: '',
  alignment: '',
  raceName: null,
  raceSize: null,
  raceSpeed: null,
  raceStartingAbilMod: false,
  racialLanguages: [],
  racialTraits: {},
  gender: '',
  age: null,
  ageCategory: 'Normal',
  height: '',
  weight: null,
  hairColor: '',
  eyeColor: '',
  skinColor: '',
  portraitImgUrl: '',
  xp: 0,
  xpAdvancementSpeed: 'medium',
  deity: '',
  campaign: '',
  homeland: '',
  hpCurrent: 0,
  hpTemp: 0,
  hpRollLock: false,
  hpRacial: 0,
  hpStartingBonus: 0,
  overrideBaB: false,
  babOverride: 0,
  abilityIncreases: {
    1: '',
    4: '',
    8: '',
    12: '',
    16: '',
    20: '',
  },
  baseAbilityScores: {
    ...abilityScoresSet(10),
  },
  miscAbilityModifiers: {
    ...abilityScoresSet(),
  },
  raceAbilityModifiers: {
    ...abilityScoresSet(),
  },
  characterClasses: [
    {
      id: 0,
      classType: 'Starting Class',
      ...emptyClass(),
    },
    {
      id: 1,
      classType: 'Multiclass',
      ...emptyClass(),
    },
    {
      id: 2,
      classType: 'Multiclass',
      ...emptyClass(),
    },
    {
      id: 3,
      classType: 'Multiclass',
      ...emptyClass(),
    },
  ],
  userSelectedClassSkills: [],
  skillsList: [
    {
      name: 'Acrobatics',
      trainedOnly: false,
      armorCheckPenalty: true,
      abilityScore: 'Dexterity',
      ...emptySkill(),
    },
    {
      name: 'Appraise',
      trainedOnly: false,
      armorCheckPenalty: false,
      abilityScore: 'Intelligence',
      ...emptySkill(),
    },
    {
      name: 'Bluff',
      trainedOnly: false,
      armorCheckPenalty: false,
      abilityScore: 'Charisma',
      ...emptySkill(),
    },
    {
      name: 'Climb',
      trainedOnly: false,
      armorCheckPenalty: true,
      abilityScore: 'Strength',
      ...emptySkill(),
    },
    {
      name: 'Craft1',
      trainedOnly: false,
      armorCheckPenalty: false,
      abilityScore: 'Intelligence',
      ...emptySkill(),
    },
    {
      name: 'Craft2',
      trainedOnly: false,
      armorCheckPenalty: false,
      abilityScore: 'Intelligence',
      ...emptySkill(),
    },
    {
      name: 'Craft3',
      trainedOnly: false,
      armorCheckPenalty: false,
      abilityScore: 'Intelligence',
      ...emptySkill(),
    },
    {
      name: 'Diplomacy',
      trainedOnly: false,
      armorCheckPenalty: false,
      abilityScore: 'Charisma',
      ...emptySkill(),
    },
    {
      name: 'Disable Device',
      trainedOnly: true,
      armorCheckPenalty: true,
      abilityScore: 'Dexterity',
      ...emptySkill(),
    },
    {
      name: 'Disguise',
      trainedOnly: false,
      armorCheckPenalty: false,
      abilityScore: 'Charisma',
      ...emptySkill(),
    },
    {
      name: 'Escape Artist',
      trainedOnly: false,
      armorCheckPenalty: true,
      abilityScore: 'Dexterity',
      ...emptySkill(),
    },
    {
      name: 'Fly',
      trainedOnly: false,
      armorCheckPenalty: true,
      abilityScore: 'Dexterity',
      ...emptySkill(),
    },
    {
      name: 'Handle Animal',
      trainedOnly: true,
      armorCheckPenalty: false,
      abilityScore: 'Charisma',
      ...emptySkill(),
    },
    {
      name: 'Heal',
      trainedOnly: false,
      armorCheckPenalty: false,
      abilityScore: 'Wisdom',
      ...emptySkill(),
    },
    {
      name: 'Intimidate',
      trainedOnly: false,
      armorCheckPenalty: false,
      abilityScore: 'Charisma',
      ...emptySkill(),
    },
    {
      name: 'K (Arcana)',
      trainedOnly: true,
      armorCheckPenalty: false,
      abilityScore: 'Intelligence',
      ...emptySkill(),
    },
    {
      name: 'K (Dungeoneering)',
      trainedOnly: true,
      armorCheckPenalty: false,
      abilityScore: 'Intelligence',
      ...emptySkill(),
    },
    {
      name: 'K (Engineering)',
      trainedOnly: true,
      armorCheckPenalty: false,
      abilityScore: 'Intelligence',
      ...emptySkill(),
    },
    {
      name: 'K (Geography)',
      trainedOnly: true,
      armorCheckPenalty: false,
      abilityScore: 'Intelligence',
      ...emptySkill(),
    },
    {
      name: 'K (History)',
      trainedOnly: true,
      armorCheckPenalty: false,
      abilityScore: 'Intelligence',
      ...emptySkill(),
    },
    {
      name: 'K (Local)',
      trainedOnly: true,
      armorCheckPenalty: false,
      abilityScore: 'Intelligence',
      ...emptySkill(),
    },
    {
      name: 'K (Nature)',
      trainedOnly: true,
      armorCheckPenalty: false,
      abilityScore: 'Intelligence',
      ...emptySkill(),
    },
    {
      name: 'K (Nobility)',
      trainedOnly: true,
      armorCheckPenalty: false,
      abilityScore: 'Intelligence',
      ...emptySkill(),
    },
    {
      name: 'K (Planes)',
      trainedOnly: true,
      armorCheckPenalty: false,
      abilityScore: 'Intelligence',
      ...emptySkill(),
    },
    {
      name: 'K (Religion)',
      trainedOnly: true,
      armorCheckPenalty: false,
      abilityScore: 'Intelligence',
      ...emptySkill(),
    },
    {
      name: 'Linguistics',
      trainedOnly: true,
      armorCheckPenalty: false,
      abilityScore: 'Intelligence',
      ...emptySkill(),
    },
    {
      name: 'Perception',
      trainedOnly: false,
      armorCheckPenalty: false,
      abilityScore: 'Wisdom',
      ...emptySkill(),
    },
    {
      name: 'Perform1',
      trainedOnly: false,
      armorCheckPenalty: false,
      abilityScore: 'Charisma',
      ...emptySkill(),
    },
    {
      name: 'Perform2',
      trainedOnly: false,
      armorCheckPenalty: false,
      abilityScore: 'Charisma',
      ...emptySkill(),
    },
    {
      name: 'Profession1',
      trainedOnly: true,
      armorCheckPenalty: false,
      abilityScore: 'Wisdom',
      ...emptySkill(),
    },
    {
      name: 'Profession2',
      trainedOnly: true,
      armorCheckPenalty: false,
      abilityScore: 'Wisdom',
      ...emptySkill(),
    },
    {
      name: 'Ride',
      trainedOnly: false,
      armorCheckPenalty: false,
      abilityScore: 'Dexterity',
      ...emptySkill(),
    },
    {
      name: 'Sense Motive',
      trainedOnly: false,
      armorCheckPenalty: false,
      abilityScore: 'Wisdom',
      ...emptySkill(),
    },
    {
      name: 'Sleight of Hand',
      trainedOnly: true,
      armorCheckPenalty: true,
      abilityScore: 'Dexterity',
      ...emptySkill(),
    },
    {
      name: 'Spellcraft',
      trainedOnly: true,
      armorCheckPenalty: false,
      abilityScore: 'Intelligence',
      ...emptySkill(),
    },
    {
      name: 'Stealth',
      trainedOnly: false,
      armorCheckPenalty: true,
      abilityScore: 'Dexterity',
      ...emptySkill(),
    },
    {
      name: 'Survival',
      trainedOnly: false,
      armorCheckPenalty: false,
      abilityScore: 'Wisdom',
      ...emptySkill(),
    },
    {
      name: 'Swim',
      trainedOnly: false,
      armorCheckPenalty: true,
      abilityScore: 'Strength',
      ...emptySkill(),
    },
    {
      name: 'Use Magic Device',
      trainedOnly: true,
      armorCheckPenalty: false,
      abilityScore: 'Charisma',
      ...emptySkill(),
    },
  ],
  userAddedSkills: {},
  characterFeats: {},
  userAddedFeats: {},
  characterTraits: {},
  attacks: [],
  inventory: {},
  equipment: {
    Head: {
      name: 'Head',
      ...emptyEquipmentSlot(),
    },
    Headband: {
      name: 'Headband',
      ...emptyEquipmentSlot(),
    },
    Eyes: {
      name: 'Eyes',
      ...emptyEquipmentSlot(),
    },
    Shoulders: {
      name: 'Shoulders',
      ...emptyEquipmentSlot(),
    },
    Neck: {
      name: 'Neck',
      ...emptyEquipmentSlot(),
    },
    Chest: {
      name: 'Chest',
      ...emptyEquipmentSlot(),
    },
    Body: {
      name: 'Body',
      ...emptyEquipmentSlot(),
    },
    Armor: {
      name: 'Armor',
      ...emptyEquipmentSlot(),
    },
    Belt: {
      name: 'Belt',
      ...emptyEquipmentSlot(),
    },
    Wrists: {
      name: 'Wrists',
      ...emptyEquipmentSlot(),
    },
    'Left Hand': {
      name: 'Left Hand',
      ...emptyEquipmentSlot(),
    },
    'Right Hand': {
      name: 'Right Hand',
      ...emptyEquipmentSlot(),
    },
    'Left Ring': {
      name: 'Left Ring',
      ...emptyEquipmentSlot(),
    },
    'Right Ring': {
      name: 'Right Ring',
      ...emptyEquipmentSlot(),
    },
    Feet: {
      name: 'Feet',
      ...emptyEquipmentSlot(),
    },
  },
  wealth: {
    platinum: 0,
    gold: 0,
    silver: 0,
    copper: 0,
  },
  modifiers: {},
  knownLanguages: {},
  userAddedLanguages: {},
  characterConditions: [],
  effects: {},
  notes: '',
  rest: {
    hoursOfRest: 0,
    useCon: false,
    longTermCare: false,
    refreshClassSpells: [],
  },
  healHarm: {
    amount: 0,
  },
  lastSave: '',
  diceCheckLog: [],
});
