<template>
  <div class="feat-user-added-fields fade-in">
    <div class="feat-user-added-fields-content">
      <div class="user-added-feat-chooser">
        <SectionDivider
          :title="formatFeatName(userFeat)"
          dividerClass="my-4"
        />
        <b-form-row>
          <b-col class="col-12 col-sm-6">
            <VueSelectField
              :id="`feat-user-added-selector-${userFeat.id}`"
              label="Feat Name"
              v-model="userFeat.selection"
              :options="feats"
              taggable
              textField="name"
              valueField="id"
              placeholder="- Select / Enter Feat Name -"
              @handleVueSelectFieldChange="setFeatData(userFeat)"
            />
          </b-col>
          <b-col class="col-11 col-sm-5">
            <InputField
              :id="`feat-user-added-source-${userFeat.id}`"
              label="Feat Source"
              placeholder="- Enter Feat Source -"
              desc="What is this feat from?"
              v-model="userFeat.source"
            />
          </b-col>
          <b-col class="col-1 d-flex justify-content-center align-items-center">
            <Button
              size="sm"
              :btnClass="`remove-user-feat-btn-${userFeat.id}`"
              label="Remove This Feat"
              :showLabel="false"
              @handleButtonClick="removeFieldAndModifiers({ list: 'userAddedFeats', value: userFeat })"
              v-b-popover.hover.focus.blur.top="'Remove This Feat'"
            >
              <FontAwesomeIcon :icon="removeIcon" />
            </Button>
          </b-col>
          <b-col class="col-12 col-sm-6">
            <MultiSelectField
              :id="`feat-user-added-type-${userFeat.id}`"
              label="Feat Type(s)"
              :options="featTypes"
              textField="name"
              valueField="name"
              v-model="userFeat.types"
              placeholder="- Select / Enter Feat Type(s) -"
            />
          </b-col>
          <b-col class="col-12 col-sm-4">
            <InputField
              :id="`feat-user-added-prereqs-${userFeat.id}`"
              label="Prerequisites"
              placeholder="- Enter Prerequisites -"
              v-model="userFeat.prerequisites"
            />
          </b-col>
          <b-col class="col-12 col-sm-2">
            <InputField
              :id="`feat-user-added-level-${userFeat.id}`"
              label="Level Gained"
              type="number"
              min="1"
              max="20"
              placeholder="- Enter Level Gained -"
              v-model="userFeat.levelGained"
            />
          </b-col>
          <b-col class="col-12">
            <TextAreaField
              :id="`feat-user-added-benefit-${userFeat.id}`"
              label="Benefit"
              rows="5"
              v-model="userFeat.benefit"
            />
          </b-col>
          <b-col>
            <ModifierPanel
              :parentElement="userFeat"
              parentList="userAddedFeats"
              :addModifierButtonLabel="`+ Add Modifier to ${userFeat.selection ? userFeat.selection : 'Custom Feat'}`"
            />
          </b-col>
        </b-form-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import InputField from '@/components/form/InputField.vue';
import VueSelectField from '@/components/form/VueSelectField.vue';
import MultiSelectField from '@/components/form/MultiSelectField.vue';
import TextAreaField from '@/components/form/TextAreaField.vue';
import Button from '@/components/form/Button.vue';
import SectionDivider from '@/components/SectionDivider.vue';
import ModifierPanel from '@/components/ModifierPanel.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { formatFeatName } from '../utils/utils';

export default {
  name: 'FeatUserAddedFields',
  created() {
    if (!this.feats.length) {
      this.$store.dispatch('fetchList', { urlPath: 'feats?all=true', targetList: 'feats' });
    }
  },
  components: {
    InputField,
    VueSelectField,
    MultiSelectField,
    TextAreaField,
    Button,
    SectionDivider,
    ModifierPanel,
    FontAwesomeIcon,
  },
  data() {
    return {
      removeIcon: faMinus,
    };
  },
  props: {
    userFeat: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState([
      'feats',
      'featTypes',
    ]),
  },
  methods: {
    formatFeatName,
    ...mapActions('characterSheet', [
      'removeFieldAndModifiers',
    ]),
    setFeatData(userEnteredFeat) {
      this.$store.dispatch('fetchListItem', { value: userEnteredFeat?.selection || '', listToSearch: 'feats', urlPath: 'feats' })
        .then((response) => {
          if (response && !!response.length) {
            this.$store.commit('characterSheet/updateUserFeat', { userEnteredFeat: response[0], featToUpdate: userEnteredFeat });
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
  div.anvil-button {
    margin-bottom: 0.5rem;
  }
</style>
