<template>
  <div class="inventory-item-fields">
    <b-form-row>
      <b-col class="col-3 col-md-2 col-lg-1">
        <InputField
          id="inventory-item-quantity"
          label="Qty"
          type="number"
          v-model="item.quantity"
        />
      </b-col>
      <b-col class="col-9 col-md-4 col-lg-5 col-xl-2">
        <VueSelectField
          id="inventory-item-category"
          label="Category"
          :options="itemTypes"
          taggable
          v-model="item.category"
          placeholder="- Select / Enter Category -"
          @handleVueSelectFieldChange="loadItemsOfType"
        />
      </b-col>
      <b-col class="col-6 col-md-6 col-xl-3">
        <VueSelectField
          id="inventory-item-name"
          label="Item Name"
          :options="itemOptions"
          textField="name"
          valueField="name"
          v-model="item.name"
          taggable
          placeholder="- Select / Enter Item Name -"
          @handleVueSelectFieldChange="loadItemData"
        />
      </b-col>
      <b-col class="col-6 col-md-3 col-lg-6 col-xl-3">
        <VueSelectField
          id="inventory-item-slot"
          label="Equipment Slot (Optional)"
          :options="equipmentSlots"
          v-model="item.slot"
          taggable
          placeholder="- Select Slot (Optional) -"
        />
      </b-col>
      <b-col class="col-4 col-md-3 col-lg-2 col-xl-1">
        <InputField
          id="inventory-item-weight"
          label="Weight (lb)"
          type="number"
          v-model="item.weight"
        />
      </b-col>
      <b-col class="col-4 col-md-3 col-lg-2 col-xl-1">
        <InputField
          id="inventory-item-price"
          label="Cost"
          type="number"
          v-model="item.cost"
        />
      </b-col>
      <b-col class="col-4 col-md-3 col-lg-2 col-xl-1">
        <VueSelectField
          id="inventory-item-denomination"
          label="Denomination"
          taggable
          :options="Object.keys(currencyDenominations)"
          v-model="item.denomination"
        />
      </b-col>
    </b-form-row>
    <ModifierPanel
      :parentElement="item"
      parentList="inventory"
      :addModifierButtonLabel="`+ Add Modifier to ${item.name ? item.name : 'Inventory Item'}`"
    />
    <b-form-row>
      <b-col class="col-12 col-md-4">
        <ButtonGroup
          id="inventory-item-quality"
          v-model="item.quality"
          label="Item Quality"
          :options="[
            {
              text: 'Mundane',
              value: 'Mundane',
            },
            {
              text: 'Masterwork',
              value: 'Masterwork',
            },
            {
              text: 'Magic',
              value: 'Magic',
            },
          ]"
        />
      </b-col>
      <b-col v-if="item.quality === 'Magic'" class="col-12 col-sm-4 col-md-3">
        <InputField
          id="inventory-item-aura"
          label="Aura"
          v-model="item.aura"
        />
      </b-col>
      <b-col v-if="item.quality === 'Magic'" class="col-6 col-sm-4 col-md-2">
        <InputField
          id="inventory-item-enhancement-bonus"
          label="Enhancement Bonus"
          type="number"
          min=0
          max=5
          v-model="item.enhancementBonus"
        />
      </b-col>
      <b-col v-if="item.quality === 'Magic'" class="col-6 col-sm-4 col-md-3">
        <InputField
          id="inventory-item-caster-level"
          label="Caster Level"
          type="number"
          min=0
          v-model="item.casterLevel"
        />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col class='col-12'>
        <TextAreaField
          label="Description"
          rows="5"
          v-model="item.description"
        />
      </b-col>
      <b-col class='col-12' v-if="Object.keys(item.stats).length">
        <div><u>Item Info (Default)</u></div>
        <ItemStatsDisplayWeapon v-if="item.type === 'weapon'" :weaponStats="item.stats" />
        <ItemStatsDisplayArmor v-if="item.type === 'armor'" :armorStats="item.stats" />
        <ItemStatsDisplay v-if="item.type !== 'weapon' && item.type !== 'armor'" :itemStats="item.stats" />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import InputField from '@/components/form/InputField.vue';
import VueSelectField from '@/components/form/VueSelectField.vue';
import ButtonGroup from '@/components/form/ButtonGroup.vue';
import TextAreaField from '@/components/form/TextAreaField.vue';
import ModifierPanel from '@/components/ModifierPanel.vue';
import ItemStatsDisplayWeapon from '@/components/ItemStatsDisplayWeapon.vue';
import ItemStatsDisplayArmor from '@/components/ItemStatsDisplayArmor.vue';
import ItemStatsDisplay from '@/components/ItemStatsDisplay.vue';
import { stringToCamel, capIt } from '../utils/utils';

export default {
  name: 'InventoryItemFields',
  components: {
    InputField,
    VueSelectField,
    ButtonGroup,
    TextAreaField,
    ModifierPanel,
    ItemStatsDisplayWeapon,
    ItemStatsDisplayArmor,
    ItemStatsDisplay,
  },
  data() {
    return {
      selectedItemType: '',
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState([
      'itemTypes',
      'equipmentSlots',
      'currencyDenominations',
      'simpleWeapons',
      'martialWeapons',
      'exoticWeapons',
      'ammunition',
      'lightArmor',
      'mediumArmor',
      'heavyArmor',
      'shields',
      'armorExtras',
      'adventuringGear',
      'specialSubstances',
      'toolsAndSkillKits',
      'clothing',
      'mountsAndRelatedGear',
      'transport',
    ]),
    itemOptions() {
      return this[this.selectedItemType];
    },
  },
  methods: {
    capIt,
    loadItemsOfType(value) {
      if (value && value.length) {
        this.selectedItemType = stringToCamel(value);
        this.$store.dispatch('fetchList', { urlPath: this.selectedItemType, targetList: this.selectedItemType });
      }
    },
    loadItemData(value) {
      if (this.itemTypes.map((type) => stringToCamel(type)).indexOf(this.selectedItemType) > -1) {
        this.$store.dispatch('fetchUrlPath', `${this.selectedItemType}/${value}`)
          .then((response) => {
            if (response.length > 0) {
              this.$store.commit('characterSheet/updateInventoryItem', { selectedInventoryItem: response[0], inventoryItemToUpdate: this.item });
            }
          });
      }
    },
  },
};
</script>
