<template>
  <div class="spin-button spin-button-field">
    <label :for="`${id}-spin-button`" v-if="showLabel">{{ label }}</label>
    <b-form-spinbutton
      :id="`${id}-spin-button`"
      v-model="inputValue"
      :size="size"
      :min="min"
      :max="max"
      :class="`${spinButtonClass} spin-button-inner`"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      @change="handleSpinButtonChange"
    />
  </div>
</template>

<script>
export default {
  name: 'SpinButton',
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  props: {
    id: String,
    value: [String, Number],
    size: {
      type: String,
      default: 'sm',
    },
    spinButtonClass: {
      type: String,
      default: '',
    },
    label: String,
    showLabel: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [String, Number],
      default: -100,
    },
    max: [String, Number],
  },
  methods: {
    handleSpinButtonChange(spunValue) {
      this.$emit('handleSpinButtonChange', spunValue);
    },
  },
};
</script>

<style lang="scss">
.spin-button-field {
  margin: 0 !important;
  .b-form-spinbutton {
    background: transparent;
    color: revert;
    button {
      @include hoverFocusStyle();
      @include flexRowCenter();
      div {
        line-height: 1;
        display: flex;
      }
    }
    output {
      border: 0 !important;
      @include hoverFocusStyle();
    }
  }
  .b-form-spinbutton {
    border-radius: 0.25rem;
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: $text-large;
    font-weight: $thin-text;
    height: 28px;
  }
  @media screen and (max-width: 576px) {
    .b-form-spinbutton {
      font-size: $text-standard;
      button {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }
    }
  }
}
</style>
