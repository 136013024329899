<template>
  <div class="ability-scores-panel my-3"
    v-b-modal.abilityScoresModal
  >
    <div class="ability-scores-container">
      <AbilityScore scoreName="Strength" />
      <AbilityScore scoreName="Dexterity" />
      <AbilityScore scoreName="Constitution" />
      <AbilityScore scoreName="Intelligence" />
      <AbilityScore scoreName="Wisdom" />
      <AbilityScore scoreName="Charisma" />
    </div>
    <Modal
      modalTitle="Edit Ability Scores"
      modalSize="xl"
      modalId="abilityScoresModal"
      okOnly
    >
      <AbilityScoreFields/>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue';
import AbilityScoreFields from '@/components/AbilityScoreFields.vue';
import AbilityScore from '@/components/AbilityScore.vue';

export default {
  name: 'AbilityScoresPanel',
  components: {
    Modal,
    AbilityScoreFields,
    AbilityScore,
  },
};
</script>

<style scoped lang="scss">
.ability-scores-panel {
  padding-bottom: 1.5rem;
  @include hoverFocusStyle();
  .ability-scores-container {
    display: flex;
    justify-content: space-between;
  }
}
</style>
