<template>
  <div class="class-builder fade-in">
    <b-form-row>
      <b-col class="col-12">
        <SectionDivider
          :title="charClass.classType"
        />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col class="col-12 col-sm-4 col-lg-5 col-xl-2">
        <VueSelectField
          :id="'characterClass'+charClass.id"
          label="Class Name"
          :options="getAvailableClasses"
          v-model="charClass.name"
          textField="name"
          valueField="name"
          taggable
          :placeholder="`- ${charClass.classType} -`"
          @handleVueSelectFieldChange="setClassData"
        />
      </b-col>
      <b-col class="col-12 col-sm-4 col-lg-5 col-xl-2">
        <VueSelectField
          :id="'classArchetype'+charClass.id"
          label="Archetype"
          :options="[]"
          taggable
          :value="charClass.classArchetype"
          placeholder="- COMING SOON -"
        />
      </b-col>
      <b-col class="col-6 col-sm-4 col-lg-2 col-xl-1">
        <SpinButton
          :id="'characterClass'+charClass.id+'Levels'"
          label="Levels"
          min=1
          v-model="charClass.classLevels"
          @handleSpinButtonChange="setClassLevel"
        />
      </b-col>
      <b-col class="col-6 col-sm-3 col-lg-3 col-xl-1">
        <VueSelectField
          :id="'characterClass'+charClass.id+'HitDie'"
          label="Hit Die"
          v-model="charClass.hitDie"
          :options="hitDice"
          placeholder="- HD -"
          @handleVueSelectFieldChange="setStartingHitPoints"
        />
      </b-col>
      <b-col class="col-12 col-sm-3 col-lg-3 col-xl-2">
        <VueSelectField
          :id="'characterClass'+charClass.id+'BaB'"
          label="BaB"
          v-model="charClass.BaB"
          :options="babSpeeds"
          placeholder="- BaB -"
        />
      </b-col>
      <b-col class="col-6 col-sm-3 col-lg-3 col-xl-2">
        <VueSelectField
          :id="'characterClass'+charClass.id+'Saves'"
          label="Saving Throws"
          v-model="charClass.savingThrows"
          :options="saveSpeeds"
          placeholder="- Saves -"
          desc="(Fort/Ref/Will) | S = Slow, F = Fast"
        />
      </b-col>
      <b-col class="col-6 col-sm-3 col-lg-3 col-xl-2">
        <VueSelectField
          :id="'characterClass'+charClass.id+'SkillPointsPerLevel'"
          label="Skill Pts / Lvl"
          v-model="charClass.skillPointsPerLevel"
          :options="skillPointsPerLevel"
          placeholder="- SPPL -"
        />
      </b-col>
      <b-col v-if="charClass.name && charClass.classLevels" class="col-4 col-sm-3 col-md-2">
        <Button
          :id="'favored-class-'+charClass.id"
          size="sm"
          :btnClass="`favored-class-btn${charClass.isFavored ? ' active' : ''}`"
          label="Favored"
          :disabled="checkFavoredClasses()"
          @handleButtonClick="setFavoredClass"
        >
          Favored
        </Button>
      </b-col>
      <b-col v-if="charClass.isFavored && !checkFavoredClasses()" class="col-8 col-sm-9 col-md-10">
        <RangeSlider
          :min="+0"
          :max="+charClass.classLevels"
          :label="`HP (${charClass.favHp}) | SP (${charClass.favSp})`"
          v-model="charClass.favSp"
          @handleRangeSliderInput="adjustFavHpSp"
        />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SpinButton from '@/components/form/SpinButton.vue';
import VueSelectField from '@/components/form/VueSelectField.vue';
import SectionDivider from '@/components/SectionDivider.vue';
import Button from '@/components/form/Button.vue';
import RangeSlider from '@/components/form/RangeSlider.vue';
import { getDieSidesFromHitDieString } from '../utils/diceUtils';

export default {
  name: 'ClassBuilder',
  created() {
    this.$store.dispatch('fetchList', { urlPath: this.classListToFetch, targetList: 'classes' });
  },
  components: {
    SpinButton,
    VueSelectField,
    SectionDivider,
    Button,
    RangeSlider,
  },
  props: {
    charClass: Object,
  },
  computed: {
    ...mapState([
      'isAuthenticated',
      'isSupporter',
      'classes',
      'hitDice',
      'babSpeeds',
      'saveSpeeds',
      'skillPointsPerLevel',
    ]),
    ...mapState('characterSheet', [
      'raceName',
    ]),
    ...mapGetters('characterSheet', [
      'getAvailableClasses',
      'getFavoredClasses',
    ]),
    classListToFetch() {
      return (this.isAuthenticated && this.isSupporter) ? 'allClasses' : 'classes';
    },
  },
  methods: {
    setClassData(value) {
      this.$store.dispatch('fetchListItem', { value, listToSearch: 'classes', urlPath: this.classListToFetch })
        .then((response) => {
          if (response && !!response.length) {
            this.$store.dispatch('characterSheet/setClass', { selectedClass: response[0], classToPopulate: this.charClass });
          } else if (!value) {
            this.$store.dispatch('characterSheet/removeClass', this.charClass);
          }
        });
    },
    setClassLevel(levels) {
      this.$store.dispatch('characterSheet/updateClass', { classToUpdate: this.charClass, levels });
    },
    setFavoredClass() {
      this.$store.commit('characterSheet/updateFavoredClass', { classToUpdate: this.charClass });
    },
    adjustFavHpSp() {
      this.$store.commit('characterSheet/adjustFavoredHpSp', { classToAdjust: this.charClass });
    },
    checkFavoredClasses() {
      if (this.getFavoredClasses.length > 0) {
        if (this.getFavoredClasses.length >= (this.raceName === 'Half-Elf' ? 2 : 1) && !this.getFavoredClasses.includes(this.charClass.name)) {
          return true;
        }
        return false;
      }
      return false;
    },
    setStartingHitPoints() {
      if (this.charClass.id === 0) {
        this.$store.commit('characterSheet/saveHitPointRoll', { charClassId: 0, level: 0, result: getDieSidesFromHitDieString(this.charClass.hitDie) });
      }
    },
  },
};
</script>
