<template>
  <div class="racial-traits-panel my-1" v-if="raceName && raceName.length > 0">
    <b-form-row>
      <b-col class="racial-traits-title-flex">
          <div class="racial-traits-title-wrapper" v-b-modal="'editRacialTraitsModal'">
          <div class="racial-traits-title">{{ raceName }}</div>
          </div>
      </b-col>
      <b-col class="">
          <div class="racial-traits-wrapper">
          <div v-for="(racialTrait, index) in racialTraits" :key="index">
            <span
              :title="racialTrait.name" v-b-popover.hover.click.focus.blur.top="racialTrait.Description">{{ racialTrait.name }}
            </span>
          </div>
          </div>
      </b-col>
    </b-form-row>
    <Modal
    :modalTitle="`Edit Racial Traits - ${raceName}`"
    :modalId="'editRacialTraitsModal'"
    modalClass="edit-racial-traits-modal"
    modalSize="xl"
    okOnly
    isScrollable
  >
    <Button
      alt="Add Racial Trait"
      size="sm"
      btnClass="add-racial-trait-btn"
      centered
      @handleButtonClick="addRacialTrait()"
    >
      + Add Racial Trait
    </Button>
    <Collapse
      v-for="racialTrait in racialTraits"
      :key="`racial-trait-${racialTrait.id}`"
      :item="racialTrait"
      @handleDeleteCollapseItem="deleteField({ list: 'racialTraits', id: racialTrait.id })"
    >
      <RacialTraitFields
        :trait="racialTrait"
      />
    </Collapse>
    <Button
      v-b-popover.hover.focus.blur.top="setRevertButtonPopoverText()"
      size="sm"
      id="revert-default-racial-traits-btn"
      btnClass="revert-default-racial-traits-btn"
      centered
      @handleButtonClick="confirmRevertRacialTraits"
    >
      {{ confirmRevert ? 'Confirm?' : 'Reset Defaults' }}
    </Button>
  </Modal>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapMutations } from 'vuex';
import Modal from '@/components/Modal.vue';
import Button from '@/components/form/Button.vue';
import RacialTraitFields from '@/components/RacialTraitFields.vue';
import Collapse from '@/components/Collapse.vue';

export default {
  name: 'RacialTraitsPanel',
  components: {
    Modal,
    Button,
    RacialTraitFields,
    Collapse,
  },
  data() {
    return {
      confirmRevert: false,
    };
  },
  computed: {
    ...mapFields('characterSheet', [
      'raceName',
      'racialTraits',
    ]),
  },
  methods: {
    ...mapMutations('characterSheet', [
      'addRacialTrait',
      'deleteField',
    ]),
    confirmRevertRacialTraits() {
      this.confirmRevert = !this.confirmRevert;
      if (!this.confirmRevert) {
        this.$store.dispatch('characterSheet/revertToDefaultRacialTraits');
      }
    },
    setRevertButtonPopoverText() {
      return this.confirmRevert ? 'Are you sure? This will delete any custom features you have added.' : `Revert to Default ${this.raceName} Racial Traits`;
    },
  },
};
</script>

<style lang="scss">
.racial-traits-panel {
  .racial-traits-title-flex {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    .racial-traits-title-wrapper {
      height: 100%;
      min-height: 100px;
      min-width: 25px;
      max-width: 25px;
      @include flexColumnCenter();
      @include hoverFocusStyle();
      margin-right: 10px;
      .racial-traits-title {
        transform: rotate(270deg);
        text-align: center;
        white-space: nowrap;
      }
    }
  }
}
.add-racial-trait-btn,
.revert-default-racial-traits-btn {
  margin-right: 32px;
}
#revert-default-racial-traits-btngroup {
  margin-top: 30px;
}
.trait-dropdown {
  button.dropdown-toggle {
    @include squareButton();
  }
  .dropdown-menu {
    font-size: $text-smaller;
    box-shadow: 0 0 8px $navbar-shadow;
  }
}
</style>
