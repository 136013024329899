<template>
  <div class="traits fade-in">
    <div class="traits-content">
      <b-form-row>
        <b-col class="col-12">
          <div class="traits-subpanel">
            <Button
              alt="Add Trait"
              size="sm"
              btnClass="add-trait-btn"
              @handleButtonClick="handleAddTraitButtonClick"
            >
              + Add Trait
            </Button>
            <Trait
              v-for="(trait, index) in characterTraits"
              :key="'trait-'+index"
              :trait="trait"
            />
          </div>
        </b-col>
      </b-form-row>
    </div>
  </div>
</template>

<script>
import { mapMultiRowFields } from 'vuex-map-fields';
import Trait from '@/components/Trait.vue';
import Button from '@/components/form/Button.vue';

export default {
  name: 'TraitsPanel',
  components: {
    Trait,
    Button,
  },
  computed: {
    ...mapMultiRowFields('characterSheet', [
      'characterTraits',
    ]),
  },
  methods: {
    handleAddTraitButtonClick() {
      this.$store.commit('characterSheet/addTrait');
      const addedTraitId = this.characterTraits[this.characterTraits.length - 1].id;
      this.$nextTick(() => {
        this.$bvModal.show(`editTraitModal${addedTraitId}`);
      });
    },
  },
};
</script>
