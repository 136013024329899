<template>
  <div class="error-bar fade-in mb-2">
    {{ error }} <FontAwesomeIcon :icon="closeIcon" @click="closeMe" class="close-error" />
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'ErrorBar',
  data() {
    return {
      closeIcon: faTimes,
    };
  },
  components: {
    FontAwesomeIcon,
  },
  props: {
    error: String,
    isCloseable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    closeMe() {
      this.$emit('closeMe');
    },
  },
};
</script>

<style scoped lang="scss">
.error-bar {
  display: block;
  font-size: 0.875rem;
  width: 100%;
  padding: 7px 14px;
  border-radius: 3px;
  .close-error {
    float: right;
    cursor: pointer;
    margin-top: 3px;
  }
}
</style>
