<template>
  <div class="stat-modifiers-string-display fade-in">
    <ModifierDisplay
      :modNumber="statTotal"
      :modText="statTotalText"
    />
    <FontAwesomeIcon :icon="equalsIcon" />
    <div
      class="mod-display"
      v-for="(modItem, index) in statStringArray"
      :key="index"
    >
      <div v-if="index !== 0">
        <FontAwesomeIcon :icon="plusIcon" />
      </div>
      <ModifierDisplay
        :modNumber="modItem.value"
        :modText="modItem.name"
      />
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEquals, faPlus } from '@fortawesome/free-solid-svg-icons';
import ModifierDisplay from '@/components/ModifierDisplay.vue';

export default {
  name: 'StatModifiersStringDisplay',
  components: {
    FontAwesomeIcon,
    ModifierDisplay,
  },
  data() {
    return {
      equalsIcon: faEquals,
      plusIcon: faPlus,
    };
  },
  props: {
    statTotal: {
      type: [Number, String],
      default: 0,
    },
    statTotalText: {
      type: String,
      default: 'Total',
    },
    statStringArray: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.stat-modifiers-string-display {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 2rem 0;
  .mod-display {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
