<template>
  <div class="feats fade-in">
    <div class="feats-content" v-b-modal.featsModal>
      <SectionDivider
        title="Standard Feats"
        dividerClass="mb-4"
      />
      <div v-for="(feat, key) in characterFeats" :key="`character-feat-${key}`">
        <div class="feat-label">{{ formatFeatName(feat) }}</div>
        <div class="py-1">{{ feat.selection || '- Select Feat -' }}</div>
      </div>
    </div>
    <div class="feats-user-added-content" v-b-modal.featsUserAddedModal>
      <SectionDivider
        title="Custom Feats"
        dividerClass="mb-4"
      />
      <div v-for="(userFeat, key) in userAddedFeats" :key="`user-added-feat-${key}`">
        <div class="feat-label">{{ formatFeatName(userFeat) }}</div>
        <div class="py-1">{{ userFeat.selection || '- Select Feat -' }}</div>
      </div>
    </div>
    <div v-if="Object.keys(characterFeats).length < 1 && Object.keys(userAddedFeats).length < 1">
      You don't have any feats. Select a race and/or class(es) to be granted some, or click on this panel to add your own.
    </div>
    <Modal
      modalTitle="Standard (Class, Race, and Level-Based) Feats"
      modalSize="xl"
      modalId="featsModal"
      okOnly
    >
      <FeatFields
        v-for="(feat, key) in characterFeats"
        :key="`character-feat-${key}`"
        :feat="feat"
      />
    </Modal>
    <Modal
      modalTitle="Custom (User-Added) Feats"
      modalSize="xl"
      modalId="featsUserAddedModal"
      okOnly
    >
      <FeatUserAddedPanel />
    </Modal>
  </div>
</template>

<script>
import { mapMultiRowFields } from 'vuex-map-fields';
import Modal from '@/components/Modal.vue';
import SectionDivider from '@/components/SectionDivider.vue';
import FeatFields from '@/components/FeatFields.vue';
import FeatUserAddedPanel from '@/components/FeatUserAddedPanel.vue';
import { formatFeatName } from '../utils/utils';

export default {
  name: 'FeatsPanel',
  components: {
    Modal,
    SectionDivider,
    FeatFields,
    FeatUserAddedPanel,
  },
  computed: {
    ...mapMultiRowFields('characterSheet', [
      'characterFeats',
      'userAddedFeats',
    ]),
  },
  methods: {
    formatFeatName,
  },
};
</script>

<style scoped lang="scss">
.feats {
  .feats-content,
  .feats-user-added-content {
    @include hoverFocusStyle();
    .feat-label {
      font-size: $text-small;
      width: 100%;
    }
  }
}
</style>
