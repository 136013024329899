<template>
  <div>
    <b-form-row>
      <div class="skill-name-text">
        {{ skillNameWithIndicators(skill) }}
      </div>
      <div v-if="skill.subskill">
        - {{ skill.subskill }}
      </div>
    </b-form-row>
    <b-form-row class="skill-row">
      <b-col class="col-6 col-sm-2 class-skill mb-3">
        <Button
          :id="skill.name+'-class-btn'"
          size="sm"
          :btnClass="`class-skill-btn ${setButtonClass()}`"
          square
          :disabled="isClassSkillButtonDisabled()"
          label="Class Skill"
          @handleButtonClick="toggleUserSelectedClassSkill(skill.name)"
        >
          <FontAwesomeIcon :icon="checkIcon" v-if="isClassSkill()" />
        </Button>
        <div class="skill-amount class-skill-bonus" v-if="isClassSkill() && skill.ranks > 0">
          +3
        </div>
      </b-col>
      <b-col class="col-6 col-sm-2 skill-ranks-stepper">
        <SpinButton
          :id="'skillRanks'+skill.name"
          label="Ranks"
          size="sm"
          spinButtonClass="skill-ranks"
          min=0
          :max="getTotalClassLevels ? getTotalClassLevels : 0"
          @handleSpinButtonChange="updateSkill({ skill, ranks: $event })"
          :value="skill.ranks"
        />
      </b-col>
      <b-col class="col-2 col-sm-2 skill-ability-score mb-3">
        <label class="skill-label">{{ abilAbbrev(skill.abilityScore) }}</label>
        <div class="skill-amount">{{ getAbilityModifier(skill.abilityScore)}}</div>
      </b-col>
      <b-col class="col-6 col-sm-2 skill-optional-modifiers mb-3">
        <div class="skill-armor-check-penalty" v-if="skill.armorCheckPenalty">
          <label class="skill-label">Armor Check Penalty</label>
          <div class="skill-amount">{{ getArmorCheckPenalty }}</div>
        </div>
      </b-col>
      <b-col class="col-4 col-sm-2">
        <div class="skill-misc-modifier" v-for="(skillMod, index) in filterModifiersByCategory(skill.name)" :key="index">
          <label class="skill-label">{{ skillMod.name }}</label>
          <div class="skill-amount">{{ plusMinus(skillMod.amount) }}</div>
        </div>
      </b-col>
      <b-col class="col-12 col-sm-2 skill-total">
        <div class="skill-total-amount">{{ plusMinus(getSkillTotal(skill.name)) }}</div>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import SpinButton from '@/components/form/SpinButton.vue';
import Button from '@/components/form/Button.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { plusMinus, abilAbbrev, skillNameWithIndicators } from '../utils/utils';

const { mapFields } = createHelpers({
  getterType: 'characterSheet/getField',
  mutationType: 'characterSheet/updateField',
});

export default {
  name: 'SkillRow',
  components: {
    SpinButton,
    Button,
    FontAwesomeIcon,
  },
  data() {
    return {
      checkIcon: faCheck,
    };
  },
  props: {
    skill: Object,
  },
  computed: {
    ...mapFields([
      'userSelectedClassSkills',
    ]),
    ...mapGetters('characterSheet', [
      'getTotalClassLevels',
      'getSkillTotal',
      'getAbilityModifier',
      'getClassSkills',
      'getAllClassSkills',
      'filterModifiersByCategory',
      'getArmorCheckPenalty',
    ]),
  },
  methods: {
    plusMinus,
    abilAbbrev,
    skillNameWithIndicators,
    ...mapMutations('characterSheet', [
      'updateSkill',
    ]),
    ...mapActions('characterSheet', [
      'toggleUserSelectedClassSkill',
    ]),
    setButtonClass() {
      return this.isClassSkill() ? 'active' : '';
    },
    isUserSelectedClassSkill() {
      this.userSelectedClassSkills.includes(this.skill.name);
    },
    isClassSkillButtonDisabled() {
      return !this.isUserSelectedClassSkill() && this.getClassSkills.includes(this.skill.name);
    },
    isClassSkill() {
      return !!this.getAllClassSkills.includes(this.skill.name);
    },
  },
};
</script>

<style lang="scss">
.skill-name-text {
  border-radius: 10px 10px 0px 0px;
  margin: 0 1.5rem;
  padding: 0.25rem 1rem !important;
  width: auto;
}
.skill-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin: 0rem 0rem 0.25rem;
  padding: 0.25rem 1rem;
  border-radius: 20px;
  .class-skill {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .class-skill-bonus {
      padding-top: 1.7rem;
    }
    label {
      font-size: $text-smaller !important;
      font-weight: $normal-text !important;
    }
  }
  .skill-ranks-stepper {
    margin-bottom: 1rem;
    .spin-button-field {
      .b-form-spinbutton {
        height: 31px;
      }
    }
  }
  .skill-ability-score {
    text-align: center;
  }
  .skill-optional-modifiers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-basis: 20%;
    .skill-armor-check-penalty {
      text-align: center;
      padding: 0 5px;
    }
  }
  .skill-misc-modifier {
    text-align: center;
    padding: 0 5px;
    margin-bottom: 1rem;
  }
  .skill-total {
    font-size: $text-header;
    font-weight: $superthin-text;
    line-height: 1;
    text-align: right;
  }
}
label.skill-label {
  font-size: $text-smaller;
  font-weight: $normal-text;
}
div.skill-amount {
  font-size: $text-large;
}
@media screen and (max-width: 576px) {
  .skill-total {
    .skill-total-amount {
      text-align: center;
    }
  }
}
</style>
