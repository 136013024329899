<template>
  <div class="racial-trait-fields fade-in">
    <b-form-row>
      <b-col class="col-12">
        <InputField
          :id="`racial-trait-name-${trait.id}`"
          label="Name"
          :value="trait.name"
          @handleInputFieldInput="updateRacialTrait({ racialTraitToUpdate: { ...trait, name: $event } })"
        />
      </b-col>
      <b-col class="col-12">
        <TextAreaField
          :id="`racial-trait-description-${trait.id}`"
          label="Description"
          rows="5"
          :value="trait.Description"
          @handleTextAreaFieldChange="updateRacialTrait({ racialTraitToUpdate: { ...trait, Description: $event } })"
        />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import InputField from '@/components/form/InputField.vue';
import TextAreaField from '@/components/form/TextAreaField.vue';

export default {
  name: 'RacialTraitFields',
  components: {
    InputField,
    TextAreaField,
  },
  props: {
    trait: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    ...mapMutations('characterSheet', [
      'updateRacialTrait',
    ]),
  },
};
</script>
