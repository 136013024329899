<template>
  <div class="heal-harm">
    <Button
      v-b-modal.healHarmModal
      alt="Damage/Heal"
      v-b-popover.hover.click.focus.blur.top="'Damage/Heal'"
      size="sm"
      btnClass="heal-harm-btn"
      label=""
    >
      <FontAwesomeIcon :icon="heartbeatIcon" />
    </Button>
    <Modal
      modalTitle="Damage/Heal Hit Points"
      modalId="healHarmModal"
      :modalIcon="heartbeatIcon"
      okOnly
      :modalOkTitle="getModalOkTitle"
      @handleModalSubmit="modifyHitPoints"
    >
      <HealHarmFields />
    </Modal>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import Modal from '@/components/Modal.vue';
import Button from '@/components/form/Button.vue';
import HealHarmFields from '@/components/HealHarmFields.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'HealHarm',
  components: {
    Modal,
    Button,
    HealHarmFields,
    FontAwesomeIcon,
  },
  data() {
    return {
      heartbeatIcon: faHeartbeat,
    };
  },
  props: {
    modalSize: {
      type: String,
      default: 'md',
    },
  },
  computed: {
    ...mapFields('characterSheet', {
      healHarmAmount: 'healHarm.amount',
    }),
    getModalOkTitle() {
      if (+this.healHarmAmount > 0) {
        return `Heal ${this.healHarmAmount} HP`;
      }
      if (+this.healHarmAmount < 0) {
        return `Take ${Math.abs(this.healHarmAmount)} Damage`;
      }
      return 'Ok';
    },
  },
  methods: {
    modifyHitPoints() {
      this.$store.dispatch('characterSheet/makeHitPointAdjustment', this.healHarmAmount);
    },
  },
};
</script>
