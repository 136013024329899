<template>
  <div class="portrait-fields fade-in">
    <b-form-row>
      <b-col class="col-10">
        <InputField
          id="portraitImgUrl"
          label="Portrait Image URL"
          v-model="portraitImgUrl"
        />
      </b-col>
      <b-col class="col-2">
        <div class="img-restrainer">
          <CircularImage :imgUrl="portraitImgUrl" />
        </div>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import InputField from '@/components/form/InputField.vue';
import CircularImage from '@/components/CircularImage.vue';

const { mapFields: characterFields } = createHelpers({
  getterType: 'characterSheet/getField',
  mutationType: 'characterSheet/updateField',
});

export default {
  name: 'PortraitFields',
  components: {
    InputField,
    CircularImage,
  },
  computed: {
    ...characterFields([
      'portraitImgUrl',
    ]),
  },
  methods: {
  },
};
</script>

<!--   Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.portrait-fields {
  margin: 0 1%;
  .img-restrainer {
    max-height: 60px;
    max-width: 60px;
    overflow: hidden;
    text-align: center;
    margin: 0 auto;
  }
}
</style>
