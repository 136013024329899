import Vue from 'vue';
import Vuex from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import axios from 'axios';
import alignments from './modules/alignments';
import characterSheet from './modules/characterSheet';
import { blankCharacter, emptyClass, emptySpellcasting } from './database/blankCharacter';
import loader from './modules/loader';
import httpClient from '../utils/httpClient';
import {
  checkInitialize,
  setThemeBodyClass,
  extractSpellcastingFeature,
  arrayToObject,
} from '../utils/utils';
import router from '../router';

Vue.use(Vuex);

const blankDatabase = () => ({
  errors: [],
  stepEdit: false,
  isAuthenticated: false,
  isSupporter: false,
  isPrestige: false,
  campaignId: 5066234,
  tierIds: {
    5977627: {
      id: 5977627,
      name: 'Core',
    },
    5977628: {
      id: 5977628,
      name: 'Prestige',
    },
    5977629: {
      id: 5977629,
      name: 'Epic',
    },
  },
  allPatrons: [],
  patreonCursor: 0,
  corePatrons: [],
  prestigePatrons: [],
  epicPatrons: [],
  userSettings: {
    defaultTheme: 'tublave',
    showDiceTray: false,
    buildOption: process.env.NODE_ENV === 'production' ? 'Step-by-Step Builder' : 'Interactive UI',
    viewDiceCheckLog: false,
  },
  themes: {
    Blorbal: require('../scss/css/blorbal.css'),
    Blufin: require('../scss/css/blufin.css'),
    Citriq: require('../scss/css/citriq.css'),
    Elaria: require('../scss/css/elaria.css'),
    Errant: require('../scss/css/errant.css'),
    Hazitz: require('../scss/css/hazitz.css'),
    Incen: require('../scss/css/incen.css'),
    Lys: require('../scss/css/lys.css'),
    Ocua: require('../scss/css/ocua.css'),
    Optim: require('../scss/css/optim.css'),
    Perchmin: require('../scss/css/perchmin.css'),
    Shneeker: require('../scss/css/shneeker.css'),
    Shwam: require('../scss/css/shwam.css'),
    Tublave: require('../scss/css/tublave.css'),
    Woodsie: require('../scss/css/woodsie.css'),
    Yarg: require('../scss/css/yarg.css'),
  },
  saveUpdatesToLocalStorage: false,
  savedCharacterSheets: [],
  races: [],
  classes: [],
  skillFilters: [
    {
      text: 'Class Skills',
      value: 'class',
      isActive: false,
    },
    {
      text: 'Non-Class Skills',
      value: 'nonclass',
      isActive: false,
    },
    {
      text: 'Custom Skills',
      value: 'custom',
      isActive: false,
    },
    {
      text: 'Has Ranks',
      value: 'ranks',
      isActive: false,
    },
    {
      text: 'No Ranks',
      value: 'noranks',
      isActive: false,
    },
    {
      text: 'Hide Untrained',
      value: 'hideuntrained',
      isActive: false,
    },
  ],
  feats: [],
  traits: [],
  spells: [],
  familiars: [],
  animalCompanions: [],
  itemTypes: [
    'Simple Weapons',
    'Martial Weapons',
    'Exotic Weapons',
    'Ammunition',
    'Light Armor',
    'Medium Armor',
    'Heavy Armor',
    'Shields',
    'Armor Extras',
    'Adventuring Gear',
    'Special Substances',
    'Tools and Skill Kits',
    'Clothing',
    'Mounts and Related Gear',
    'Transport',
    'Miscellaneous',
  ],
  abilityScores: [
    'Strength',
    'Dexterity',
    'Constitution',
    'Intelligence',
    'Wisdom',
    'Charisma',
  ],
  modifierCategories: [
    'Armor Class',
    'Armor Check Penalty',
    'Max Dex Bonus',
    'Arcane Spell Failure',
    'Attack Rolls',
    'Damage Rolls',
    'Fort',
    'Ref',
    'Will',
    'CMD',
    'CMB',
    'Initiative',
    'Hit Points',
    'Speed',
  ],
  modifierTypes: [
    'Alchemical',
    'Armor',
    'Circumstance',
    'Competence',
    'Deflection',
    'Dodge',
    'Enhancement',
    'Insight',
    'Luck',
    'Morale',
    'Natural Armor',
    'Profane',
    'Racial',
    'Resistance',
    'Sacred',
    'Shield',
    'Size',
    'Trait',
  ],
  simpleWeapons: [],
  martialWeapons: [],
  exoticWeapons: [],
  ammunition: [],
  lightArmor: [],
  mediumArmor: [],
  heavyArmor: [],
  shields: [],
  armorExtras: [],
  adventuringGear: [],
  specialSubstances: [],
  toolsAndSkillKits: [],
  clothing: [],
  mountsAndRelatedGear: [],
  transport: [],
  miscellaneous: [],
  ageCategories: {
    Normal: {
      Strength: 0,
      Dexterity: 0,
      Constitution: 0,
      Intelligence: 0,
      Wisdom: 0,
      Charisma: 0,
    },
    'Middle Age': {
      Strength: -1,
      Dexterity: -1,
      Constitution: -1,
      Intelligence: 1,
      Wisdom: 1,
      Charisma: 1,
    },
    Old: {
      Strength: -3,
      Dexterity: -3,
      Constitution: -3,
      Intelligence: 2,
      Wisdom: 2,
      Charisma: 2,
    },
    Venerable: {
      Strength: -6,
      Dexterity: -6,
      Constitution: -6,
      Intelligence: 3,
      Wisdom: 3,
      Charisma: 3,
    },
  },
  sizeCategories: [
    {
      name: 'Colossal',
      acMod: -8,
      cmMod: 8,
    },
    {
      name: 'Gargantuan',
      acMod: -4,
      cmMod: 4,
    },
    {
      name: 'Huge',
      acMod: -2,
      cmMod: 2,
    },
    {
      name: 'Large',
      acMod: -1,
      cmMod: 1,
    },
    {
      name: 'Medium',
      acMod: 0,
      cmMod: 0,
    },
    {
      name: 'Small',
      acMod: 1,
      cmMod: -1,
    },
    {
      name: 'Tiny',
      acMod: 2,
      cmMod: -2,
    },
    {
      name: 'Diminutive',
      acMod: 4,
      cmMod: -4,
    },
    {
      name: 'Fine',
      acMod: 8,
      cmMod: -8,
    },
  ],
  hitDice: [
    'd4',
    'd6',
    'd8',
    'd10',
    'd12',
  ],
  babSpeeds: [
    'Slow',
    'Medium',
    'Fast',
  ],
  saveSpeeds: [
    'SSS',
    'SSF',
    'SFF',
    'SFS',
    'FSF',
    'FSS',
    'FFS',
    'FFF',
  ],
  skillPointsPerLevel: [
    2,
    4,
    6,
    8,
  ],
  attackTypes: [
    'Melee',
    'Ranged',
    'Unarmed',
    'Natural',
  ],
  damageTypes: [
    'Piercing',
    'Slashing',
    'Bludgeoning',
    'Acid',
    'Cold',
    'Electricity',
    'Fire',
    'Sonic',
    'Force',
    'Poison',
    'Bleed',
    'Nonlethal',
    'Precision',
    'Positive Energy',
    'Negative Energy',
    'Lawful',
    'Chaotic',
    'Good',
    'Evil',
  ],
  featTypes: [
    'Animal Companion',
    'Animal/Familiar',
    'Achievement',
    'Alignment',
    'Armor Mastery',
    'Betrayal',
    'Blood Hex',
    'Called Shot',
    'Caravan',
    'Channeling',
    'Combat',
    'Combination',
    'Conduit',
    'Coven',
    'Critical',
    'Damnation',
    'Esoteric',
    'Faction',
    'Familiar',
    'Gathlian Court Title',
    'General',
    'Grit',
    'Hero Point',
    'Item Creation',
    'Item Mastery',
    'Local',
    'Meditation',
    'Metamagic',
    'Monster',
    'Mythic',
    'Origin',
    'Panache',
    'Performance',
    'Possession',
    'Racial',
    'Shield Mastery',
    'Stare',
    'Story',
    'Style',
    'Targeting',
    'Teamwork',
    'Trick',
    'Weapon Mastery',
    'Words of Power',
  ],
  traitTypes: [
    'Campaign',
    'Combat',
    'Equipment',
    'Faith',
    'Family',
    'Magic',
    'Mount',
    'Race',
    'Regional',
    'Religion',
    'Social',
  ],
  equipmentSlots: [
    'Head',
    'Headband',
    'Eyes',
    'Shoulders',
    'Neck',
    'Chest',
    'Body',
    'Armor',
    'Belt',
    'Wrists',
    'One-Handed',
    'Two-Handed',
    'Ring',
    'Feet',
  ],
  spellTypes: [
    'Arcane',
    'Divine',
  ],
  spellLists: [
    'Alchemist/Investigator',
    'Antipaladin',
    'Bard',
    'Bloodrager',
    'Cleric/Oracle',
    'Druid',
    'Inquisitor',
    'Magus',
    'Medium',
    'Mesmerist',
    'Occultist',
    'Paladin',
    'Psychic',
    'Ranger',
    'Shaman',
    'Sorcerer/Wizard',
    'Spiritualist',
    'Summoner',
    'Witch',
  ],
  castingTypes: [
    'Prepared',
    'Known',
  ],
  spellsPerDayTables: {
    Minimal: [
      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
      [null, null, null, 1, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4],
      [null, null, null, null, null, null, 1, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4],
      [null, null, null, null, null, null, null, null, null, 1, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3],
      [null, null, null, null, null, null, null, null, null, null, null, null, 1, 1, 1, 1, 1, 2, 2, 2],
    ],
    Delayed: [
      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
      [null, null, null, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4],
      [null, null, null, null, null, null, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4],
      [null, null, null, null, null, null, null, null, null, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3],
      [null, null, null, null, null, null, null, null, null, null, null, null, 0, 1, 1, 1, 1, 2, 2, 3],
    ],
    Standard: [
      [3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [1, 2, 2, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [null, null, 1, 2, 2, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [null, null, null, null, 1, 2, 2, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [null, null, null, null, null, null, 1, 2, 2, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4],
      [null, null, null, null, null, null, null, null, 1, 2, 2, 3, 3, 3, 4, 4, 4, 4, 4, 4],
      [null, null, null, null, null, null, null, null, null, null, 1, 2, 2, 3, 3, 3, 4, 4, 4, 4],
      [null, null, null, null, null, null, null, null, null, null, null, null, 1, 2, 2, 3, 3, 3, 4, 4],
      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, 1, 2, 2, 3, 3, 4],
      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 1, 2, 3, 4],
    ],
    Spontaneous: [
      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
      [3, 4, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      [null, null, null, 3, 4, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      [null, null, null, null, null, 3, 4, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      [null, null, null, null, null, null, null, 3, 4, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      [null, null, null, null, null, null, null, null, null, 3, 4, 5, 6, 6, 6, 6, 6, 6, 6, 6],
      [null, null, null, null, null, null, null, null, null, null, null, 3, 4, 5, 6, 6, 6, 6, 6, 6],
      [null, null, null, null, null, null, null, null, null, null, null, null, null, 3, 4, 5, 6, 6, 6, 6],
      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 3, 4, 5, 6, 6],
      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 3, 4, 6],
    ],
    Limited: [
      [3, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      [1, 2, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      [null, null, null, 1, 2, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      [null, null, null, null, null, null, 1, 2, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5],
      [null, null, null, null, null, null, null, null, null, 1, 2, 3, 3, 4, 4, 4, 4, 5, 5, 5],
      [null, null, null, null, null, null, null, null, null, null, null, null, 1, 2, 3, 3, 4, 4, 5, 5],
      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 1, 2, 3, 4, 5],
    ],
    Arcanist: [
      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
      [2, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [null, null, null, 2, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [null, null, null, null, null, 2, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [null, null, null, null, null, null, null, 2, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [null, null, null, null, null, null, null, null, null, 2, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [null, null, null, null, null, null, null, null, null, null, null, 2, 3, 4, 4, 4, 4, 4, 4, 4],
      [null, null, null, null, null, null, null, null, null, null, null, null, null, 2, 3, 4, 4, 4, 4, 4],
      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 2, 3, 4, 4, 4],
      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 2, 3, 4],
    ],
  },
  spellsKnownTables: {
    Minimal: [
      [2, 3, 4, 4, 5, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      [null, null, null, 2, 3, 4, 4, 4, 5, 5, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      [null, null, null, null, null, null, 2, 3, 4, 4, 4, 5, 5, 5, 6, 6, 6, 6, 6, 6],
      [null, null, null, null, null, null, null, null, null, 2, 3, 4, 4, 4, 5, 5, 5, 6, 6, 6],
      [null, null, null, null, null, null, null, null, null, null, null, null, 2, 3, 4, 4, 4, 5, 5, 5],
    ],
    Delayed: [
      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
      [null, null, null, 2, 3, 4, 4, 4, 4, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 6],
      [null, null, null, null, null, null, 2, 3, 4, 4, 4, 4, 5, 5, 5, 5, 6, 6, 6, 6],
      [null, null, null, null, null, null, null, null, null, 2, 3, 4, 4, 4, 4, 5, 5, 5, 5, 6],
      [null, null, null, null, null, null, null, null, null, null, null, null, 2, 3, 4, 4, 4, 4, 5, 5],
    ],
    Spontaneous: [
      [4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9],
      [2, 2, 3, 3, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      [null, null, null, 1, 2, 2, 3, 3, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      [null, null, null, null, null, 1, 2, 2, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [null, null, null, null, null, null, null, 1, 2, 2, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4],
      [null, null, null, null, null, null, null, null, null, 1, 2, 2, 3, 3, 4, 4, 4, 4, 4, 4],
      [null, null, null, null, null, null, null, null, null, null, null, 1, 2, 2, 3, 3, 3, 3, 3, 3],
      [null, null, null, null, null, null, null, null, null, null, null, null, null, 1, 2, 2, 3, 3, 3, 3],
      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 1, 2, 2, 3, 3],
      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 1, 2, 3],
    ],
    Limited: [
      [4, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      [2, 3, 4, 4, 4, 4, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      [null, null, null, 2, 3, 4, 4, 4, 4, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 6],
      [null, null, null, null, null, null, 2, 3, 4, 4, 4, 4, 5, 5, 5, 5, 6, 6, 6, 6],
      [null, null, null, null, null, null, null, null, null, 2, 3, 4, 4, 4, 4, 5, 5, 5, 5, 6],
      [null, null, null, null, null, null, null, null, null, null, null, null, 2, 3, 4, 4, 4, 4, 5, 5],
      [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 2, 3, 4, 4, 5],
    ],
  },
  conditions: [],
  experienceLevels: {
    0: {
      slow: 0,
      medium: 0,
      fast: 0,
    },
    1: {
      slow: 0,
      medium: 0,
      fast: 0,
    },
    2: {
      slow: 3000,
      medium: 2000,
      fast: 1300,
    },
    3: {
      slow: 7500,
      medium: 5000,
      fast: 3300,
    },
    4: {
      slow: 14000,
      medium: 9000,
      fast: 6000,
    },
    5: {
      slow: 23000,
      medium: 15000,
      fast: 10000,
    },
    6: {
      slow: 35000,
      medium: 23000,
      fast: 15000,
    },
    7: {
      slow: 53000,
      medium: 35000,
      fast: 23000,
    },
    8: {
      slow: 77000,
      medium: 51000,
      fast: 34000,
    },
    9: {
      slow: 115000,
      medium: 75000,
      fast: 50000,
    },
    10: {
      slow: 160000,
      medium: 105000,
      fast: 71000,
    },
    11: {
      slow: 235000,
      medium: 155000,
      fast: 105000,
    },
    12: {
      slow: 330000,
      medium: 220000,
      fast: 145000,
    },
    13: {
      slow: 475000,
      medium: 315000,
      fast: 210000,
    },
    14: {
      slow: 665000,
      medium: 445000,
      fast: 295000,
    },
    15: {
      slow: 955000,
      medium: 635000,
      fast: 425000,
    },
    16: {
      slow: 1350000,
      medium: 890000,
      fast: 600000,
    },
    17: {
      slow: 1900000,
      medium: 1300000,
      fast: 850000,
    },
    18: {
      slow: 2700000,
      medium: 1800000,
      fast: 1200000,
    },
    19: {
      slow: 3850000,
      medium: 2550000,
      fast: 1700000,
    },
    20: {
      slow: 5350000,
      medium: 3600000,
      fast: 2400000,
    },
    21: {
      slow: 10700000,
      medium: 7200000,
      fast: 4800000,
    },
    22: {
      slow: 21400000,
      medium: 14400000,
      fast: 9600000,
    },
    23: {
      slow: 42800000,
      medium: 28800000,
      fast: 19200000,
    },
    24: {
      slow: 85600000,
      medium: 57600000,
      fast: 38400000,
    },
    25: {
      slow: 171200000,
      medium: 115200000,
      fast: 76800000,
    },
  },
  currencyDenominations: {
    Platinum: {
      name: 'Platinum',
      abbreviation: 'PP',
      value: 10,
    },
    Gold: {
      name: 'Gold',
      abbreviation: 'GP',
      value: 1,
    },
    Electrum: {
      name: 'Electrum',
      abbreviation: 'EP',
      value: 0.5,
    },
    Silver: {
      name: 'Silver',
      abbreviation: 'SP',
      value: 0.1,
    },
    Copper: {
      name: 'Copper',
      abbreviation: 'CP',
      value: 0.01,
    },
  },
  carryingCapacity: {
    5: {
      light: 16,
      medium: 33,
      heavy: 50,
      overHead: 50,
      offGround: 100,
      pushOrDrag: 250,
    },
    6: {
      light: 20,
      medium: 40,
      heavy: 60,
      overHead: 60,
      offGround: 120,
      pushOrDrag: 300,
    },
    7: {
      light: 23,
      medium: 46,
      heavy: 70,
      overHead: 70,
      offGround: 140,
      pushOrDrag: 350,
    },
    8: {
      light: 26,
      medium: 53,
      heavy: 80,
      overHead: 80,
      offGround: 160,
      pushOrDrag: 400,
    },
    9: {
      light: 30,
      medium: 60,
      heavy: 90,
      overHead: 90,
      offGround: 180,
      pushOrDrag: 450,
    },
    10: {
      light: 33,
      medium: 66,
      heavy: 100,
      overHead: 100,
      offGround: 200,
      pushOrDrag: 500,
    },
    11: {
      light: 38,
      medium: 76,
      heavy: 115,
      overHead: 115,
      offGround: 230,
      pushOrDrag: 575,
    },
    12: {
      light: 43,
      medium: 86,
      heavy: 130,
      overHead: 130,
      offGround: 260,
      pushOrDrag: 650,
    },
    13: {
      light: 50,
      medium: 100,
      heavy: 150,
      overHead: 150,
      offGround: 300,
      pushOrDrag: 750,
    },
    14: {
      light: 58,
      medium: 116,
      heavy: 175,
      overHead: 175,
      offGround: 350,
      pushOrDrag: 875,
    },
    15: {
      light: 66,
      medium: 133,
      heavy: 200,
      overHead: 200,
      offGround: 400,
      pushOrDrag: 1000,
    },
    16: {
      light: 76,
      medium: 153,
      heavy: 230,
      overHead: 230,
      offGround: 460,
      pushOrDrag: 1150,
    },
    17: {
      light: 86,
      medium: 173,
      heavy: 260,
      overHead: 260,
      offGround: 520,
      pushOrDrag: 1300,
    },
    18: {
      light: 100,
      medium: 200,
      heavy: 300,
      overHead: 300,
      offGround: 600,
      pushOrDrag: 1500,
    },
    19: {
      light: 116,
      medium: 233,
      heavy: 350,
      overHead: 350,
      offGround: 700,
      pushOrDrag: 1750,
    },
    20: {
      light: 133,
      medium: 266,
      heavy: 400,
      overHead: 400,
      offGround: 800,
      pushOrDrag: 2000,
    },
    21: {
      light: 153,
      medium: 306,
      heavy: 460,
      overHead: 460,
      offGround: 920,
      pushOrDrag: 2300,
    },
    22: {
      light: 173,
      medium: 346,
      heavy: 520,
      overHead: 520,
      offGround: 1040,
      pushOrDrag: 2600,
    },
    23: {
      light: 200,
      medium: 400,
      heavy: 600,
      overHead: 600,
      offGround: 1200,
      pushOrDrag: 3000,
    },
    24: {
      light: 233,
      medium: 466,
      heavy: 700,
      overHead: 700,
      offGround: 1400,
      pushOrDrag: 3500,
    },
    25: {
      light: 266,
      medium: 533,
      heavy: 800,
      overHead: 800,
      offGround: 1600,
      pushOrDrag: 4000,
    },
    26: {
      light: 306,
      medium: 613,
      heavy: 920,
      overHead: 920,
      offGround: 1840,
      pushOrDrag: 4600,
    },
    27: {
      light: 346,
      medium: 693,
      heavy: 1040,
      overHead: 1040,
      offGround: 2080,
      pushOrDrag: 5200,
    },
    28: {
      light: 400,
      medium: 800,
      heavy: 1200,
      overHead: 1200,
      offGround: 2400,
      pushOrDrag: 6000,
    },
    29: {
      light: 466,
      medium: 933,
      heavy: 1400,
      overHead: 1400,
      offGround: 2800,
      pushOrDrag: 7000,
    },
  },
  stackableBonusTypes: [
    'dodge',
    'circumstance',
    'race',
    'racial',
  ],
});

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    ...blankDatabase(),
  },
  modules: {
    characterSheet,
    alignments,
    loader,
  },
  getters: {
    getState: (state) => state,
    getField,
    getUserSettings(state, field) {
      return getField(state.userSettings, field);
    },
  },
  mutations: {
    updateField,
    setField(state, { stateProperty, value }) {
      state[stateProperty] = value;
    },
    updateUserSettings(state, field) {
      updateField(state.userSettings, field);
    },
    toggleProperty(state, property) {
      state[property] = !state[property];
    },
    toggleDeepProperty(state, { property, element }) {
      state[property][element] = !state[property][element];
    },
    toggleSaveToLocalStorage: (state, bool) => {
      state.saveUpdatesToLocalStorage = !!bool;
    },
    updateSaveTimestamp: (state, stamp) => {
      state.characterSheet.lastSave = stamp;
    },
    authenticateUser: (state, isLoggedIn) => {
      state.isAuthenticated = isLoggedIn;
      if (!isLoggedIn) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('support_tier');
        state.isSupporter = false;
        state.isPrestige = false;
      }
    },
    setPatreonSupportTier: (state, entitledTier) => {
      if (state.tierIds[entitledTier]) {
        state.isSupporter = true;
        const userTier = state.tierIds[entitledTier];
        if (userTier.name === 'Prestige' || userTier.name === 'Epic') {
          state.isPrestige = true;
        }
      }
    },
    mockPatreonLogin: (state, isLoggedIn) => {
      state.isAuthenticated = isLoggedIn;
      state.isSupporter = isLoggedIn;
    },
    setPatrons: (state, { patronsArray, patronsList }) => {
      Vue.set(state, patronsList, patronsArray);
    },
    loadFetchedData: (state, { targetList, payload }) => {
      const frozenPayload = payload.map((item) => Object.freeze(item)).sort((a, b) => a.name.localeCompare(b.name));
      state[targetList] = frozenPayload;
    },
    addError(state, error) {
      if (state.errors.indexOf(error)) {
        state.errors.push(error);
      }
    },
    removeError(state, index) {
      state.errors.splice(index, 1);
    },
    clearErrors(state) {
      state.errors = [];
    },
    updateCharacterName(state, charName) {
      state.characterSheet.characterName = charName;
    },
    getSavedCharacterSheets(state) {
      const characterSheets = Object.keys(localStorage).filter((item) => localStorage.getItem(item).includes('characterName'));
      state.savedCharacterSheets = [...characterSheets];
    },
    overwriteCharacterSheet(state, newSheet = {}) {
      state.characterSheet = { ...blankCharacter(), ...newSheet };
      setThemeBodyClass(state.characterSheet.characterTheme);
    },
    createNewCharacterSheet(state, charName) {
      state.characterSheet = { ...blankCharacter() };
      state.characterSheet.characterName = charName;
      setThemeBodyClass(state.characterSheet.characterTheme);
      localStorage.setItem('latestSheet', charName);
    },
    saveCharacterSheetToLocalStorage(state) {
      const sheetWithTimestamp = { ...state.characterSheet };
      sheetWithTimestamp.lastSave = new Date();
      localStorage.setItem(state.characterSheet.characterName, JSON.stringify(sheetWithTimestamp));
    },
    setSkillFilters(state, activeFilters) {
      state.skillFilters = state.skillFilters.map((filterObj) => ({
        ...filterObj,
        isActive: !!activeFilters.find((filterText) => filterText === filterObj.text),
      }));
    },
  },
  actions: {
    loadCharacterSheet({ commit, dispatch }, charName) {
      if (localStorage.getItem(charName)) {
        const loadedSheet = JSON.parse(localStorage.getItem(charName));
        const convertedClasses = loadedSheet.characterClasses
          .map((clz) => ({ ...emptyClass(), ...clz, classFeatures: checkInitialize(clz.classFeatures) }))
          .map((clz) => {
            if (Object.keys(extractSpellcastingFeature(clz.classFeatures)).length > 0) {
              return ({
                ...clz,
                spellcasting: { ...emptySpellcasting(), ...extractSpellcastingFeature(clz.classFeatures), isSpellcaster: !!extractSpellcastingFeature(clz.classFeatures) },
                classFeatures: arrayToObject(Object.values(clz.classFeatures).filter((feature) => feature.name !== 'Spellcasting')),
              });
            }
            return clz;
          });
        const syncedSkills = [...blankCharacter().skillsList].map((skillObj) => {
          const sameSkill = loadedSheet.skillsList.find((loadedSkill) => loadedSkill.name === skillObj.name);
          if (sameSkill) {
            return { ...skillObj, ...sameSkill };
          }
          return skillObj;
        });
        const updatedSheet = {
          ...loadedSheet,
          characterClasses: convertedClasses,
          racialTraits: checkInitialize(loadedSheet.racialTraits),
          skillsList: syncedSkills,
        };
        commit('overwriteCharacterSheet', updatedSheet);
      } else {
        commit('overwriteCharacterSheet', {});
        commit('updateCharacterName', charName);
      }
      dispatch('characterSheet/gatherFeats').then(() => {
        commit('saveCharacterSheetToLocalStorage');
      });
      localStorage.setItem('latestSheet', charName);
    },
    fetchList({ commit }, { urlPath, targetList }) {
      return httpClient
        .get(`${process.env.VUE_APP_CONFIG_SERVERURL}${urlPath}`)
        .then((response) => {
          if (targetList) {
            commit('loadFetchedData', { targetList, payload: response });
          }
          return response;
        })
        .catch((error) => {
          throw new Error(`API ${error}`);
        });
    },
    fetchListItem({ state }, { value, listToSearch, urlPath }) {
      const getItemByName = state[listToSearch].find((item) => item.name === value);
      if (getItemByName) {
        return httpClient
          .get(`${process.env.VUE_APP_CONFIG_SERVERURL}${urlPath}/${encodeURIComponent(getItemByName.name)}`)
          .then((response) => response)
          .catch((error) => {
            throw new Error(`API ${error}`);
          });
      }
      return null;
    },
    fetchUrlPath(context, urlPath) {
      return httpClient
        .get(`${process.env.VUE_APP_CONFIG_SERVERURL}${urlPath}`)
        .then((response) => response)
        .catch((error) => {
          throw new Error(`API ${error}`);
        });
    },
    handleAuthentication({ commit, dispatch }, authCode) {
      return axios
        .create({
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .post(
          `${process.env.VUE_APP_PROXY_TOKEN}`, null,
          {
            params: {
              code: authCode,
              client_id: process.env.VUE_APP_PATREON_CLIENTID,
              grant_type: 'authorization_code',
              redirect_uri: `${process.env.VUE_APP_REDIRECTURL}`,
              scope: 'identity',
            },
          },
        )
        .then((response) => {
          const res = response.data;
          if (response && res.access_token && res.refresh_token) {
            const expiresAt = JSON.stringify(
              res.expires_in * 1000 + new Date().getTime(),
            );
            localStorage.setItem('access_token', res.access_token);
            localStorage.setItem('refresh_token', res.refresh_token);
            localStorage.setItem('expires_at', expiresAt);
            commit('authenticateUser', true);
            dispatch('fetchPatreonProfile');
          } else {
            commit('authenticateUser', false);
            console.log('We could not connect you with Patreon.');
            if (router.currentRoute.path !== '/') {
              router.replace('/');
            }
          }
        })
        .catch((error) => {
          throw new Error(`API ${error}`);
        });
    },
    fetchPatreonProfile({ state, commit }) {
      if (localStorage.getItem('access_token') && localStorage.getItem('refresh_token') && localStorage.getItem('expires_at')) {
        return axios
          .create({
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          })
          .get(
            `${process.env.VUE_APP_PROXY_IDENTITY}`,
            {
              params: {
                include: 'memberships,memberships.currently_entitled_tiers',
                fields: '',
              },
            },
          )
          .then((response) => {
            const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
            const loginIsExpired = new Date().getTime() > expiresAt;
            if (response && !loginIsExpired) {
              if (!state.isAuthenticated) {
                commit('authenticateUser', true);
              }
              if (response.data.included && response.data.included[0]) {
                commit('setPatreonSupportTier', response.data.included[0].relationships.currently_entitled_tiers.data[0].id);
              }
              if (router.currentRoute.path !== '/') {
                router.replace('/');
              }
            } else {
              commit('authenticateUser', false);
              if (router.currentRoute.path !== '/') {
                router.replace('/');
              }
            }
          })
          .catch((error) => {
            throw new Error(`API ${error}`);
          });
      }
      return null;
    },
    async fetchPageOfMembers({ state, commit }) {
      let addCursor = '';
      if (state.patreonCursor !== 0 && state.patreonCursor !== null) {
        addCursor = `&page%5Bcursor%5D=${encodeURIComponent(state.patreonCursor)}`;
      }
      return axios
        .create({
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_PATREON_CREATOR_TOKEN}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .get(
          `${process.env.VUE_APP_PROXY_CAMPAIGNS}/${state.campaignId}/members?fields%5Bmember%5D=full_name${addCursor}`,
          {
            params: {
              include: 'currently_entitled_tiers',
            },
          },
        )
        .then((response) => {
          commit('setField', { stateProperty: 'allPatrons', value: [...state.allPatrons, ...response.data.data] });
          commit('setField', { stateProperty: 'patreonCursor', value: response.data.meta.pagination.cursors.next });
        });
    },
    fetchPatrons({ state, commit, dispatch }) {
      commit('setField', { stateProperty: 'patreonCursor', value: 0 });
      commit('setField', { stateProperty: 'allPatrons', value: [] });

      const fetchPage = async () => {
        try {
          await dispatch('fetchPageOfMembers');

          if (state.patreonCursor !== null) {
            await fetchPage();
          } else {
            const getEpicPatrons = state.allPatrons
              .filter((patron) => state.tierIds[patron?.relationships?.currently_entitled_tiers?.data?.[0]?.id]?.name === 'Epic')
              .map((patron) => patron?.attributes?.full_name);
            commit('setPatrons', { patronsArray: getEpicPatrons, patronsList: 'epicPatrons' });
            const getPrestigePatrons = state.allPatrons
              .filter((patron) => state.tierIds[patron?.relationships?.currently_entitled_tiers?.data?.[0]?.id]?.name === 'Prestige')
              .map((patron) => patron?.attributes?.full_name);
            commit('setPatrons', { patronsArray: getPrestigePatrons, patronsList: 'prestigePatrons' });
            const getCorePatrons = state.allPatrons
              .filter((patron) => state.tierIds[patron?.relationships?.currently_entitled_tiers?.data?.[0]?.id]?.name === 'Core')
              .map((patron) => patron?.attributes?.full_name);
            commit('setPatrons', { patronsArray: getCorePatrons, patronsList: 'corePatrons' });
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchPage();
    },
  },
});
