<template>
  <div class="view-panel fade-in">
    <div
      class="view-panel-header"
      v-b-toggle="'view-panel-'+replaceSpaces(title)+'-collapse'"
    >
      <div class="view-panel-title ml-3">{{ title }}</div>
      <Button
        :alt="'Toggle '+title+' Panel'"
        size="sm"
        btnClass="toggle-btn"
        label=""
      >
        {{ isPanelVisible ? '-' : '+' }}
      </Button>
    </div>
    <b-collapse
      :id="'view-panel-'+replaceSpaces(title)+'-collapse'"
      v-model="isPanelVisible"
    >
      <slot class="m-3"></slot>
    </b-collapse>
  </div>
</template>

<script>
import Button from '@/components/form/Button.vue';
import { replaceSpaces } from '../utils/utils';

export default {
  name: 'ViewPanel',
  components: {
    Button,
  },
  data() {
    return {
      isPanelVisible: false,
    };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    panelClass: {
      type: String,
      default: '',
    },
    defaultOpen: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.isPanelVisible = this.defaultOpen;
  },
  methods: {
    replaceSpaces,
  },
};
</script>

<style lang="scss">
.view-panel {
  .view-panel-header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: background-color 1s ease;
    div.form-button {
      margin: 0;
      .toggle-btn {
        margin: 0;
        min-width: 30px;
        border-radius: 0 !important;
      }
    }
    @include cutTopLeftCorner();
  }
}
</style>
