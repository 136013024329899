<template>
  <div class="ability-score fade-in">
    <div class="abbrev">
      {{ abilAbbrev }}
    </div>
    <div class="score">
      <span class="score-number">{{ getAbilityScore(scoreName) }}</span>
    </div>
    <div class="modifier">
      <span class="modbox">{{ getAbilityModifier(scoreName) }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AbilityScore',
  props: {
    scoreName: String,
  },
  computed: {
    ...mapGetters('characterSheet', [
      'getAbilityScore',
      'getAbilityModifier',
    ]),
    abilAbbrev() {
      return this.scoreName.substr(0, 3);
    },
  },
};
</script>

<style scoped lang="scss">
.ability-score {
  position: relative;
  display: block;
  font-size: 0.875rem;
  padding: 0 2px;
  height: 100px;
  width: 100px;
  border-bottom-right-radius: 20px;
  background: $userTheme;
  @include cutTopLeftCorner(15px);
  .abbrev {
    width: 100%;
    font-weight: $normal-text;
    text-transform: uppercase;
  }
  .score {
    display: block;
    position: relative;
    font-size: $text-header;
    font-weight: $thin-text;
    line-height: 1;
  }
  .modifier {
    position: absolute;
    bottom: -35%;
    left: 50%;
    margin: 0 auto;
    width: 45%;
    height: 45%;
    font-size: $text-larger;
    font-weight: $bold-text;
    line-height: 1;
    .modbox {
      position: relative;
      left: -50%;
      padding: 10%;
      background: $panel-bg;
      border-radius: 10% 10% 50% 50%;
    }
  }
}
@media screen and (max-width: 992px) {
  .ability-score {
    height: 70px;
    width: 70px;
    .score {
      font-size: $text-title;
    }
    .modifier {
      width: 30%;
      height: 30%;
      font-size: $text-med-large;
      bottom: -20%;
      .modbox {
        left: -65%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .ability-score {
    height: 60px;
    width: 60px;
    .abbrev {
      font-size: $text-small;
      padding-top: 2px;
    }
    .score {
      font-size: $text-xlarge;
    }
    .modifier {
      font-size: $text-standard;
      bottom: -15px;
    }
  }
}
</style>
