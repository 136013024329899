<template>
  <b-form-group
    class="textarea-field"
    :id="id+'group'"
    :label="label"
    :label-for="id"
    :label-sr-only="!showLabel"
    :description="desc"
  >
    <b-form-textarea
      :id="id"
      :type="type"
      :placeholder="placeholder || label"
      :size="size"
      :class="textareaClass"
      :required="required"
      :disabled="disabled"
      v-model="inputValue"
      @change="handleTextAreaFieldChange"
      @input="handleTextAreaFieldInput"
      :rows="rows"
      :max-rows="maxRows"
    ></b-form-textarea>
  </b-form-group>
</template>

<script>
export default {
  name: 'TextAreaField',
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  props: {
    id: String,
    value: [String, Number],
    type: {
      type: String,
      default: 'text',
    },
    size: {
      type: String,
      default: 'sm',
    },
    textareaClass: {
      type: String,
      default: '',
    },
    label: String,
    showLabel: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    desc: {
      type: String,
      default: '',
    },
    rows: {
      type: [String, Number],
      default: 5,
    },
    maxRows: {
      type: [String, Number],
      default: 20,
    },
  },
  methods: {
    handleTextAreaFieldChange(typedValue) {
      this.$emit('handleTextAreaFieldChange', typedValue);
    },
    handleTextAreaFieldInput(typedValue) {
      this.$emit('handleTextAreaFieldInput', typedValue);
    },
  },
};
</script>
