<template>
  <div class="name-panel fade-in">
    <div class="character-name pr-4"
      v-b-modal.nameModal
    >
      <div class="cutcorner"/>
      {{ characterName || 'Name Your Character' }}
    </div>
    <Modal
      modalTitle="Edit Character Name"
      modalSize="md"
      modalId="nameModal"
      okOnly
      preventSaveWhileOpen
      @handleModalSubmit="handleNameModalClose"
      @handleModalHide="handleNameModalClose"
    >
      <NameFields
        :desc="((isAuthenticated && isSupporter)) ? 'Renaming an existing character will save a new character sheet to your device.' : ''"
      />
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue';
import NameFields from '@/components/NameFields.vue';
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'characterSheet/getField',
  mutationType: 'characterSheet/updateField',
});

export default {
  name: 'NamePanel',
  components: {
    Modal,
    NameFields,
  },
  computed: {
    ...mapFields([
      'characterName',
      'isAuthenticated',
      'isSupporter',
    ]),
  },
  methods: {
    handleNameModalClose() {
      if (!this.saveUpdatesToLocalStorage) {
        this.$store.commit('toggleSaveToLocalStorage', true);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.name-panel {
  text-align: right;
  position: relative;
  .character-name {
    font-family: 'cardo-bold';
    position: relative;
    font-size: $text-xlarge;
    letter-spacing: 3px;
    display: inline-block;
    padding-left: 3rem;
    transition: background-color 1s ease;
    min-width: 100%;
    @include hoverFocusStyle();
    .cutcorner:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: -1px;
      background-color: $body-bg;
      width: 25px;
      height: 102%;
      clip-path: polygon( 0 0, 0 0, 100% 100%, 0% 100%);
      z-index: 10;
    }
  }
  @media screen and (max-width: 992px) {
    .character-name {
      font-size: $text-larger;
    }
  }
  @media screen and (max-width: 768px) {
    .character-name {
      font-size: $text-med-large;
    }
  }
}
@media screen and (max-width: 576px) {
  .name-panel {
    text-align: center;
    .character-name {
      .cutcorner {
        display: none;
      }
    }
  }
}
</style>
