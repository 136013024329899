<template>
  <div class="notes fade-in">
    <TextAreaField
      label="Notes"
      :showLabel="false"
      rows="8"
      v-model="notes"
    />
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import TextAreaField from '@/components/form/TextAreaField.vue';

export default {
  name: 'NotesPanel',
  components: {
    TextAreaField,
  },
  computed: {
    ...mapFields('characterSheet', [
      'notes',
    ]),
  },
};
</script>

<style lang="scss">
.notes {
  margin: 10px 0;
  text-align: left;
  font-size: $text-med-small;
}
</style>
