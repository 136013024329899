import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import { APP_TITLE } from '../constants/metaConstants';

Vue.use(VueRouter);

const routeMeta = {
  title: APP_TITLE,
  metaTags: [
    {
      name: 'description',
      content: 'The most robust, convenient, and usable FREE Pathfinder Character Generator for the 1st Edition of the Pathfinder roleplaying game.',
    },
    {
      property: 'og:description',
      content: 'The most robust, convenient, and usable FREE Pathfinder Character Generator for the 1st Edition of the Pathfinder roleplaying game.',
    },
  ],
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: routeMeta,
  },
  {
    path: '/about',
    name: 'About',
    meta: routeMeta,
    component: () => import('../views/About.vue'),
  },
  {
    path: '/print',
    name: 'Print',
    meta: routeMeta,
    component: () => import('../views/Print.vue'),
  },
  {
    path: '/patreonAuth',
    name: 'PatreonAuth',
    meta: routeMeta,
    component: () => import('../views/PatreonAuth.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'production' ? 'anvil' : '',
  routes,
});

export default router;
