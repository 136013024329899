<template>
  <b-form-row>
    <b-col class="col-5">
      <VueSelectField
        :id="'attack-'+attack.id+'-damage-type-'+damage.id"
        label="Damage Type"
        :options="damageTypes"
        taggable
        placeholder="- Select / Enter Damage Type -"
        :value="damage.type"
        @handleVueSelectFieldChange="updateDamageType({ attack, damage: { id: damage.id, type: $event, amount: damage.amount } })"
      />
    </b-col>
    <b-col class="col-6">
      <InputField
        :id="'attack-'+attack.id+'-damage-amount-'+damage.id"
        label="Damage Amount"
        desc="Dice + modifiers, ie., '2d6 + 3'"
        :value="damage.amount"
        @handleInputFieldChange="updateDamageType({ attack, damage: { id: damage.id, type: damage.type, amount: $event } })"
      />
    </b-col>
    <b-col class="col-1 d-flex justify-content-center align-items-center">
      <Button
        v-if="damage.id === attack.damage[0].id"
        size="sm"
        btnClass="add-damage-type-btn"
        label="Add a Damage Type"
        :showLabel="false"
        @handleButtonClick="addDamageType({ attack })"
        v-b-popover.hover.focus.blur.top="'Add a Damage Type'"
      >
        +
      </Button>
      <Button
        v-if="damage.id !== attack.damage[0].id"
        size="sm"
        :btnClass="'remove-damage-type-btn-'+damage.id"
        label="Remove This Damage Type"
        :showLabel="false"
        @handleButtonClick="deleteDamageType({ attack, damage })"
        v-b-popover.hover.focus.blur.top="'Remove This Damage Type'"
      >
        -
      </Button>
    </b-col>
  </b-form-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Button from '@/components/form/Button.vue';
import VueSelectField from '@/components/form/VueSelectField.vue';
import InputField from '@/components/form/InputField.vue';

export default {
  name: 'DamageFields',
  components: {
    Button,
    VueSelectField,
    InputField,
  },
  props: {
    damage: Object,
    attack: Object,
  },
  computed: {
    ...mapState([
      'damageTypes',
    ]),
  },
  methods: {
    ...mapMutations('characterSheet', [
      'addDamageType',
      'updateDamageType',
      'deleteDamageType',
    ]),
  },
};
</script>

<style scoped lang="scss">
  div.anvil-button {
    margin-bottom: 0.5rem;
  }
</style>
