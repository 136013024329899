<template>
  <div class="damage-roll">
    <SingleDieResult
      v-for="(result, index) in diceResults" :key="'die-roll'+index"
      :die='result.die'
      :result="result.result"
      :isLast="isLastResult(index, diceResults, damageRollElements[1])"
    />
    <ModifierDisplay
      v-for="(el, index) in damageRollElements[1]"
      :key="'dmg-mod-'+index"
      :modNumber="el"
      modText="Damage Bonus"
      :showPlus="damageMods.length > 0"
    />
    <ModifierDisplay
      v-for="(mod, index) in damageMods"
      :key="'misc-mod-'+index"
      :modNumber="mod.value"
      :modText="mod.name"
      :showPlus="index < (damageMods.length -1)"
    />
    <FontAwesomeIcon :icon="equalsIcon" />
    <div class="result-wrapper">
      <div class="total-result">{{ damageResult }}</div>
      <div class="damage-type-wrapper">
        <div class="damage-type">{{ damageType }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SingleDieResult from '@/components/SingleDieResult.vue';
import ModifierDisplay from '@/components/ModifierDisplay.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEquals } from '@fortawesome/free-solid-svg-icons';
import { rollADie, parseDamageString, sumValues } from '../utils/diceUtils';

export default {
  name: 'DamageRoll',
  created() {
    this.rollDamage();
  },
  components: {
    SingleDieResult,
    ModifierDisplay,
    FontAwesomeIcon,
  },
  props: {
    checkName: {
      type: String,
      default: '',
    },
    damageString: {
      type: String,
      default: '',
    },
    damageType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      damageRollElements: [],
      diceResults: [],
      equalsIcon: faEquals,
    };
  },
  computed: {
    damageResult() {
      return +this.diceResults.reduce((a, b) => +a + +b.result, 0) + (this.damageRollElements[1].length ? +sumValues(this.damageRollElements[1]) : 0) + this.damageMods.reduce((a, b) => +a + +b.value, 0);
    },
    damageMods() {
      return this.buildModArray('Damage Rolls');
    },
    ...mapGetters('characterSheet', [
      'buildModArray',
    ]),
  },
  methods: {
    sumValues,
    rollADie,
    parseDamageString,
    rollDamage() {
      this.damageRollElements = parseDamageString(this.damageString);
      this.diceResults = [];
      this.damageRollElements[0].forEach((dieRoll) => {
        this.makeOneDieRoll(dieRoll);
      });
    },
    makeOneDieRoll(dieType) {
      this.diceResults.push({
        die: dieType,
        result: rollADie(dieType.split('d')[1]),
      });
    },
    isLastResult(thisResult, results, elements) {
      return thisResult >= (results.length - 1) && elements.length === 0;
    },
  },
};
</script>

<style lang="scss">
.damage-roll {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .result-number {
    font-size: $text-title;
  }
  .result-wrapper {
    @include flexRowCenter();
    .total-result {
      font-size: $text-header;
    }
    .damage-type-wrapper {
      height: 100%;
      min-height: 90px;
      min-width: 25px;
      max-width: 25px;
      margin-left: 10px;
      @include flexColumnCenter();
      .damage-type {
        transform: rotate(90deg);
        text-align: center;
        white-space: nowrap;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .damage-roll {
    .result-wrapper {
      .total-result {
        font-size: $text-title;
      }
    }
  }
}
</style>
