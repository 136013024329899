<template>
  <div class="modifier-fields">
    <b-form-row>
      <b-col class="col-6 col-md-3">
        <InputField
          :id="'modifier-'+mod.id+'-name'"
          :label="`${labelType} Source`"
          :placeholder="`- Enter ${labelType} Source -`"
          :desc="`What is this ${labelType.toLowerCase()} from?`"
          :value="mod.name"
          @handleInputFieldChange="updateModifier({ id: mod.id, payload: { name: $event, categories: mod.categories, type: mod.type, amount: mod.amount }})"
        />
      </b-col>
      <b-col class="col-6 col-md-3">
        <VueSelectField
          :id="'modifier-'+mod.id+'-type'"
          :label="`${labelType} Type`"
          :placeholder="`- Enter ${labelType} Type -`"
          :options="modifierTypes"
          taggable
          :value="mod.type"
          @handleVueSelectFieldChange="updateModifier({ id: mod.id, payload: { name: mod.name, categories: mod.categories, type: $event, amount: mod.amount }})"
        />
      </b-col>
      <b-col class="col-8 col-md-3">
        <MultiSelectField
          :id="'modifier-'+mod.id+'-categories'"
          label="Categories"
          :options="allModCategories"
          textField=""
          valueField=""
          :taggable="false"
          :value="mod.categories"
          :placeholder="'- Select Categories -'"
          desc="Select which stats to modify."
          @handleMultiSelectFieldChange="updateModifier({ id: mod.id, payload: { name: mod.name, categories: $event, type: mod.type, amount: mod.amount }})"
        />
      </b-col>
      <b-col class="col-3 col-md-2">
        <SpinButton
          :id="'modifier-'+mod.id+'-amount'"
          label="Amount"
          placeholder="Enter Modifier Amount"
          min=-99
          max=99
          :value="mod.amount"
          @handleSpinButtonChange="updateModifier({ id: mod.id, payload: { name: mod.name, categories: mod.categories, type: mod.type, amount: $event }})"
        />
      </b-col>
      <b-col class="col-1 d-flex justify-content-center align-items-center remove-modifier-btn">
        <Button
          size="sm"
          :btnClass="'remove-modifier-btn-'+mod.id"
          :label="`Remove This ${labelType}`"
          :showLabel="false"
          square
          @handleButtonClick="removeModifier({ list: parentList, id: parentElement.id, deleteId: mod.id })"
          v-b-popover.hover.focus.blur.top="`Remove This ${labelType}`"
        >
          <FontAwesomeIcon :icon="removeIcon" />
        </Button>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import { mapFields } from 'vuex-map-fields';
import InputField from '@/components/form/InputField.vue';
import SpinButton from '@/components/form/SpinButton.vue';
import VueSelectField from '@/components/form/VueSelectField.vue';
import MultiSelectField from '@/components/form/MultiSelectField.vue';
import Button from '@/components/form/Button.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'ModifierFields',
  components: {
    InputField,
    SpinButton,
    VueSelectField,
    MultiSelectField,
    Button,
    FontAwesomeIcon,
  },
  data() {
    return {
      removeIcon: faMinus,
    };
  },
  props: {
    mod: {
      type: Object,
      default: () => {},
    },
    categoryOptions: {
      type: Array,
      default: () => [],
    },
    catDisabled: {
      type: Boolean,
      default: false,
    },
    parentElement: {
      type: Object,
      default: () => {},
    },
    parentList: {
      type: String,
      default: '',
    },
    labelType: {
      type: String,
      default: 'Modifier',
    },
  },
  computed: {
    allModCategories() {
      return [...this.modifierCategories, ...this.abilityScores, ...this.getAllSkills.map((skill) => skill.name)];
    },
    ...mapState([
      'modifierCategories',
      'modifierTypes',
      'abilityScores',
    ]),
    ...mapGetters('characterSheet', [
      'getAllSkills',
    ]),
    ...mapFields('characterSheet', [
      'modifiers',
    ]),
  },
  methods: {
    ...mapMutations('characterSheet', [
      'updateModifier',
    ]),
    ...mapActions('characterSheet', [
      'removeModifier',
    ]),
  },
};
</script>

<style lang="scss">
.remove-modifier-btn {
  div.anvil-button.form-group.form-button {
    margin-bottom: 0.5rem !important;
    .btn {
      @include squareButton(28px);
    }
  }
}
</style>
