<template>
  <div class="conditions">
    <span
      class="checkbox-group"
      v-for="(cond, index) in conditions"
      :key="index"
    >
      <b-form-checkbox
        size="sm"
        v-model="characterConditions"
        :name="'checkbox-'+index+'-'+cond.name"
        class="condition-checkbox m-1"
        :id="cond.name"
        :value="cond.name"
        button
        button-variant="outline-theme-main"
      >
        {{ cond.name }}
      </b-form-checkbox>
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'Conditions',
  created() {
    if (!this.conditions.length) {
      this.$store.dispatch('fetchUrlPath', 'conditions?all=true')
        .then((response) => {
          this.$store.commit('loadFetchedData', { targetList: 'conditions', payload: response });
        });
    }
  },
  computed: {
    ...mapState([
      'conditions',
    ]),
    ...mapFields('characterSheet', [
      'characterConditions',
    ]),
  },
};
</script>
