<template>
  <div class="stacked-button-group fade-in d-flex justify-content-center align-items-center">
    <div>
      <b-form-group
        class="stacked-button-group-btn-group"
        label-sr-only
        :label="`Choose a ${itemGroupName}`"
        v-if="itemsList.length"
      >
        <b-form-radio-group
          id="stacked-button-group-radio-group"
          v-model="inputValue"
          :options="itemsList"
          buttons
          stacked
          split
          button-variant="outline-secondary"
          name="stacked-button-group-radio-btn-stacked"
          class="w-100 stacked-button-group-radio-btn-stacked"
        >
        </b-form-radio-group>
      </b-form-group>
    </div>
    <div class="stacked-button-dropdown-menu-btn-wrapper">
      <b-form-group
        class="stacked-button-dropdown-menu-btn-group"
        label-sr-only
        :label="`Delete a ${itemGroupName}`"
        v-if="itemsList.length"
      >
        <b-dropdown
          right
          size="sm"
          v-model="inputValue"
          v-for="(item, index) of itemsList"
          :key="index"
          id="delete-stacked-button-group"
          variant="outline-secondary"
          stacked
          name="delete-stacked-button-group-btn-stacked"
          :alt="'Delete '+item"
          class="w-100 stacked-button-dropdown-menu-btn"
        >
          <template slot="button-content"></template>
          <b-dropdown-item
            @click="handleDeleteItemButtonClick(item)"
          >
            <FontAwesomeIcon :icon="trashIcon" /> Delete "{{ item }}"
          </b-dropdown-item>
        </b-dropdown>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'StackedButtonGroup',
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      trashIcon: faTrash,
    };
  },
  props: {
    itemGroupName: {
      type: String,
      default: 'Item',
    },
    itemsList: {
      type: Array,
      default: () => [],
    },
    value: [String, Number],
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    handleDeleteItemButtonClick(event) {
      this.$emit('handleDeleteItemButtonClick', event);
    },
  },
};
</script>

<style lang="scss">
.stacked-button-group {
  .stacked-button-group-btn-group {
    #stacked-button-group-radio-group {
      label.btn {
        font-size: $text-smaller;
      }
    }
  }
  .stacked-button-dropdown-menu-btn-wrapper {
    width: 32px;
    .stacked-button-dropdown-menu-btn-group {
      .stacked-button-dropdown-menu-btn {
        .dropdown-toggle {
          border-radius: 0;
          @include squareButton();
        }
        .dropdown-menu {
          font-size: $text-smaller;
          box-shadow: 0 0 8px $navbar-shadow;
        }
      }
      & > div div:first-child > button.dropdown-toggle:first-child {
        border-top-right-radius: 0.25rem;
      }
      & > div div:last-child > button.dropdown-toggle:first-child {
        border-bottom-right-radius: 0.25rem;
      }
      & > div :not(:first-child) > .dropdown-toggle:first-child {
        margin-top: -1px;
      }
    }
  }
  .stacked-button-group-radio-btn-stacked {
    .btn-outline-secondary {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
</style>
