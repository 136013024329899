<template>
  <b-form-group
    class="select-field"
    :id="id+'group'"
    :label="label"
    :label-for="id"
    :label-sr-only="!showLabel"
    :description="desc"
  >
    <v-select
      :id="id"
      :options="options"
      :label="textField"
      :value="valueField"
      :size="size"
      :required="required"
      :disabled="disabled"
      :placeholder="placeholder"
      v-model="inputValue"
      :reduce="value => value.name || value"
      close-on-select
      transition=""
      :taggable="taggable"
      :components="loading ? {Deselect} : null"
      @input="handleVueSelectFieldChange"
    >
      <template v-if="textField && valueField" #selected-option="{ name, description }">
        <div
          v-b-popover.hover.focus.blur.top="description"
        >
          {{ name }}
        </div>
      </template>
      <template v-slot:no-options="{}">
        {{ emptyText }}
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import Deselect from '@/components/DeselectSpinner.vue';
import { mapState } from 'vuex';

export default {
  name: 'VueSelectField',
  data: () => ({
    Deselect,
  }),
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    ...mapState('loader', [
      'loading',
    ]),
  },
  props: {
    id: String,
    options: Array,
    value: [String, Number, Array, Object],
    textField: {
      type: String,
      default: '',
    },
    valueField: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'sm',
    },
    label: String,
    showLabel: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '- Select -',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    desc: {
      type: String,
      default: '',
    },
    emptyText: {
      type: String,
      default: 'No available options.',
    },
  },
  methods: {
    handleVueSelectFieldChange(selectedValue) {
      this.$emit('handleVueSelectFieldChange', selectedValue);
    },
  },
};
</script>

<style lang="scss">
.select-field {
  .v-select {
    .vs__dropdown-toggle {
      max-height: 28px;
      .vs__selected-options {
        span.vs__selected {
          background-color: transparent !important;
        }
      }
      .vs__actions {
        button {
          svg {
            vertical-align: unset;
          }
        }
      }
    }
  }
}
</style>
