<template>
  <div class="spellcasting-fields fade-in">
    <b-form-row>
      <b-col class="col-12">
        <MultiSelectButtonGroup
          v-if="spellcasting['Level Increases']"
          :id="`spellcasting-level-increases-${charClass.name}`"
          :value="spellcasting['Level Increases']"
          @handleMultiSelectButtonGroupInput="updateClassSpellcasting({ charClass, spellcastingUpdate: { ...spellcasting, ['Level Increases']: $event } })"
          label="Level Increases"
          textField=""
          valueField=""
          helpText="At which level(s) does the character achieve a new rank in or benefit from this spellcasting?"
          :options="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]"
        />
      </b-col>
      <b-col class="col-12 col-sm-3">
        <VueSelectField
          :id="`spellcasting-spell-type-${charClass.name}`"
          label="Spell Type"
          :options="spellTypes"
          placeholder="- Spell Type -"
          :value="spellcasting.spellType"
          @handleVueSelectFieldChange="updateClassSpellcasting({ charClass, spellcastingUpdate: { ...spellcasting, spellType: $event } })"
        />
      </b-col>
      <b-col class="col-12 col-sm-3">
        <VueSelectField
          :id="`spellcasting-spell-list-${charClass.name}`"
          label="Spell List"
          :options="spellLists"
          taggable
          placeholder="- Spell List -"
          :value="spellcasting['Spell List']"
          @handleVueSelectFieldChange="updateClassSpellcasting({ charClass, spellcastingUpdate: { ...spellcasting, ['Spell List']: $event } })"
        />
      </b-col>
      <b-col class="col-12 col-sm-3">
        <VueSelectField
          :id="`spellcasting-casting-type-${charClass.name}`"
          label="Casting Type"
          :options="castingTypes"
          placeholder="- Casting Type -"
          :value="spellcasting.castingType"
          @handleVueSelectFieldChange="updateClassSpellcasting({ charClass, spellcastingUpdate: { ...spellcasting, castingType: $event } })"
        />
      </b-col>
      <b-col class="col-12 col-sm-3">
        <VueSelectField
          :id="`spellcasting-casting-ability-${charClass.name}`"
          label="Casting Ability"
          :options="abilityScores"
          placeholder="- Casting Ability -"
          :value="spellcasting.castingAbility"
          @handleVueSelectFieldChange="updateClassSpellcasting({ charClass, spellcastingUpdate: { ...spellcasting, castingAbility: $event } })"
        />
      </b-col>
      <b-col class="col-12 col-sm-6 col-md-3">
        <InputField
          :id="`spellcasting-spell-dc-${charClass.name}`"
          label="Spell DC"
          desc="Ex: 10 + spell level + Casting Ability"
          :value="spellcasting['Spell DC']"
          @handleInputFieldChange="updateClassSpellcasting({ charClass, spellcastingUpdate: { ...spellcasting, ['Spell DC']: $event } })"
        />
      </b-col>
      <b-col class="col-12 col-sm-6 col-md-3">
        <InputField
          :id="`spellcasting-spell-source-${charClass.name}`"
          label="Spell Source"
          :value="spellcasting['Spell Source']"
          @handleInputFieldChange="updateClassSpellcasting({ charClass, spellcastingUpdate: { ...spellcasting, ['Spell Source']: $event } })"
        />
      </b-col>
      <b-col class="col-12 col-sm-6 col-md-3">
        <InputField
          :id="`spellcasting-casting-focus-${charClass.name}`"
          label="Casting Focus"
          :value="spellcasting['Casting Focus']"
          @handleInputFieldChange="updateClassSpellcasting({ charClass, spellcastingUpdate: { ...spellcasting, ['Casting Focus']: $event } })"
        />
      </b-col>
      <b-col class="col-12 col-sm-6 col-md-3">
        <SpinButton
          :id="`spellcasting-caster-level-mod-${charClass.name}`"
          label="Caster Level Mod"
          size="sm"
          spinButtonClass="spellcaster-caster-level-mod-spin-btn"
          min=-10
          max=10
          :value="spellcasting['Caster Level Mod']"
          @handleSpinButtonChange="updateClassSpellcasting({ charClass, spellcastingUpdate: { ...spellcasting, ['Caster Level Mod']: $event } })"
        />
      </b-col>
      <b-col class="col-12">
        <CheckboxField
          :id="`spellcasting-cantrips-${charClass.name}`"
          checkboxClass="my-3 w-100"
          :value="spellcasting.Cantrips"
          @handleCheckboxFieldChange="updateClassSpellcasting({ charClass, spellcastingUpdate: { ...spellcasting, Cantrips: $event } })"
        >
          Cantrips
        </CheckboxField>
      </b-col>
      <b-col class="col-12">
        <TextAreaField
          :id="`spellcasting-description-${spellcasting.id}`"
          label="Description / Notes"
          rows="5"
          :value="spellcasting.Description"
          @handleTextAreaFieldChange="updateClassSpellcasting({ charClass, spellcastingUpdate: { ...spellcasting, Description: $event } })"
        />
      </b-col>
    </b-form-row>
  </div>
</template>

  Spell Source: "",
  Casting Focus: "",
  spellsKnown: [],
  spellsPerDay: [],

<script>
import { mapState, mapMutations } from 'vuex';
import InputField from '@/components/form/InputField.vue';
import TextAreaField from '@/components/form/TextAreaField.vue';
import VueSelectField from '@/components/form/VueSelectField.vue';
import SpinButton from '@/components/form/SpinButton.vue';
import CheckboxField from '@/components/form/CheckboxField.vue';
import MultiSelectButtonGroup from '@/components/form/MultiSelectButtonGroup.vue';
import { replaceSpaces } from '../utils/utils';

export default {
  name: 'SpellcastingFields',
  components: {
    InputField,
    TextAreaField,
    VueSelectField,
    SpinButton,
    CheckboxField,
    MultiSelectButtonGroup,
  },
  props: {
    charClass: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState([
      'spellTypes',
      'spellLists',
      'castingTypes',
      'abilityScores',
    ]),
    spellcasting() {
      return this.charClass.spellcasting;
    },
  },
  methods: {
    replaceSpaces,
    ...mapMutations('characterSheet', [
      'updateClassSpellcasting',
    ]),
  },
};
</script>

<style lang="scss">
  .spellcasting-fields {
    .checkbox-field {
      label {
        width: 100%;
      }
    }
  }
</style>
