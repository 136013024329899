<template>
  <div class="armor-class-display fade-in">
    <ModifierDisplay
      :modNumber="acTotal"
      :modText="acType"
    />
    <FontAwesomeIcon :icon="equalsIcon" />
    <div
      class="mod-display"
      v-for="(acItem, index) in getACStringArray(useDex, typeFilters)"
      :key="index"
    >
      <div v-if="index !== 0">
        <FontAwesomeIcon :icon="plusIcon" />
      </div>
      <ModifierDisplay
        :modNumber="acItem.value"
        :modText="acItem.name"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEquals, faPlus } from '@fortawesome/free-solid-svg-icons';
import ModifierDisplay from '@/components/ModifierDisplay.vue';

export default {
  name: 'ArmorClassDisplay',
  components: {
    FontAwesomeIcon,
    ModifierDisplay,
  },
  data() {
    return {
      equalsIcon: faEquals,
      plusIcon: faPlus,
    };
  },
  props: {
    acTotal: {
      type: [Number, String],
      default: 10,
    },
    acType: {
      type: String,
      default: 'Total AC',
    },
    useDex: {
      type: Boolean,
      default: true,
    },
    typeFilters: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('characterSheet', [
      'getACStringArray',
    ]),
  },
};
</script>

<style scoped lang="scss">
.armor-class-display {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 2rem 0;
  .mod-display {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
