<template>
  <div class="full-rest">
    <Button
      v-b-modal.fullRestModal
      alt="Take Rest"
      v-b-popover.hover.click.focus.blur.top="'Take Rest'"
      size="sm"
      btnClass="full-rest-btn"
      label=""
    >
      <FontAwesomeIcon :icon="bedIcon" />
    </Button>
    <Modal
      modalTitle="Take Rest"
      modalId="fullRestModal"
      okOnly
      modalOkTitle="Take Rest"
      :modalIcon="bedIcon"
      @handleModalSubmit="doFullRest"
    >
      <FullRestFields />
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Modal from '@/components/Modal.vue';
import Button from '@/components/form/Button.vue';
import FullRestFields from '@/components/FullRestFields.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faBed } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'FullRest',
  components: {
    Modal,
    Button,
    FullRestFields,
    FontAwesomeIcon,
  },
  data() {
    return {
      bedIcon: faBed,
    };
  },
  props: {
    modalSize: {
      type: String,
      default: 'md',
    },
  },
  computed: {
    ...mapState('characterSheet', [
      'characterClasses',
    ]),
    ...mapFields('characterSheet', {
      refreshClassSpells: 'rest.refreshClassSpells',
    }),
    ...mapGetters('characterSheet', [
      'getHitPointsRecoveredFromRest',
    ]),
  },
  methods: {
    doFullRest() {
      this.$store.dispatch('characterSheet/makeHitPointAdjustment', this.getHitPointsRecoveredFromRest);
      Array.from(this.refreshClassSpells).forEach((arg, index) => {
        if (arg) {
          this.$store.commit('characterSheet/refreshAllSpellSlotsForClass', { charClass: this.characterClasses[index] });
        }
      });
    },
  },
};
</script>
