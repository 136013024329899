import getId from './idUtils';

export const getArrayIndex = (arrayToSearch, property, match) => arrayToSearch.findIndex((i) => i[match] === property);

export const simpleArraysAreEqual = (a, b) => Array.isArray(a)
  && Array.isArray(b)
  && a.length === b.length
  && a.every((val, index) => val === b[index]);

export const deleteFromArrayByProperty = (arrayToChange, itemToDelete, property, match) => arrayToChange.filter((i) => i[match || property] !== itemToDelete[property]);

export const formatLanguage = (existingLanguages, name, type, language, options) => {
  let setId = '';
  const isExistingLanguage = Object.keys(existingLanguages).find((item) => existingLanguages[item].name === name && existingLanguages[item].type === type && existingLanguages[item].id);
  if (isExistingLanguage) {
    setId = existingLanguages[isExistingLanguage].id;
  }
  return ({
    id: setId || getId(),
    name,
    type,
    language,
    options,
  });
};

export const formatFeatOptions = (options, gainLevel) => {
  if (Array.isArray(options)) {
    return options;
  }
  const featOptionsArray = [];
  for (const [key, value] of Object.entries(options)) {
    if (gainLevel >= key) { featOptionsArray.push(...value); }
  }
  return featOptionsArray;
};

export const formatFeat = (existingFeats, featName, value, featSource, emptyFeat, gainLevel) => {
  let setId = '';
  const isExistingFeat = Object.keys(existingFeats).find((item) => existingFeats[item].name === featName && simpleArraysAreEqual(existingFeats[item].types, (value.Types || [])) && existingFeats[item].levelGained === +gainLevel && existingFeats[item].id);
  if (isExistingFeat) {
    setId = existingFeats[isExistingFeat].id;
  }
  return ({
    ...emptyFeat,
    id: setId || getId(),
    name: featName,
    types: value?.Types || [],
    source: featSource,
    levelGained: +gainLevel,
    options: value?.Options ? formatFeatOptions(value.Options, gainLevel) : [],
    description: value?.Description || '',
    selection: value?.Selected || (value?.Options?.length === 1 && value.Options[0]) || '',
  });
};

export const formatFeatObjectForData = (existingFeats, featObj, emptyFeat, featSource, charLevel) => {
  const formattedFeatArray = [];
  for (const [key, value] of Object.entries(featObj)) {
    if (featObj[key].name === 'Bonus Feats') {
      if (value['Level Increases']) {
        value['Level Increases'].forEach((lvlInc) => {
          if (charLevel >= lvlInc) {
            formattedFeatArray.push(formatFeat(existingFeats, 'Bonus Feat', value, featSource, emptyFeat, lvlInc));
          }
        });
      } else {
        formattedFeatArray.push(formatFeat(existingFeats, 'Bonus Feat', value, featSource, emptyFeat, charLevel));
      }
    } else if (value['Bonus Feats']['Level Increases']) {
      value['Bonus Feats']['Level Increases'].forEach((lvlInc) => {
        if (charLevel >= lvlInc) {
          formattedFeatArray.push(formatFeat(existingFeats, value.name, value['Bonus Feats'], featSource, emptyFeat, lvlInc));
        }
      });
    } else {
      formattedFeatArray.push(formatFeat(existingFeats, value.name, value['Bonus Feats'], featSource, emptyFeat, charLevel));
    }
  }
  return formattedFeatArray.reduce((theFeat, item) => Object.assign(theFeat, { [`${item.id}`]: item }), {});
};

export const formatSpellQuery = (charClass, spellLevel) => {
  const spellList = (charClass.spellcasting['Spell List'].toLowerCase() || charClass.spellcasting.spellList.toLowerCase()).replace('/', ',');
  return `spells?${spellList}=${spellLevel}`;
};

export const compileBonusLanguages = (objToSearch) => Object.keys(objToSearch)
  .filter((feature) => objToSearch[feature].name === 'Bonus Languages' && objToSearch[feature].Options)
  .reduce((acc, langKey) => {
    acc.push(...objToSearch[langKey].Options);
    return acc;
  }, []);
