<template>
  <div class="attack-fields fade-in">
    <b-form-row>
      <b-col class="col-12 col-sm-4 col-md-4">
        <InputField
          id="weapon-field"
          label="Attack Name"
          v-model="attack.weapon"
        />
      </b-col>
      <b-col class="col-5 col-sm-3 col-md-3">
        <VueSelectField
          id="weapon-attack-type"
          label="Attack Type"
          v-model="attack.attackType"
          :options="attackTypes"
          placeholder="- Type -"
        />
      </b-col>
      <b-col class="col-4 col-sm-3 col-md-3">
        <SpinButton
          id="weapon-attack-bonus"
          label="Attack Bonus"
          v-model="attack.attackBonus"
        />
      </b-col>
      <b-col class="col-3 col-sm-2 col-md-2">
        <CheckboxField
          id="add-base-attack-bonus"
          checkboxClass="use-bab-btn"
          v-model="attack.useBaB"
        >
          Add BaB
        </CheckboxField>
      </b-col>
      <b-col class="col-7 col-md-3">
        <InputField
          id="weapon-crit-threat-range"
          label="Crit Range"
          v-model="attack.critRange"
        />
      </b-col>
      <b-col class="col-5 col-md-3">
        <SpinButton
          id="weapon-crit-multiplier"
          label="Crit Multiplier"
          min=1
          v-model="attack.critMultiplier"
        />
      </b-col>
      <b-col class="col-12 col-sm-6 col-md-3">
        <InputField
          id="weapon-range"
          label="Range"
          v-model="attack.range"
        />
      </b-col>
      <b-col class="col-12 col-sm-6 col-md-3">
        <InputField
          id="weapon-ammo"
          label="Ammo"
          v-model="attack.ammo"
        />
      </b-col>
    </b-form-row>
    <DamageFields
      v-for="(dmg, indx) in attack.damage"
      :key="indx"
      :damage="dmg"
      :attack="attack"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import InputField from '@/components/form/InputField.vue';
import SpinButton from '@/components/form/SpinButton.vue';
import CheckboxField from '@/components/form/CheckboxField.vue';
import VueSelectField from '@/components/form/VueSelectField.vue';
import DamageFields from '@/components/DamageFields.vue';

export default {
  name: 'AttackFields',
  components: {
    InputField,
    SpinButton,
    CheckboxField,
    VueSelectField,
    DamageFields,
  },
  props: {
    attack: Object,
  },
  computed: {
    ...mapState([
      'attackTypes',
    ]),
  },
};
</script>

<style lang="scss">
.attack-fields {
  div.anvil-button {
    margin-bottom: 0.5rem;
  }
  .use-bab-btn {
    margin-top: 26px;
    width: 100%;
    label {
      width: 100%;
    }
  }
}
</style>
