<template>
  <div class="trait fade-in">
    <SectionDivider
      :title="trait.name"
      dividerClass=""
      v-b-modal="'editTraitModal'+trait.id"
      v-b-popover.hover.focus.blur.top="trait.description"
    />
    <div class="d-flex align-items-center justify-content-between">
      <div class="trait-type-subtype">
        {{ trait.type }}{{ trait.subtype ? ` | ${trait.subtype}` : '' }}
      </div>
      <Button
        v-b-popover.hover.focus.blur.top="'Remove Trait'"
        alt="Remove Trait"
        size="sm"
        btnClass="remove-trait-btn"
        square
        v-b-modal="'removeTraitModal'+trait.id"
      >
        <FontAwesomeIcon :icon="removeIcon" />
      </Button>
    </div>
    <Modal
      modalTitle="Edit Trait"
      modalSize="xl"
      :modalId="'editTraitModal'+trait.id"
      okOnly
    >
      <TraitFields
        :trait="trait"
      />
    </Modal>
    <Modal
      modalTitle="Remove Trait"
      modalSize="md"
      :modalId="'removeTraitModal'+trait.id"
      @handleModalSubmit="removeFieldAndModifiers({ list: 'characterTraits', value: trait })"
    >
      Are you sure you want to delete this trait?
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Modal from '@/components/Modal.vue';
import Button from '@/components/form/Button.vue';
import SectionDivider from '@/components/SectionDivider.vue';
import TraitFields from '@/components/TraitFields.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'Trait',
  components: {
    Modal,
    Button,
    SectionDivider,
    TraitFields,
    FontAwesomeIcon,
  },
  data() {
    return {
      removeIcon: faTimes,
    };
  },
  props: {
    trait: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState([
      'traitTypes',
    ]),
  },
  methods: {
    ...mapActions('characterSheet', [
      'removeFieldAndModifiers',
    ]),
  },
};
</script>

<style scoped lang="scss">
  div.anvil-button.form-group {
    margin-bottom: 0;
  }
</style>
