<template>
  <div class="class-feature-fields fade-in">
    <b-form-row>
      <b-col class="col-12">
        <InputField
          :id="`feature-name-${feature.id}`"
          label="Name"
          :value="feature.name"
          @handleInputFieldInput="updateClassFeature({ charClass, featureToUpdate: { ...feature, name: $event } })"
        />
      </b-col>
      <b-col class="col-12">
        <MultiSelectButtonGroup
          v-if="feature['Level Increases']"
          :id="`feature-level-increases-${feature.id}`"
          :value="feature['Level Increases']"
          @handleMultiSelectButtonGroupInput="updateClassFeature({ charClass, featureToUpdate: { ...feature, ['Level Increases']: $event } })"
          label="Level Increases"
          textField=""
          valueField=""
          helpText="At which level(s) does the character achieve a new rank in or benefit from this feature?"
          :options="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]"
        />
      </b-col>
      <b-col class="col-12">
        <TextAreaField
          :id="`feature-description-${feature.id}`"
          label="Description"
          rows="5"
          :value="feature.Description"
          @handleTextAreaFieldChange="updateClassFeature({ charClass, featureToUpdate: { ...feature, Description: $event } })"
        />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import InputField from '@/components/form/InputField.vue';
import TextAreaField from '@/components/form/TextAreaField.vue';
import MultiSelectButtonGroup from '@/components/form/MultiSelectButtonGroup.vue';
import { replaceSpaces } from '../utils/utils';

export default {
  name: 'ClassFeatureFields',
  components: {
    InputField,
    TextAreaField,
    MultiSelectButtonGroup,
  },
  props: {
    feature: {
      type: Object,
      default: () => {},
    },
    charClass: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    replaceSpaces,
    ...mapMutations('characterSheet', [
      'updateClassFeature',
    ]),
  },
};
</script>
