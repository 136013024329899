<template>
  <b-form-checkbox
    :class="`checkbox-field ${checkboxClass}`"
    :id="id"
    :name="id"
    button
    v-model="checkboxValue"
    :size="size"
    :value="true"
    :unchecked-value="false"
    @change="handleCheckboxFieldChange"
  >
    <slot></slot>
  </b-form-checkbox>
</template>

<script>
export default {
  name: 'CheckboxField',
  computed: {
    checkboxValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  props: {
    id: String,
    value: [Boolean, String],
    size: {
      type: String,
      default: 'sm',
    },
    checkboxClass: {
      type: String,
      default: '',
    },
    label: String,
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleCheckboxFieldChange(checkedValue) {
      this.$emit('handleCheckboxFieldChange', checkedValue);
    },
  },
};
</script>
